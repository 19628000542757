import React, { useEffect, useState, useRef } from 'react';
import { ExamineeTableRow, Pagination} from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { notificationController } from 'controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Dates } from '@app/constants/Dates';
import { Modal } from 'antd';
import { ScheduleForm } from '../forms/ScheduleForm/ScheduleForm';
import { VideoForm } from '../forms/VideoForm/VideoForm';
import { BaseButton } from '../common/BaseButton/BaseButton';
import {
  CheckOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { RegistrationForm } from '../forms/RegistrationForm/RegistrationForm';
import { BaseFormTitle } from '../common/forms/components/BaseFormTitle/BaseFormTitle';
import HeaderSearch from '../header/components/YMHeaderSearch/HeaderSearch';
import { BaseCheckbox } from '../common/BaseCheckbox/BaseCheckbox';
import * as S from './ExamineeTable.styles';
import styled from 'styled-components';
import { Priority } from '@app/constants/enums/priorities';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  createYMGroupGQL,
  createYMTargetGQL,
  getAllYMExecutersGQL,
  getYMCountGQL,
  getYMGroupByCodeGQL,
  getYMTargetsByExAndStatusGQL,
  getYMTargetsByNameAndExAndStatusGQL,
  getYMTargetsByStatusGQL,
  searchYMTargetsByExecutersGQL,
  searchYMTargetsByGroupGQL,
  searchYMTargetsByNameGQL,
  updateYMTargetGQL,
} from '@app/hocs/gql';
import { ymDatas, ymaDatas, isSameClickSideBar } from '@app/hocs/cache';
import clonedeep from 'lodash.clonedeep';
import { getExamMonthFromReserved, getFormatedAge, regions } from '@app/constants/YMData';
import { GroupScheduleForm } from '../forms/ScheduleForm/GroupScheduleForm';
import { GroupAddForm } from '../forms/GroupAddForm/GroupAddForm';
import TargetInfoModal from '@app/components/modal/TargetInfoModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const PopoverCheckbox = styled(BaseCheckbox)`
  margin-right: 1rem;
`;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 7,
};

export const ExamineeTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: ExamineeTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const targets = useRef<any[]>([]);

  const [targetInfoModal, setTargetInfoModal] = useState(false);
  const onToggleModal = () => {
    setTargetInfoModal((prevState) => !prevState);
  };

  const { t } = useTranslation();

  const [isUpdateTarget, setIsUpdateTarget] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<any>(null);

  const [status, setStatus] = useState(99);
  const ymaData = useReactiveVar(ymaDatas);
  const ymData = useReactiveVar(ymDatas);
  const [title, setTitle] = useState('');
  const isSidebarClick = useReactiveVar(isSameClickSideBar);
  const handleTableChange = (pagination: Pagination) => {
    if (pagination.current === undefined) return;
    tableData.pagination.current = pagination.current;
    //fetch(pagination);
  };

  const setExamineeData = (rowId: number, modalID: number) => {
    if (rowId >= 0) {
      const target = clonedeep(targets.current[rowId]);
      target.data = JSON.parse(targets.current[rowId].data);
      if (target.group === null) target.group = { data: {} };
      target.group.data = targets.current[rowId].group !== null ? JSON.parse(targets.current[rowId].group.data) : {};
      const examiners = ymaData.eqs.map((ex: any, idx: number) => {
        return {
          id: ex.id,
          name: ex.name,
          selected: target.executers.find((element: any) => element.name && element.id === ex.id) !== undefined,
          auth: ex.authorized,
        };
      });
      ymDatas({
        ...ymDatas(),
        tg: target,
        tk: target.tasks,
        eqid: targets.current[rowId].executers.length > 0 ? targets.current[rowId].executers[0].id : '',
        modal: modalID,
        update: new Date().getTime(),
        examiners: examiners,
      });
    } else {
      const bdate = new Date().getTime();
      const target = {
        data: { uid: '', name: '', bdate: new Date('2022-04-01'), sex: 1, epweek: 40, epday: 0, bmonth: 0 },
        tasks: [],
        reservedAt: bdate,
      };
      const examiners = ymaData.eqs.map((ex: any, idx: number) => {
        return { id: ex.id, name: ex.name, selected: false, auth: ex.authorized };
      });
      ymDatas({
        ...ymDatas(),
        tg: target,
        tk: target.tasks,
        eqid: ymaData.eqid,
        modal: modalID,
        update: new Date().getTime(),
        examiners: examiners,
      });
    }
    //uData.executer = targets.current[rowId].executers[0].id;
    //uData.reservedAt = targets.current[rowId].reservedAt;
    //uData.bmonth = 0;
    //uData.exmonth = 0;

    //const vData = targets.current[rowId].tasks; //JSON.parse(targets.current[rowId].tasks[0].data);
    //const rDate = targets.current[rowId].reservedAt;

    //console.log(uData);
    //setVideoInfo(vData);
    //setExamInfo(rDate);
    //setExamineeInfo(uData);
  };

  const updateExam = () => {
    if (ymaData.auth === '') return;
    // setStatus(99);
    //console.log('updateExaminer:'+ymaData.eqid + ymaData.eqname + ymaData.auth);

    if (ymaData.auth.split('|').includes('관리자')) {
      getYMTargetByStatus({
        variables: { status: 0 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    } else if (ymaData.eqid !== '') {
      getYMTargetByExAndStatus({
        variables: { eId: ymaData.eqid, status: 0 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    }
  };

  const updateExaminer = () => {
    if (ymaData.auth === '') return;

    setStatus(99);
    //console.log('updateExaminer:'+ymaData.eqid + ymaData.eqname + ymaData.auth);
    
    //if( ymaData.tgs.length > 0) return;

    if(ymaData.auth.split('|').includes('관리자')) {
      getYMTargetByStatusHandler({
        variables: { status: 0 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    } else if(ymaData.eqid !== '') {
      getYMTargetByExAndStatusHandler({
        variables: { eId: ymaData.eqid, status: 0 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    }
  };
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const statusValue = searchParams.get('status');

  const handleSearch = (keyword: string, type: string) => {
    setStatus(99);

    if (keyword) {
      searchParams.set('search', keyword);
    } else {
      searchParams.delete('search');
    }
    navigate({ search: searchParams.toString() });
    if (!keyword) {
      updateExaminer();
      return;
    }

    if (type === 'name') {
      searchYMTargetsByName({
        variables: { keyword },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      });
    } else if (type === 'group') {
      searchYMTargetsByGroup({
        variables: { keyword },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      });
    } else if (type === 'executer') {
      searchYMTargetsByExecuters({
        variables: { keyword },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      });
    }
  };

  const searchExaminer = async (name: string, status: number) => {
    setStatus(99);
    //d console.log('updateExaminer:'+ymaData.eqid + ymaData.eqname + ymaData.auth);

    if (name) {
      searchParams.set('search', name);
    } else {
      searchParams.delete('search');
    }
    navigate({ search: searchParams.toString() });
    if (!name) {
      updateExaminer();
      return;
    }

    if (ymaData.auth.split('|').includes('관리자')) {
      await getYMTargetByNameAndExAndStatusHandler({
        variables: { name: name, eId: '', status: status },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    } else if (ymaData.eqid !== '') {
      // if (!name) {
      //   getYMTargetByNameAndExAndStatusHandler({
      //     variables: { name: name, eId: ymaData.eqid, status: status },
      //     notifyOnNetworkStatusChange: true,
      //     fetchPolicy: 'network-only',
      //     // pollInterval: 500
      //   });
      //   return;
      // }

      await getYMTargetByNameAndExAndStatusHandler({
        variables: { name: name, eId: '', status: status },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    }
  };

  // register modal
  const [open, setOpen] = useState(false);
  //const bData = useRef<any>(null);
  const handleSchedule = (rowId: number) => {
    //d console.log('handleSchedule---------:'+rowId);
    setExamineeData(rowId, 1);
    //bData.current = {executer: targets.current[rowId].executers.length>0?targets.current[rowId].executers[0].id:'', reservedAt: targets.current[rowId].reservedAt};
    showModal();
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    // update target data
    updateTarget();
    setOpen(false);
  };

  const handleCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setOpen(false);
  };

  // examinee modal
  const [examineeopen, setExamineeOpen] = useState(false);

  const handleExaminee = (rowId: number) => {
    //d console.log('handleExaminee---------:'+rowId);
    setTitle(rowId >= 0 ? '대상자 관리' : '대상자 등록하기');
    setExamineeData(rowId, 4);
    showExamineeModal();
  };
  const showExamineeModal = () => {
    setExamineeOpen(true);
  };

  const handleExamineeCancel = () => {
    setExamineeOpen(false);
  };

  // group modal modal
  const [gaddopen, setGAddOpen] = useState(false);
  const [isGAddOpened, setGAddOpened] = useState(false);

  const handleGroupAdd = () => {
    //d console.log('handleSchedule---------:'+rowId);
    //bData.current = {executer: targets.current[rowId].executers.length>0?targets.current[rowId].executers[0].id:'', reservedAt: targets.current[rowId].reservedAt};
    showGAddModal();
  };
  const showGAddModal = () => {
    setGAddOpen(true);
  };
  const handleGAddOk = () => {
    // update target data
    //updateTarget();
    setGAddOpen(false);
  };

  const handleGAddCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setGAddOpen(false);
  };

  // groupschedule modal
  const [gscheduleopen, setGScheduleOpen] = useState(false);
  const [isGScheduleOpened, setGScheduleOpened] = useState(false);
  const handleGSchedule = (rowId: number) => {
    //d console.log('handleSchedule---------:'+rowId);
    setExamineeData(rowId, 2);
    //bData.current = {executer: targets.current[rowId].executers[0].id, reservedAt: targets.current[rowId].reservedAt};
    showGSModal();
  };
  const showGSModal = () => {
    setGScheduleOpen(true);
  };
  const handleGSOk = () => {
    // reload target data
    updateExaminer();
    setGScheduleOpen(false);
  };

  const handleGSCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setGScheduleOpen(false);
  };

  //const [examineeInfo, setExamineeInfo] = useState(null);
  //const [examInfo, setExamInfo] = useState("");
  // video modal
  //const [videoInfo, setVideoInfo] = useState(null);
  const [videoopen, setVideoOpen] = useState(false);

  const showVideoModal = () => {
    setVideoOpen(true);
  };
  const handleVideo = (rowId: number) => {
    //d console.log('handleVideo---------:'+rowId);
    showVideoModal();
    setExamineeData(rowId, 3);
  };
  const handleVideoOk = () => {
    updateExaminer();
    setVideoOpen(false);
  };

  const onUpdate = () => {
    // updateExaminer();
    if (!statusValue) {
      updateExaminer();
      return;
    }
    updateExam();
  };

  const handleVideoCancel = () => {
    setVideoOpen(false);
  };

  // minus modal
  const [minusopen, setMinusOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showMinusModal = () => {
    setMinusOpen(true);
  };
  const handleMinus = (rowId: number) => {
    //d console.log('handleExaminee---------:'+rowId);
    setExamineeData(rowId, 4);
    showMinusModal();
  };
  const handleMinusOk = () => {
    setConfirmLoading(true);
    deleteTarget();
    setTimeout(() => {
      setMinusOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleMinusCancel = () => {
    setMinusOpen(false);
  };

  /**
    
__typename:
'YMTarget'
category:
'ym_examinee'
data:
'{"uid":"T23090330","name":"이번해","bdate":"2021-09-17T00:00:00.000Z","sex":1,"epweek":38,"epday":0}'
executers:
(1) [{…}]
id:
'64f3fb0bd79bc16ef8ec45f5'
reservedAt:
'1693711115936'
status:
0
title:
'이번해'

     */
  //setTableData((tableData) => ({ ...tableData, loading: true }));

  //setTableData({ data: jData.data, pagination: jData.pagination, loading: false });

  const [count, setCount] = useState({
    scheduled: 0,
    progress: 0,
    completed: 0,
    group: 0,
  });

  const [getYMTargetByStatusHandler, getYMTargetsByStatusRequest] = useLazyQuery(getYMTargetsByStatusGQL, {
    onCompleted: (data) => {
      if (data.getYMTargetsByStatus.targets === null) return;

      const tgs = clonedeep(data.getYMTargetsByStatus.targets);
      ymaDatas({ ...ymaData, tgs: tgs });
      targets.current = tgs;
      setStatus(9);
      // setStatus(status);
      notificationController.success({ message: '대상자 목록을 새로 불러왔습니다.' });
    },
  });

  const [getYMTargetByStatus] = useLazyQuery(getYMTargetsByStatusGQL, {
    onCompleted: (data) => {
      if (data.getYMTargetsByStatus.targets === null) return;

      const tgs = clonedeep(data.getYMTargetsByStatus.targets);
      ymaDatas({ ...ymaData, tgs: tgs });
      targets.current = tgs;
      updateTableData(parseInt(statusValue!));
      // setStatus(9);
      // setStatus(status);
      notificationController.success({ message: '대상자 목록을 새로 불러왔습니다.' });
    },
  });

  const [getYMTargetByExAndStatusHandler, getYMTargetsByExAndStatusRequest] = useLazyQuery(
    getYMTargetsByExAndStatusGQL,
    {
      onCompleted: (data) => {
        if (data.getYMTargetsByExAndStatus.targets === null) return;

        const tgs = clonedeep(data.getYMTargetsByExAndStatus.targets);
        ymaDatas({ ...ymaData, tgs: tgs });
        targets.current = tgs;
        setStatus(9);
        //console.log('################################################')
        notificationController.success({ message: '대상자 목록을 새로 불러왔습니다.' });
      },
    },
  );

  const [getYMTargetByExAndStatus] = useLazyQuery(getYMTargetsByExAndStatusGQL, {
    onCompleted: (data) => {
      if (data.getYMTargetsByExAndStatus.targets === null) return;

      const tgs = clonedeep(data.getYMTargetsByExAndStatus.targets);
      ymaDatas({ ...ymaData, tgs: tgs });
      targets.current = tgs;
      // setStatus(9);
      //console.log('################################################')
      notificationController.success({ message: '대상자 목록을 새로 불러왔습니다.' });
    },
  });

  const [getYMTargetByNameAndExAndStatusHandler, getYMTargetsByNameAndExAndStatusRequest] = useLazyQuery(
    getYMTargetsByNameAndExAndStatusGQL,
    {
      onCompleted: (data) => {
        if (data.getYMTargetsByNameAndExAndStatus.targets === null) return;

        const tgs = clonedeep(data.getYMTargetsByNameAndExAndStatus.targets);
        ymaDatas({ ...ymaData, tgs: tgs });
        targets.current = tgs;
        setStatus(9);
        //console.log('################################################')
        notificationController.success({ message: '검색 대상자 목록을 불러왔습니다.', duration: 1 });
      },
    },
  );

  const [searchYMTargetsByExecuters] = useLazyQuery(searchYMTargetsByExecutersGQL, {
    onCompleted: (data) => {
      if (data.searchYMTargetsByExecuter.targets.length === null) return;
      const tgs = clonedeep(data.searchYMTargetsByExecuter.targets);
      const targetDatas = tgs.filter((target: any) => target.status !== 5);

      ymaDatas({ ...ymaData, tgs: targetDatas });
      targets.current = targetDatas;
      setStatus(9);
      notificationController.success({ message: '검색 대상자 목록을 불러왔습니다.', duration: 1 });
    },
  });

  const [searchYMTargetsByGroup] = useLazyQuery(searchYMTargetsByGroupGQL, {
    onCompleted: (data) => {
      if (data.searchYMTargetsByGroup.targets.length === null) return;
      const tgs = clonedeep(data.searchYMTargetsByGroup.targets);
      const targetDatas = tgs.filter((target: any) => target.status !== 5);

      ymaDatas({ ...ymaData, tgs: targetDatas });
      targets.current = targetDatas;
      setStatus(9);
      notificationController.success({ message: '검색 대상자 목록을 불러왔습니다.', duration: 1 });
    },
  });

  const [searchYMTargetsByName] = useLazyQuery(searchYMTargetsByNameGQL, {
    onCompleted: (data) => {
      if (data.searchYMTargetsByName.targets.length === 0) {
        targets.current = [];
        ymaDatas({ ...ymaData, tgs: [] });
        setStatus(9);

        notificationController.success({ message: '검색 대상자 목록을 불러왔습니다.', duration: 1 });
        return;
      }
      const tgs = clonedeep(data.searchYMTargetsByName.targets);
      const targetDatas = tgs.filter((target: any) => target.status !== 5);
      console.log(targetDatas, 'tgs data');

      targets.current = targetDatas;
      ymaDatas({ ...ymaData, tgs: targetDatas });
      setStatus(9);

      console.log('here', tgs);

      notificationController.success({ message: '검색 대상자 목록을 불러왔습니다.', duration: 1 });
    },
  });

  const [getAllYMExecutersHandler, getAllYMExecutesRequest] = useLazyQuery(getAllYMExecutersGQL, {
    onCompleted: (data) => {
      if (data.allYMExecuters === null) return;
      //console.log('getAllYMExecutersHandler:'+data.allYMExecuters.length);
      //executers.current = data.allYMExecuters;
      ymaDatas({ ...ymaData, eqs: data.allYMExecuters });
    },
  });

  const [getYMGroupByCodeHandler, getYMGroupByCodeRequest] = useLazyQuery(getYMGroupByCodeGQL, {
    onCompleted: (data) => {
      if (data.getYMGroupByCode === null) return;
      //console.log('getYMGroupByCoceHandler:'+data.getYMGroupByCode);
      if (data.getYMGroupByCode.group !== null) {
        //d console.log(data.getYMGroupByCode.id);
        // create target
        createTarget(data.getYMGroupByCode.id);
      } else {
        createGroup();
      }
    },
  });

  const [createYMGroup, createYMGroupRequest] = useMutation(createYMGroupGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.createYMGroup.success === true) {
        //console.log('createYMGroup - data:'+data.createYMGroup.data)
        // create target
      } else {
        console.log(data.updateYMTarget.error);
      }
    },
  });

  const [createYMTarget, createYMTargetRequest] = useMutation(createYMTargetGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.createYMTarget.success === true) {
      } else {
        console.log(data.updateYMTarget.error);
      }
    },
  });

  const [getYMCountGQLHandler, getYMCountGQLRequest] = useLazyQuery(getYMCountGQL, {
    onCompleted: (data) => {
      if (data.getYMCount.success === true) {
        ymDatas({
          ...ymDatas(),
          tg: {
            ...ymDatas().tg,
            data: {
              ...ymDatas().tg.data,
              uid:
                data.getYMCount.code +
                (data.getYMCount.count < 10 ? '0' + data.getYMCount.count : data.getYMCount.count),
            },
          },
        }); // update cache
        getGrouByCode();
      }
    },
    fetchPolicy: 'network-only',
  });

  const getGrouByCode = () => {
    //d console.log("ymData.gcode:"+ymData.gcode)
    getYMGroupByCodeHandler({
      variables: { code: ymData.gcode },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    });
  };
  const getTargetCode = () => {
    if (ymData.tg.data.uid.substring(8, 10) === '##') {
      const gcode = ymData.tg.data.uid.substring(0, 8);
      //d console.log('code:'+gcode);
      getYMCountGQLHandler({
        variables: { code: gcode },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    } else getGrouByCode();
  };

  const createGroup = () => {
    const today = new Date();
    const owner = ymaData.eqid;
    createYMGroup({
      variables: {
        input: {
          code: ymData.gcode,
          title: regions[ymData.gregion] + '-' + ymData.gname,
          category: 'ym_exam',
          owner: owner,
          reservedAt: today,
          data: JSON.stringify({
            region: regions[ymData.gregion],
            code: ymData.gregion,
            name: ymData.gname,
            date: 1,
          }),
        },
      },
    })
      .then((result) => {
        const {
          data: {
            createYMGroup: { success, data, id },
          },
        } = result;
        createTarget(id);
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };
  const handleExamineeOk = (bNew: any) => {
    console.log(ymData.tg, 'ymData.tg');
    if (bNew) getTargetCode();
    // else updateTarget();
    else {
      const today = new Date();
      const owner = ymaData.eqid;
      createYMGroup({
        variables: {
          input: {
            code: ymData.gcode,
            title: regions[ymData.gregion] + '-' + ymData.gname,
            category: 'ym_exam',
            owner: owner,
            reservedAt: today,
            data: JSON.stringify({
              region: regions[ymData.gregion],
              code: ymData.gregion,
              name: ymData.gname,
              date: 1,
            }),
          },
        },
      })
        .then((result) => {
          const {
            data: {
              createYMGroup: { success, data, id: gid },
            },
          } = result;
          const targetData = { ...ymData.tg.data };
          updateYMTarget({
            variables: {
              id: ymData.tg.id,
              input: {
                title: ymData.tg.title,
                group: gid,
                data: JSON.stringify(targetData),
                reservedAt: ymData.tg.reservedAt,
              },
            },
          });
        })
        .catch((err) => {
          //console.log('login error',createUserError)
          //d console.log(err)
        });
    }

    setExamineeOpen(false);
  };

  const createTarget = (gid: any) => {
    // gen uid
    //d console.log('ymData.tg.data.uid:'+ymData.tg.data.uid);

    const bdate = !ymData.tg.data.bdate
      ? ''
      : typeof ymData.tg.data.bdate === 'number'
      ? ymData.tg.data.bdate
      : dayjs(ymData.tg.data.bdate).valueOf();

    const diffDate = ymData.tg.reservedAt - bdate;

    console.log('createTarget', bdate, diffDate, ymData.tg.reservedAt);

    createYMTarget({
      variables: {
        input: {
          title: ymData.tg.data.name,
          category: 'ym_examinee',
          owner: ymData.eqid,
          group: gid,
          data: JSON.stringify({
            uid: ymData.tg.data.uid,
            name: ymData.tg.data.name,
            // bdate: ymData.tg.data.bdate,
            bdate: bdate,
            sex: ymData.tg.data.sex,
            epweek: ymData.tg.data.epweek,
            epday: ymData.tg.data.epday,
            privacyAgreed: ymData.tg.data.privacyAgreed,
          }),
          reservedAt: ymData.tg.reservedAt.toString(),
        },
      },
    })
      .then((result) => {
        const {
          data: {
            createYMTarget: { success, data, id },
          },
        } = result;
        //d console.log('createYMTarget - data:'+data)
        updateExaminer();
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };

  const [updateYMTarget, updateYMTargetRequest] = useMutation(updateYMTargetGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMTarget.success === true) {
      } else {
        console.log(data.updateYMTarget.error);
      }
    },
  });

  const updateTarget = () => {
    const exids = ymData.examiners.map((ex: any, idx: number) => {
      if (ex.selected) return ex.id;
      else return null;
    });
    updateYMTarget({
      variables: {
        id: ymData.tg.id,
        input: {
          reservedAt: ymData.tg.reservedAt.toString(),
          executers: exids,
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMTarget: { success, data, id },
          },
        } = result;
        //d console.log('updateYMTarget - data:'+data)
        updateExaminer();
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };

  const deleteTargetByTgId = () => {
    if (selectedTarget) {
      updateYMTarget({
        variables: {
          id: selectedTarget.id,
          input: {
            status: 5,
          },
        },
      })
        .then((result) => {
          const {
            data: {
              updateYMTarget: { success, data, id },
            },
          } = result;
          //d console.log('updateYMTarget - data:'+data)
          updateExaminer();
          onToggleModal();
        })
        .catch((err) => {
          //console.log('login error',createUserError)
          //d console.log(err)
        });
    }
  };

  const deleteTarget = () => {
    const exids = ymData.examiners.map((ex: any, idx: number) => {
      if (ex.selected) return ex.id;
      else return null;
    });
    updateYMTarget({
      variables: {
        id: ymData.tg.id,
        input: {
          status: 5,
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMTarget: { success, data, id },
          },
        } = result;
        //d console.log('updateYMTarget - data:'+data)
        updateExaminer();
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };

  const updateTableData = (status: any) => {
    setTableData({
      data: [],
      pagination: { ...tableData.pagination, total: targets.current.length, position: ['bottomCenter'] },
      loading: true,
    });

    const cTableData: any[] = [];

    targets.current.map((target: any, idx: number) => {
      if (target.tasks.length !== 0) {
        let status = 0;
        let assessCount = 0;
        target.tasks.map((task: any, idx: number) => {
          if (task?.category?.includes('assess')) {
            assessCount += 1;
          }
          if (!task?.category?.includes('assess')) {
            status += task.status;
          }
        });

        target.status = Math.floor(status / (target.tasks.length - assessCount));
      }

      if (status === 9) {
      }

      if (status === 4 && target?.group?.status === 4) {
        putToTableData(cTableData, target, idx);
      } else if (status === 9) {
        putToTableData(cTableData, target, idx);
      } else if (target.status === status) {
        putToTableData(cTableData, target, idx);
      }
    });
    setTableData({
      data: cTableData,
      pagination: {
        ...initialPagination,
        current: tableData.pagination.current,
        total: cTableData.length,
        position: ['bottomCenter'],
      },
      loading: false,
    });
  };

  useEffect(() => {
    setTableData({
      data: [],
      pagination: { ...tableData.pagination, total: targets.current.length, position: ['bottomCenter'] },
      loading: true,
    });

    //console.log('useEffect TableData');

    const cTableData: any[] = [];
    let onProgressCount = 0;
    let groupCount = 0;
    let completed = 0;
    let scheduled = 0;
    // 예정: 0 , 진행중 : 1, 완료: 2,
    targets.current.map((target: any, idx: number) => {
      if (target.tasks.length !== 0) {
        let status = 0;
        let assessCount = 0;
        //console.log(target, 'target');
        target.tasks.map((task: any, idx: number) => {
          if (task?.category?.includes('assess')) {
            assessCount += 1;
          }
          if (!task?.category?.includes('assess')) {
            status += task.status;
          }
        });

        const taskLength = target.tasks.filter((task: any) => {
          // console.log(task, 'task');
          const data = task?.data === '' ? {} : typeof task?.data === 'string' ? JSON.parse(task?.data) : task?.data;
          const newItem = { ...data };
          //console.log(newItem, 'newItem');
          return !!newItem?.video;
        });
        //console.log(taskLength, target.tasks.length, 'length', target.tasks, assessCount, status);

        target.status = Math.floor(status / (target.tasks.length - assessCount));
      }

      if (target?.group?.status === 4) {
        // 진행중
        groupCount++;
      } else if (target.status === 1 && target?.group?.status < 4) {
        // 기관평가
        onProgressCount++;
      } else if (target.status === 0) {
        // 예정
        scheduled++;
      } else if (target.status === 2) {
        completed++;
      }

      if (status === 4 && target?.group?.status === 4) {
        putToTableData(cTableData, target, idx);
      } else if (status === 9 && target?.group?.status < 4) {
        putToTableData(cTableData, target, idx);
      } else if (target.status === status && target?.group?.status < 4) {
        putToTableData(cTableData, target, idx);
      } else if (status === 9 && target?.group?.status === 4) {
        // groupCount++;
      }
    });

    //console.log('onProgressData', onProgressCount, scheduled, completed, groupCount);
    setCount({
      scheduled: scheduled,
      group: groupCount,
      completed: completed,
      progress: onProgressCount,
    });

    setTableData({
      data: cTableData,
      pagination: {
        ...initialPagination,
        current: tableData.pagination.current,
        total: cTableData.length,
        position: ['bottomCenter'],
      },
      loading: false,
    });
  }, [status]);

  const putToTableData = (tableData: any, target: any, idx: number) => {
    const statuses = ['pending', 'assigned', 'completed', 'deleted'];
    const oData = JSON.parse(target.data);
    const oGroup = target.group !== null ? JSON.parse(target.group.data) : { region: '미정', name: '미정' };
    const targetGroupStatus = target?.group?.status;
    const gname = oGroup.name +  (oGroup.name !== '학부모검사'? (target.group.code.length === 5 ? '(23)':''): '');
    // console.log(target.status, 'target');

    tableData.push({
      key: idx,
      schedule: parseInt(target.reservedAt),
      executer: target.executers.length > 0 ? target.executers[0].name : '미정',
      type: targetGroupStatus === 4 ? statuses[targetGroupStatus] : statuses[target.status],
      status: target.status,
      name: oData.name,
      group: oData.uid,
      gregion: oGroup.region,
      gname: gname,
      gstatus: target?.group?.status,
      video: target.tasks,
      bdate: oData.bdate,
      address: 'New York No. 1 Lake Park',
      targetId: target?.id,
      targetName: target.title,
      rdate: target.reservedAt,
      exMonth: getFormatedAge(getExamMonthFromReserved( oData.bdate, oData.epweek, oData.epday, Number( target.reservedAt)),1),
      tags: [
        { value: '중요', priority: Priority.LOW },
        { value: '다시 검사', priority: Priority.MEDIUM },
      ],
    });
  };

  useEffect(() => {
    getAllYMExecutersHandler({
      variables: {},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    });

    //updateExaminer();
  }, []);

  useEffect(() => {
    console.log('************' + ymaData.eqid)
    if (ymaData.eqid !== '' ) updateExaminer();
  }, [ymaData.eqid]);

  useEffect(() => {
    if (isSidebarClick) {
      updateExaminer();
      // setStatus(9);
      isSameClickSideBar(false);
    }
  }, [isSidebarClick]);

  //setExamineeData(targets);

  const columns: ColumnsType<ExamineeTableRow> = [
    {
      title: t('common.schedule'),
      dataIndex: 'schedule',
      render: (text: string, record: { type: any; key: number; schedule: number; executer: string }) => {
        return (
          <>
            <BaseButton
              round
              buttontype={record.type}
              onClick={() => handleSchedule(record.key)}
              size="small"
              style={{
                fontWeight: 400,
                fontSize: '16px',
              }}
            >
              {Dates.format(record.schedule, 'YYYY.MM.DD(ddd)')} : {record.executer}
            </BaseButton>
          </>
        );
      },
      //<BaseButton round buttontype={record.type} onClick={()=>handleSchedule(record.key)} size="small">{Dates.format(record.schedule, 'YYYY.MM.DD(ddd) HH:mm')} : {record.executer}</BaseButton>
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.schedule - b.schedule,
    },
    {
      title: t('common.name'),
      dataIndex: 'name',
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.name.localeCompare(b.name),
      showSorterTooltip: true,
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', color: '#354ACB', fontWeight: 400 }}
          onClick={() => {
            // setSelectedTarget({ id: record?.targetId });
            // onToggleModal();
            handleExaminee(record.key);
            setIsUpdateTarget(true);
          }}
        >
          {record?.targetName}
        </span>
      ),
    },
    {
      title: '검사 월수',
      dataIndex: 'bdate',
      showSorterTooltip: true,
      // render: (text: string, record: { bdate: string }) => <>{getMonth(record.bdate)} 개월</>,
      render: (text: string, record) => {
        //const resultDate = calculateMonthsDifference(dayjs(record?.bdate).valueOf(), parseInt(record?.rdate));

        return (
          <>
            {record?.exMonth}
            {/*resultDate / 12 > 3 ? `${(resultDate / 12) | 0}세 ${resultDate % 12}개월` : `${resultDate}개월`*/}
            {/*{getMonth(record.bdate) / 12 > 3*/}
            {/*  ? `${(getMonth(record.bdate) / 12) | 0}세 ${getMonth(record.bdate) % 12}개월`*/}
            {/*  : `${getMonth(record.bdate)}개월`}*/}
          </>
        );
      },
    },
    {
      title: '지역',
      dataIndex: 'gregion',
      showSorterTooltip: true,
      render: (text: string, record: { gregion: string }) => <>{record.gregion}</>,
    },
    {
      title: '기관명',
      dataIndex: 'gname',
      render: (text: string, record: { key: number; gname: string; gstatus: number }) => (
        <a onClick={() => handleGSchedule(record.key)} style={{ color: '#354ACB', fontWeight: 400 }}>
          {record.gstatus === 4 ? '(평가완료)' : ''}
          {record.gname}
        </a>
      ),
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.gname.localeCompare(b.gname),
      showSorterTooltip: true,
    },
    {
      title: '검사 영상',
      dataIndex: 'video',
      width: '12%',
      render: (text: string, record: { name: string; status: number; key: number; video: any }) => {
        let v = 'https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch';
        if (record.video.length > 0) {
          const videos = JSON.parse(record.video[0].data);
          v = videos.video; // "https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch";
          //const t = videos.thumb; //"https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/thumbnails/thumbnail.jpg";
        }
        //console.log('video recod.key:'+record.key);
        const examTaskVideos = record.video.filter(
          (item: any) => item?.category && !item?.category?.includes('assess'),
        );
        //console.log(record.status, 'status');

        //                type={examTaskVideos?.length > 0 && record.status > 1 ? 'primary' : 'default'}
        return (
          <BaseSpace>
            {/*<BaseButton round type={(record.video.length > 0 && record.status > 1) ?"primary":"default"} size="small" onClick={() => (record.video.length > 0 && record.status > 1) ? handleVideo(record.key):{}}>
              {t('tables.video')}*/}
            <BaseButton
              round
              type={examTaskVideos?.length > 0 && record.status > 1 ? 'primary' : 'default'}
              size="small"
              onClick={() => handleVideo(record.key)}
            >
              {t('tables.video')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
    {
      title: '검사 삭제',
      dataIndex: 'actions',
      width: '12%',
      render: (text: string, record: { name: string; key: number }) => {
        //console.log('admin recod.key:'+record.key);
        return (
          <BaseSpace>
            <BaseButton
              size="small"
              type="text"
              icon={<MinusCircleOutlined />}
              onClick={() => handleMinus(record.key)}
            />
          </BaseSpace>
        );
      },
    },
  ];

  const query = (keyword: string, query: string) => {
    //d console.log('setQueryOption('+query+'):'+keyword);
    let qkey = 0;
    if (query === 'all') qkey = -1;
    else if (query === 'processed') qkey = 0;
    else if (query === 'completed') qkey = 4;
    else if (query === 'deleted') qkey = 5;

    searchExaminer(keyword, qkey);
  };

  const isTwoColumnsLayout = false;

  const rightSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={2} xxl={2}></BaseCol>
        <BaseCol xl={13} xxl={10}>
          {t('common.examinee-dashboard')}
        </BaseCol>
        <BaseCol>
          <S.GHButton />
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={14} xxl={14}>
        <BaseSpace>
          <BaseFormTitle style={{ padding: 0, paddingRight: 40 }}>{t('common.examinee-dashboard')}</BaseFormTitle>
          <BaseButton
            round
            type="default"
            buttontype="button"
            onClick={() => {
              handleExaminee(-1);
              setIsUpdateTarget(false);
            }}
            size="small"
            icon={<PlusOutlined />}
          >
            대상자 등록하기
          </BaseButton>
          <BaseButton
            round
            type="default"
            buttontype="button"
            onClick={() => handleGroupAdd()}
            size="small"
            icon={<PlusOutlined />}
          >
            기관 추가
          </BaseButton>
        </BaseSpace>
      </BaseCol>
      <BaseCol lg={10} xxl={10}></BaseCol>
      <BaseCol></BaseCol>
    </>
  );

  const tagSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between" style={{ position: 'relative' }}>
        <BaseColWrapper xl={15} xxl={12}>
          <HeaderSearch query={query} handleSearch={handleSearch} />
        </BaseColWrapper>
        <BaseCol>
          <S.GHButton />
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseSpace style={{ padding: 1 }}>
        <BaseButton
          round
          type="default"
          onClick={() => {
            searchParams.delete('status');
            navigate({ search: searchParams.toString() });

            setStatus(9);
          }}
          size="small"
          icon={status === 9 && <CheckOutlined />}
        >
          전체
        </BaseButton>
        <BaseButton
          round
          type="default"
          onClick={() => {
            setStatus(0);
            searchParams.set('status', '0');
            navigate({ search: searchParams.toString() });
          }}
          buttontype={'pending'}
          size="small"
          icon={status === 0 && <CheckOutlined />}
        >
          {/*예정 {status === 0 ? '(' + tableData.data.length + ')' : ''}*/}
          예정 ({count.scheduled})
        </BaseButton>
        <BaseButton
          round
          type="default"
          onClick={() => {
            searchParams.set('status', '1');
            setStatus(1);
            navigate({ search: searchParams.toString() });
          }}
          buttontype={'assigned'}
          size="small"
          icon={status === 1 && <CheckOutlined />}
        >
          {/*진행중 {status === 1 ? '(' + tableData.data.length + ')' : ''}*/}
          진행중 ({count.progress})
        </BaseButton>
        <BaseButton
          round
          type="default"
          onClick={() => {
            searchParams.set('status', '2');
            setStatus(2);
            navigate({ search: searchParams.toString() });
          }}
          buttontype={'completed'}
          size="small"
          icon={status === 2 && <CheckOutlined />}
        >
          {/*완료 {status === 2 ? '(' + tableData.data.length + ')' : ''}*/}
          완료 ({count.completed})
        </BaseButton>
        <BaseButton
          round
          type="default"
          onClick={() => {
            searchParams.set('status', '4');
            setStatus(4);
            navigate({ search: searchParams.toString() });
          }}
          buttontype={'completed'}
          size="small"
          icon={status === 4 && <CheckOutlined />}
        >
          {/*기관평가완료 {status === 4 ? '(' + tableData.data.length + ')' : ''}*/}
          기관검사완료 
        </BaseButton>
      </BaseSpace>
    </>
  );

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}></BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={5} xxl={4}>
        {tagSide}
      </BaseCol>
      <BaseColWrapper lg={5} xxl={4}>
        <BaseSpace>
          <HeaderSearch query={query} handleSearch={handleSearch} />
        </BaseSpace>
      </BaseColWrapper>
    </>
  );

  return (
    <>
      <BaseRow justify="space-between" align="middle" gutter={[15, 15]}>
        {rightSide}
      </BaseRow>
      <BaseRow justify="space-between" align="middle" gutter={[15, 15]} style={{ paddingBottom: 10 }}>
        {leftSide}
      </BaseRow>

      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />

      <Modal
        title={title}
        maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}
        open={examineeopen}
        onOk={(bNew: any) => handleExamineeOk(bNew)}
        onCancel={handleExamineeCancel}
        width={'56rem'}
        footer={null}
        keyboard={false}
      >
        <RegistrationForm
          onOk={handleExamineeOk}
          onCancel={handleExamineeCancel}
          isTargetUpdate={isUpdateTarget}
          updateExaminer={updateExaminer}
        />
      </Modal>
      <Modal
        title="검사 일정"
        maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'62rem'}
        footer={null}
      >
        <ScheduleForm onOk={handleOk} onCancel={handleCancel} />
      </Modal>
      <Modal
        title="기관 설정 변경"
        maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}
        open={gscheduleopen}
        onOk={handleGSOk}
        onCancel={handleGSCancel}
        width={'40rem'}
        footer={null}
      >
        <GroupScheduleForm onOk={handleGSOk} onCancel={handleGSCancel} />
      </Modal>
      {/*<Modal*/}
      {/*  title="기관 추가"*/}
      {/*  maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}*/}
      {/*  open={gaddopen}*/}
      {/*  onOk={handleGAddOk}*/}
      {/*  onCancel={handleGAddCancel}*/}
      {/*  width={'40rem'}*/}
      {/*  footer={null}*/}
      {/* */}
      {/*>*/}
      <GroupAddForm gaddopen={gaddopen} onOk={handleGAddOk} onCancel={handleGAddCancel} />

      <Modal
        title="영상 보기"
        maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}
        open={videoopen}
        onOk={handleVideoOk}
        onCancel={handleVideoCancel}
        width={'85rem'}
        footer={null}
        maskClosable={false} // 여기서 maskClosable을 false로 설정
        closable={false}
      >
        <VideoForm
          open={videoopen}
          onOk={handleVideoOk}
          onCancel={handleVideoCancel}
          onUpdate={onUpdate}
          searchExam={handleSearch}
        />
      </Modal>
      <Modal
        title="검사자"
        open={minusopen}
        onOk={handleMinusOk}
        confirmLoading={confirmLoading}
        onCancel={handleMinusCancel}
      >
        <p>검사자를 목록에서 뺍니다.</p>
      </Modal>

      <TargetInfoModal
        open={targetInfoModal}
        closeModal={onToggleModal}
        onOk={() => {}}
        selectedTarget={selectedTarget}
        refetch={updateExaminer}
        setSelectedTarget={setSelectedTarget}
        isExamTable={true}
        onRemove={deleteTargetByTgId}
      />
    </>
  );
};

export const BaseColWrapper = styled(BaseCol)`
  .ant-space {
    position: absolute !important;
    right: 10px !important;
    top: -21px !important;
  }
`;
