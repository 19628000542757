import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';

import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { createYMExecuterGQL, getYMExecuterGQL, signUpLoginM } from '@app/hocs/gql';
import { userDatas, ymDatas, ymaDatas, isKeepLoginBySocialLogin } from '@app/hocs/cache';
import naverIcon from '@app/assets/icons/naver.svg';
import googleIcon from '@app//assets/icons/login_google.svg';
import kakaoIcon from '@app//assets/icons/kakao.svg';
import { GOOGLE_REDIRECT_URL, isLocal, KAKAO_REDIRECT_URL, NAVER_REDIRECT_URL } from '@app/constants/YMData';
import TermModal from '@app/components/modal/TermModal';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { processDownload } from '@app/utils/downloader';

interface LoginFormData {
  email: string;
  password: string;
}

export const socialLoginLists: { image: string; value: 'kakao' | 'google' | 'naver' }[] = [
  { image: kakaoIcon, value: 'kakao' },
  // { image: googleIcon, value: 'google' },
  // { image: naverIcon, value: 'naver' },
];

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  console.log(location.pathname.split('/')[2], 'loca');
  const isParentLoginPath = location.pathname.split('/')[2] && location.pathname.split('/')[2] === 'parent-login';
  const [isLoading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false); //check this  userDatas().uid !== '');
  const [keepLogin, setKeepLogin] = useState(false); //check this  userDatas().uid !== '');
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);

  const naverLoginBtn = useRef<HTMLDivElement>(null);
  const [recentLoginInfo, setRecentLoginInfo] = useState<string | null>(null);
  const getAuthUrlByProvider = (provider: 'google' | 'naver' | 'kakao') => {
    switch (provider) {
      case 'google':
        const SCOPE = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
        return `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URL}&scope=${SCOPE}&response_type=code`;

      case 'kakao':
        return `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URL}`;

      case 'naver':
        if (naverLoginBtn.current) {
          const win = window as any;
          const naver_id_login = new win['naver_id_login'](
            process.env.REACT_APP_NAVER_CLIENT_ID,
            NAVER_REDIRECT_URL,
          ) as any;
          naver_id_login.setButton('white', 2, 40);

          naver_id_login.init_naver_id_login();
          const anchor = naverLoginBtn.current.querySelector('a');
          if (anchor) {
            anchor.click();
          }
        }
    }
  };

  const getXPos = (provider: string) => {
    // 100px -12px 90px
    console.log(provider, 'provider');
    switch (provider) {
      case 'kakao':
        return '-115px';

      case 'google':
        return '-12px';

      case 'naver':
        return '90px';

      default:
        return '';
    }
  };

  const [signUpLogin, signUpLoginRequest] = useMutation(signUpLoginM, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.signUpLoginM.success === true) {
        //setMessage('');
        //setLogin( data.signUpLoginM.id === ''? 2:1);
        //localStorage.setItem('userToken' , data.signUpLoginM.token);
        //console.log('set uid by signin:'+data.signUpLoginM.id);
        //userDatas({...userDatas(), uid:'login'});
        //localStorage.setItem('exName' , email);
        //localStorage.setItem('exID' , data.signUpLoginM.id);
      } else {
        //setMessage(data.signUpLoginM.error);
      }
    },
  });

  const onToggleModal = () => {
    setModal((prevState) => !prevState);
  };

  const nextLogin = () => {
    //localStorage.setItem('userID',id);
    //localStorage.setItem('exID',id);
    setLoggedIn(true);
    setLoading(true);
    if (isParentLoginPath) {
      console.log('isParentLoginPath isParentLoginPath');
      // navigate('/accessallow');

      navigate('/parent/accessallow');
      return;
    }
    navigate('/accessallow');
    //navigate('/member/examinee-dashboard')
    //props.completed(id);
  };

  const handleSubmit = (values: LoginFormData) => {
    signUpLogin({ variables: { username: values.email, password: values.password } })
      .then((result) => {
        const {
          data: {
            signUpLoginM: { success, token, id },
          },
        } = result;
        if (success === true) {
          const category =
            location.pathname.split('/')[2] && location.pathname.split('/')[2] === 'parent-login' ? 'parent' : '';
          localStorage.setItem('userCategory', category);

          userDatas({ ...userDatas(), uid: 'login', token: token, category, type: 'local' });
          if (isParentLoginPath) {
            localStorage.setItem('parentRecentLogin', 'local');
          } else {
            localStorage.setItem('recentLogin', 'local');
          }

          if (isParentLoginPath && keepLogin) {
            console.log('datadatadatadata');

            localStorage.setItem('parentUserToken', token);
            nextLogin();

            return;
          }

          if (!isParentLoginPath && keepLogin) {
            localStorage.setItem('userToken', token);
            nextLogin();

            return;
          }
          nextLogin();
          /*
        getExecuterHandler({variables: {uid: id},notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        });
*/
        } else {
          setMessage('패스워드가 잘못 되었습니다.');
          //result.data.signUpLoginM.error);
        }
      })
      .catch((err) => {
        console.log('login error', signUpLoginRequest.error);
        console.log(err);
      });

    /*
    dispatch(setUser('res.user'));
    persistToken('res.token');
    navigate('/member/examinee-dashboard')
    dispatch(doLogin(values))
      .unwrap()
      .then(() => navigate('/member/examinee-dashboard'))
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });*/
  };
  const recentLogin = localStorage.getItem('recentLogin');
  const parentRecentLogin = localStorage.getItem('parentRecentLogin');
  useEffect(() => {
    const recentLogin = localStorage.getItem('recentLogin');
    // setRecentLoginInfo('naver');
    if (recentLogin) {
      setRecentLoginInfo(recentLogin);
    }
  }, []);
  console.log(recentLogin, 'recentLogin');
  return (
    <Auth.FormWrapper style={{ background: '#fff' }}>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.welcome')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox
              checked={keepLogin}
              onChange={() => {
                setKeepLogin(!keepLogin);
              }}
            >
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>

            <Auth.ForgotPasswordLinkText to="/forgot-password">{t('common.forgotPass')}</Auth.ForgotPasswordLinkText>
          </BaseForm.Item>
          {/*
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link> */}
        </Auth.ActionsWrapper>
        <S.LoginDescription>{message}</S.LoginDescription>

        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to={isParentLoginPath ? '/auth/parent-sign-up' : '/auth/sign-up'}>
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>

          <Auth.SocialLoginContainer>
            {isParentLoginPath && (
              <Auth.SocialLoginText>
                <span>간편로그인</span>
              </Auth.SocialLoginText>
            )}

            {isParentLoginPath && (
              <Auth.SocialLoginIconBox>
                {socialLoginLists.map((item) => (
                  <li key={item.value}>
                    {item.value === 'naver' ? (
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const category =
                            location.pathname.split('/')[2] && location.pathname.split('/')[2] === 'parent-login'
                              ? 'parent'
                              : '';
                          localStorage.setItem('userCategory', category);
                          if (keepLogin) localStorage.setItem('isKeepLoginBySocialLogin', 'true');
                          isKeepLoginBySocialLogin(true);

                          getAuthUrlByProvider(item.value!);
                        }}
                      >
                        <img src={item.image} alt={`${item.value} icon`} />
                      </span>
                    ) : (
                      <a
                        href={getAuthUrlByProvider(item.value ?? '')}
                        onClick={() => {
                          const category =
                            location.pathname.split('/')[2] && location.pathname.split('/')[2] === 'parent-login'
                              ? 'parent'
                              : '';
                          if (keepLogin) localStorage.setItem('isKeepLoginBySocialLogin', 'true');

                          localStorage.setItem('userCategory', category);
                        }}
                      >
                        <img src={item.image} alt={`${item.value} icon`} />
                      </a>
                    )}
                  </li>
                ))}
              </Auth.SocialLoginIconBox>
            )}
            {/*{!isParentLoginPath && recentLogin && recentLogin !== 'local' && (*/}
            {/*  <Auth.Balloon xPos={getXPos(recentLogin)}>가장 최근에 사용한 로그인 방법입니다.</Auth.Balloon>*/}
            {/*)}*/}

            {isParentLoginPath && parentRecentLogin && parentRecentLogin !== 'local' && (
              <Auth.Balloon xPos={getXPos(parentRecentLogin)}>가장 최근에 사용한 로그인 방법입니다.</Auth.Balloon>
            )}
          </Auth.SocialLoginContainer>

          <div id="naver_id_login" style={{ display: 'none' }} ref={naverLoginBtn} />
          <Auth.Text style={{ cursor: 'pointer' }}>
            회원가입 시 mbisq의 <Auth.LinkText onClick={onToggleModal}>이용 약관과 개인정보 보호정책</Auth.LinkText>에
            동의하게 됩니다.
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
      <TermModal title="이용 약관 - 정보제공 동의" open={modal} onClose={onToggleModal} width="400px" />
    </Auth.FormWrapper>
  );
};

//
