import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import { AppDate, Dates } from 'constants/Dates';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { ExamCalendar } from './ExamCalendar/ExamCalendar';
import { ScheduleEvent, getUserCalendar } from '@app/api/schedule.api';
import { getExaminersData, Examiner } from '@app/api/examiners.api';
import { ExaminersPanel } from './ExaminersPanel';
import { AppointmentPanel } from './AppointmentPanel';
import { Divider } from 'antd';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { getAllYMExecutersGQL } from '@app/hocs/gql';
import dayjs from 'dayjs';
import { userDatas, ymDatas, ymaDatas } from '@app/hocs/cache';

export const ScheduleCard = (props:any) => {
  const { isTablet } = useResponsive();

  const [calendar, setCalendar] = useState<ScheduleEvent[]>([]);
  const [selectedDate, setDate] = useState<AppDate>(Dates.getToday());
  const [isDateClicked, setDateClicked] = useState(false);

  //const [examiners, setExaminers] = useState<Examiner[]>([]);
  const [selectedExaminer, setExaminer] = useState<Examiner>();
  const [isExaminerClicked, setExaminerClicked] = useState(false);

  //const user = props.examinee; //useAppSelector((state) => state.user.user);
  //const executers = useRef<any[]>([]);
  const ymData = useReactiveVar(ymDatas);


  useEffect(() => {
    
    // get calendar
    //user && getUserCalendar(user?.id).then((res) => setCalendar(res)); // -> calendar
    // get examiner
    //getExaminersData().then((res) => setExaminers(res)); // -> examiner

    //setExaminers(ymDatas().examiners);
    setDate(dayjs(parseInt(ymData.tg.reservedAt))); //new Date(parseInt(user.reservedAt))  

  }, [ymDatas().tg]);

  const { i18n, t } = useTranslation();
/*
  useEffect(() => {
    setDate((selectedDate) => selectedDate.locale(i18n.language));
  }, [i18n.language]);
*/
  
  useEffect(() => {
    if (selectedExaminer === undefined) return;
    console.log('selectedExaminer:'+selectedExaminer.name);
    console.log('selectedExaminer:'+selectedExaminer.id);
    //setExaminers({...examiners, selectedExaminer})
    const exs = ymData.examiners.map( (v:any) => {
      if( v.id === selectedExaminer.id ) {
        if( v.selected === true ) return {...v, selected:false};
        else return {...v, selected:true};
      } 
      return {...v};
    })
    ymDatas({...ymData, examiners:exs}); // update cache
  }, [selectedExaminer]);

  useEffect( ()=>{
    if( isDateClicked === false ) return;
    console.log('selectedDate:'+selectedDate.format('YYYY-MM-DD'));
    const before = new Date(parseInt(ymData.tg.reservedAt))
    const hour = before.getHours();
    const min = before.getMinutes();
    selectedDate.set('hour', hour);
    selectedDate.set('minute', min);
    //ymDatas().tg.reservedAt = selectedDate.toDate().getTime();
    ymDatas({...ymData, tg:{...ymData.tg, reservedAt:selectedDate.toDate().getTime()}}); // update cache
    setDateClicked(false);
  }, [isDateClicked])

  const handleDecreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() - 1));
  };

  const handleIncreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() + 1));
  };

  const handleToday = () => {
    setDate(Dates.getToday());
  };

  const calendarItem = (
    <ExamCalendar calendar={calendar} date={selectedDate}
      setDate={setDate}
      onDecrease={handleDecreaseMonth}
      onIncrease={handleIncreaseMonth}
      onToday={handleToday}
      setDateClicked={setDateClicked}
    />
  );

  const currentEvent = calendar.find((event) => Dates.getDate(event.date).isSame(selectedDate, 'date'));

  const panelExaminers = <ExaminersPanel event={currentEvent} setExaminer={setExaminer} setExaminerClicked={setExaminerClicked} />;
  const panelTime = <AppointmentPanel event={currentEvent} setExaminer={setExaminer} setExaminerClicked={setExaminerClicked} /> 
  //<>Hello{( selectedExaminer !== undefined ) && selectedExaminer.name}/{Dates.format(selectedDate, 'YY MM DD')}</>; //<ExaminersPanel event={currentEvent} />;

  return (
    <DashboardCard title={''} style={{backgroundColor:'#EBEFFF'}}>
      <RowStyled gutter={[10, 10]} wrap={false}>
        {isTablet ? (
          <>
            <BaseCol span={8}>{calendarItem}</BaseCol>
            <BaseCol span={1}><Divider type="vertical" style={{ height: "350px" }} /></BaseCol>
            <BaseCol span={7}>{panelExaminers}</BaseCol>
            <BaseCol span={1}><Divider type="vertical" style={{ height: "350px" }} /></BaseCol>
            <BaseCol span={7}>{panelTime}</BaseCol>
          </>
        ) : (isDateClicked || isExaminerClicked) && calendar.some((event) => Dates.getDate(event.date).isSame(selectedDate, 'date')) ? (
          <BackButtonWrapper span={24}>
            {panelExaminers}
            <BackButton type="text" icon={<ArrowLeftOutlined />} onClick={() => setDateClicked(false)} />
          </BackButtonWrapper>
        ) : (
          <BaseCol span={24}>{calendarItem}</BaseCol>
        )}
      </RowStyled>
    </DashboardCard>
  );
};

const BackButtonWrapper = styled(BaseCol)`
  position: relative;
`;

const BackButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white);
`;

const RowStyled = styled(BaseRow)`
  min-height: 21.75rem;
`;
