import React, { useEffect, useRef, useState } from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { userDatas, ymDatas, ymaDatas } from '@app/hocs/cache';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  createYMExecuterGQL,
  createYMUserGQL,
  getFlowGQL,
  getUserByToken,
  getYMExecuterGQL,
  getYMUserGQL,
} from '@app/hocs/gql';
import { NavLink, useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { PARENT_PATH } from '@app/components/router/RequireAuth';

export const ProfileDropdown: React.FC = () => {
  const navigate = useNavigate();

  const { isTablet } = useResponsive();
  const userData = useReactiveVar(userDatas);
  const ymData = useReactiveVar(ymDatas);
  const [name, setName] = React.useState('');
  const useToken = useRef('');
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    //d console.log('------------------userData.token:'+userData.token);
    setName(ymData.eqname);
  }, [userData.uid]);

  const [getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
    onCompleted: (data) => {
      if (data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
        data.getFlow.flow.cards.map(async (card: any) => {
          if (card.title === 'User') {
            userDatas({
              ...userDatas(),
              nickname: userData.data.user !== undefined ? userData.data.user.name : card.subtitle,
            });
            useToken.current = userData.token;
            getExecuterHandler({
              variables: { uid: userData.uid },
              notifyOnNetworkStatusChange: true,
              fetchPolicy: 'network-only',
              // pollInterval: 500
            });
          }
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const [getUserByTokenHandler, getUserByTokenRequest] = useLazyQuery(getUserByToken, {
    onCompleted: async (data) => {
      if (data.getUserByToken.id === null) return;
      //d console.log('set uid by token:'+data.getUserByToken.id);
      localStorage.setItem('userID', data.getUserByToken.id);

      //userDatas({...userDatas(), uid:data.getUserByToken.id, fid:data.getUserByToken.user.flowid});
      //d console.log( data.getUserByToken.user.id +':' +data.getUserByToken.user.username);
      const index = localStorage.getItem('index') as string;

      const userCategory = localStorage.getItem('userCategory');
      const recentLogin =
        userCategory === 'parent'
          ? localStorage.getItem('parentRecentLogin') ?? ''
          : localStorage.getItem('recentLogin') ?? '';

      if (PARENT_PATH.includes(location.pathname)) {
        userDatas({
          ...userDatas(),
          uid: data.getUserByToken.id,
          uname: data.getUserByToken.user.username,
          index: index,
          fid: data.getUserByToken.user.flowid,
          type: recentLogin,
        });

        const getYMUser = await getYMUserHandler({
          variables: { uid: data.getUserByToken.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });

        if (getYMUser.data.getYMUser.success === false) {
          const result = await createYMUserHandler({
            variables: {
              input: {
                user: data.getUserByToken.id,
                name: '',
                category: 'parent',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
          if (getYMUser.data.getYMUser.ymuser !== null) {
            console.log(getYMUser.data.getYMUser.ymuser, 'ymuyser');
            ymDatas({
              ...ymDatas(),
              eqid: getYMUser.data.getYMUser.ymuser.id,
              eqname: getYMUser.data.getYMUser.ymuser.name,
            });

            ymaDatas({
              ...ymaDatas(),
              eqid: getYMUser.data.getYMUser.ymuser.id,
              eqname: getYMUser.data.getYMUser.ymuser.name,
              auth: getYMUser.data.getYMUser.ymuser.authorized,
            });

            const newItem = { ...getYMUser.data.getYMUser.ymuser };
            const newData =
              newItem?.data === '' ? {} : typeof newItem.data === 'string' ? JSON.parse(newItem.data) : newItem.data;
            setProfileImage(newData?.profileImage ?? '');

            userDatas({
              ...userDatas(),
              name: getYMUser.data.getYMUser.ymuser.name,
              category: 'parent',
              type: recentLogin,
            });

            setName(ymDatas().eqname);
            navigate('/overview');
          } else {
            navigate('/auth/parent-login');
            notificationController.warning({ message: '관리자에게 사용 승인을 요청해 주세요.', duration: 10 });
          }
        }

        return;
      }

      userDatas({
        ...userDatas(),
        uid: data.getUserByToken.id,
        uname: data.getUserByToken.user.username,
        index: index,
        fid: data.getUserByToken.user.flowid,
      });
      getFlowGQLHandler({ variables: { id: data.getUserByToken.user.flowid } });
    },
  });

  const getAuthInfo = () => {
    const token = userData.token;
    //const tokenKeeped = localStorage.getItem('userToken');

    //if( tokenKeeped !== null && token === '' ) token = tokenKeeped;
    // console.log('token:' + token);
    if (token !== '' && token !== null && token !== useToken.current) {
      //d console.log('getAuthInfo ---------'+token);
      useToken.current = token;
      getUserByTokenHandler({
        variables: { token: token },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // pollInterval: 500
      });
    }
  };

  useEffect(() => {
    //d console.log('init');
    //getAuthInfo();
  }, []);

  useEffect(() => {
    getAuthInfo();
  }, [userData.token]);

  const [createExecuterHandler, createExecuterRequest] = useMutation(createYMExecuterGQL, {});
  const [createYMUserHandler] = useMutation(createYMUserGQL, {});

  const [getYMUserHandler] = useLazyQuery(getYMUserGQL, {});

  const [getExecuterHandler, getExecuterRequest] = useLazyQuery(getYMExecuterGQL, {
    onCompleted: (data) => {
      if (data === undefined) return;
      if (data.getYMExecuter.success === false && userData.uid.length > 15) {
        // create Executer
        createExecuterHandler({
          variables: {
            input: {
              user: userData.uid,
              name: userData.nickname,
              category: 'exam',
              data: JSON.stringify({}),
            },
          },
        })
          .then((result) => {
            const {
              data: {
                createYMExecuter: { success, executer, id },
              },
            } = result;
            if (executer.authorized !== '') {
              ymDatas({ ...ymDatas(), eqid: executer.id, eqname: executer.name });
              ymaDatas({ ...ymaDatas(), eqid: executer.id, eqname: executer.name, auth: executer.authorized });
              //d console.log('name:'+ymDatas().eqname);
              setName(ymDatas().eqname);
            } else navigate('/auth/login');
          })
          .catch((err) => {
            //console.log('login error',createUserError)
            //d console.log(err)
          });
      } else {
        if (data.getYMExecuter.executer !== null) {
          const newItem = { ...data.getYMExecuter.executer };
          const newData =
            newItem?.data === '' ? {} : typeof newItem.data === 'string' ? JSON.parse(newItem.data) : newItem.data;
          setProfileImage(newData?.profileImage ?? '');

          ymDatas({ ...ymDatas(), eqid: data.getYMExecuter.executer.id, eqname: data.getYMExecuter.executer.name });
          ymaDatas({
            ...ymaDatas(),
            eqid: data.getYMExecuter.executer.id,
            eqname: data.getYMExecuter.executer.name,
            auth: data.getYMExecuter.executer.authorized,
          });
          //d console.log('name:'+ymDatas().eqname);
          setName(ymDatas().eqname);
          navigate('/member/examinee-dashboard');
        } else {
          navigate('/auth/login');
          notificationController.warning({ message: '관리자에게 사용 승인을 요청해 주세요.', duration: 10 });

          //ymDatas({eqid:'', eqname:'', gid:'', gdate:0, gregion:'', gcode:'A', gname:'', tg: {}, tgid:'', tgname:'', tkid: '', tk: {}, tgidx:0, update:0, modal:0, examiners:[]});
          //ymaDatas({eqs:{}, calendar:{}, tgs:{},eqid:'', eqname:''});
        }
      }
    },
  });

  return userData.uid ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar src={profileImage} alt="User" shape="circle" size={40} />
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <span>{name}</span>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
