import React, { useEffect, useRef, useState } from 'react';
import { FilterIcon } from 'components/common/icons/FilterIcon';
import { SearchOverlay } from './searchOverlay/SearchOverlay/SearchOverlay';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Btn, InputSearch } from '../YMHeaderSearch/HeaderSearch.styles';
import { useTranslation } from 'react-i18next';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { SearchOutlined } from '@ant-design/icons';
import { Cascader, Input, Select, Space } from 'antd';
import styled from 'styled-components';
import { InputWrapper } from '@app/pages/AssessmentPage';
import { useNavigate, useSearchParams } from 'react-router-dom';
const { Option } = Select;

interface SearchOverlayProps {
  setSearchQuery: (keyword: string, query: string) => void;
  isOverlayOpen: boolean;
  setOverlayOpen: (state: boolean) => void;
  handleSearch: (keyword: string, type: string) => void;
}

const searchTab = [
  { label: '아이', value: 'name' },
  { label: '담당자', value: 'executer' },
  { label: '소속기관', value: 'group' },
];

const SearchDropdown: React.FC<SearchOverlayProps> = ({
  setSearchQuery,
  isOverlayOpen,
  setOverlayOpen,
  handleSearch,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState('' as string);
  const { t } = useTranslation();
  const [isComposing, setIsComposing] = useState(false);
  const [type, setType] = useState({ label: '아이', value: 'name' });
  const [searchParams] = useSearchParams();

  const selectBefore = (
    <Select
      style={{ width: '100px', minWidth: '100px' }}
      defaultValue={type.label}
      onChange={(e) => {
        const filteredTab = searchTab.filter((item) => item.value === e);
        setType(filteredTab[0]);

        searchParams.set('searchType', e);
        navigate({ search: searchParams.toString() });
      }}
    >
      {searchTab.map((item) => {
        if (item.label === type.label) return;
        return (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        );
      })}
      {/*<Option value="name">아이</Option>*/}
      {/*<Option value="https://">https://</Option>*/}
    </Select>
  );

  const handleCompositionStart = () => {
    setIsComposing(true);
  };
  const navigate = useNavigate();

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };
  useEffect(() => {
    setOverlayOpen(!!keyword || isFilterOpen);
  }, [keyword, isFilterOpen, setOverlayOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const doSetFilter = () => {
    setSearchQuery(keyword, query);
    setKeyword('');
    setOverlayOpen(false);
    //setFilterOpen(false);
  };

  const doSetSearch = () => {
    if (!keyword) {
      setSearchQuery('', 'processed');
      setKeyword('');
      searchParams.delete('searchType');
      navigate({ search: searchParams.toString() });

      return;
    }

    handleSearch(keyword, type.value);
    setKeyword('');
    setOverlayOpen(false);
  };

  useEffect(() => {
    searchParams.set('searchType', 'name');
    navigate({ search: searchParams.toString() });
    setType({ label: '아이', value: 'name' });
  }, []);

  return (
    <>
      {/*<BasePopover*/}
      {/*  {...(isFilterOpen && { trigger: 'click', onOpenChange: setOverlayOpen })}*/}
      {/*  overlayClassName="search-overlay"*/}
      {/*  content={<SearchOverlay setFilter={setQuery} isFilterOpen={isFilterOpen} />}*/}
      {/*  open={isOverlayOpen}*/}
      {/*  getPopupContainer={() => ref.current}*/}
      {/*>*/}
      {/*<HeaderActionWrapper>*/}
      {/*  <InputSearch*/}
      {/*    width="100%"*/}
      {/*    value={keyword}*/}
      {/*    placeholder="검색"*/}
      {/*    onCompositionStart={handleCompositionStart}*/}
      {/*    onCompositionEnd={handleCompositionEnd}*/}
      {/*    onPressEnter={(e) => {*/}
      {/*      if (isComposing) {*/}
      {/*        return;*/}
      {/*      }*/}
      {/*      e.preventDefault();*/}

      {/*      doSetFilter();*/}
      {/*    }}*/}
      {/*    filter={*/}
      {/*      <Btn*/}
      {/*        size="small"*/}
      {/*        type={isFilterOpen ? 'ghost' : 'text'}*/}
      {/*        aria-label="Filter"*/}
      {/*        icon={<SearchOutlined />}*/}
      {/*        onClick={() => doSetFilter()}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    onChange={(event) => setKeyword(event.target.value)}*/}
      {/*    // enterButton={null}*/}
      {/*    addonAfter={null}*/}
      {/*  />*/}

      {/*  <div ref={ref} />*/}
      {/*</HeaderActionWrapper>*/}
      {/*</BasePopover>*/}

      <InputWrapper>
        <Input.Search
          className="tesrsrs"
          width="100%"
          value={keyword}
          placeholder="검색"
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          addonBefore={selectBefore}
          onPressEnter={(e) => {
            if (isComposing) {
              return;
            }
            e.preventDefault();

            doSetSearch();
          }}
          suffix={
            <Btn size="small" type="text" aria-label="Filter" icon={<SearchOutlined />} onClick={() => doSetSearch()} />
          }
          onChange={(event) => setKeyword(event.target.value)}
          // enterButton={null}
          addonAfter={null}
        />

        <div ref={ref} />
      </InputWrapper>
    </>
  );
};

export default React.memo(SearchDropdown);

// export const StyledInput = styled(InputSearch)`
//   .ant-input-group {
//     border-radius: 0px !important;
//   }
//   .ant-input-affix-wrapper:not(:last-child) {
//     border-top-left-radius: 0px !important;
//     border-bottom-left-radius: 0px !important;
//   }
// `;

// export const InputWrapper = styled.div`
//   //.ant-input-group-addon {
//   //  display: none;
//   //}
//   .ant-input-group-addon > button {
//     display: none !important;
//   }
// `;
