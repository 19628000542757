import styled from 'styled-components';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import checkImage from '@app/assets/icons/Done.svg';
export const TablesWrapper = styled.div`
  //margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
  .ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    padding-left: 23px !important;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
  }
`;
export const TabSearchWrapper = styled(BaseRow)`
  margin-bottom: 2rem;
  .ant-input-prefix {
    display: none !important;
  }

  .ant-table-pagination {
    align-items: center !important;
  }
`;

export const RemoveUserButton = styled.button`
  border-radius: 22px;
  padding: 8px 16px;
  border: 1px solid #c50000;
  background: #ffc5c5;
  color: #212529;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const SettingPasswordButton = styled.button`
  border-radius: 22px;
  padding: 8px 16px;
  border: 1px solid #35e5f2;
  background: #d6fef3;
  color: #212529;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const SelectButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 24px 8px 16px;
  background: #6e82fe;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.8;
  }
`;

export const PopOverWrapper = styled(BasePopover)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const AddrRoleButton = styled.button`
  //border: none;
  width: 34px;
  height: 34px;
  border-radius: 100px;
  border: 1px solid #6e82fe;
  display: inline-flex;
  background: rgba(110, 130, 254, 0.2);
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const RoleTag = styled.span`
  border-radius: 100px;
  width: 56px;
  height: 44px;
  border: 1px solid #6c757d;
  padding: 8px 16px;
  margin-right: 8px;
`;

export const ProfileArea = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .profile-img {
    width: 50px;
    height: 50px;
    background: #d9d9d9;
    display: inline-block;
    border-radius: 50%;
    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    .name {
      color: #212529;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;

  .ant-popover-inner {
    border-radius: 24px !important;
  }
  .ant-popover-inner-content {
    background: #ebefff !important;

    padding: 0 !important;
  }
`;

export const ApprovalPendingCount = styled.div`
  border-radius: 50%;
  background: #c50000;
  width: 32px;
  height: 34px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(10px, -13px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.md};
`;

export const TabButton = styled.button<{ isChecked: boolean; isHovered: boolean }>`
  position: relative;
  cursor: ${({ isHovered }) => (isHovered ? 'pointer' : 'default')};
  background: #fff;
  padding: 8px 16px;
  transform-origin: left center;
  border-radius: 100px;
  border: 1px solid #6c757d;
  transition: padding-left 0.3s ease;

  &:hover {
    background: ${({ isHovered, isChecked }) => (isHovered ? '#21252929' : isChecked ? '#d6fef3' : '#fff')};
    border: 1px solid ${({ isHovered, isChecked }) => (isHovered ? '#21252929' : isChecked ? '#35e5f2' : '#6c757d')};
  }
  /* 클릭시 체크 이미지 스타일 */
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url(${checkImage}) no-repeat;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  ${({ isChecked }) =>
    isChecked &&
    `
    padding-left: 40px; 
    border: 1px solid #35e5f2;
    background: #d6fef3;
    

    &:before {
      opacity: 1;
    }
  `}
`;
