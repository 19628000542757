import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { ymaDatas, ymDatas } from '@app/hocs/cache';
import {
  createYMGroupGQL,
  getYMGroupByCodeGQL,
  getYMGroupListGQL,
  getYMTargetGQL,
  removeYmTargetFromYMUserM,
  updateYMTargetGQL,
} from '@app/hocs/gql';
import dayjs from 'dayjs';
import { getMonth, regions } from '@app/constants/YMData';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import FloatLabel from '@app/components/common/FloatLabel/FloatLabel';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { Divider, Form, Modal, Radio, Select } from 'antd';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import * as S from './TargetInfoModal.styles';

import targetImg from '@app/assets/icons/target_default.svg';
import { notificationController } from '@app/controllers/notificationController';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

interface Props {
  open: boolean;
  closeModal: any;
  onOk: any;
  selectedTarget: any;
  refetch?: any;
  setSelectedTarget: any;
  isExamTable?: boolean;
  onRemove?: () => void;
}

export default function TargetInfoModal(props: Props) {
  const [error, setError] = useState('');
  const [removeYmTargetFromYmUserMutation] = useMutation(removeYmTargetFromYMUserM);

  const [setTargetProfileImg, setTargetProfileImage] = useState('');
  const [updateYMTarget, updateYMTargetRequest] = useMutation(updateYMTargetGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMTarget.success === true) {
        props.refetch();
        props.closeModal();
        notificationController.success({ message: '성공적으로 처리했습니다. ' });
      } else {
        // console.log(data.updateYMTarget.error);
      }
    },
  });

  const ymaData = useReactiveVar(ymaDatas);

  const [tgData, setTgData] = useState<any>(null);

  const [getTarget] = useLazyQuery(getYMTargetGQL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      if (data.getYMTarget.success) {
        const target = data.getYMTarget.target;
        const targetData =
          typeof data.getYMTarget.target.data === 'string'
            ? JSON.parse(data.getYMTarget.target.data)
            : data.getYMTarget.target.data;

        const targetGroupData =
          typeof target.group.data === 'string' ? JSON.parse(target.group.data) : target.group.data;

        setBMonth(getMonth(targetData.bdate));
        setTgName(target.title);
        setBDate(targetData.bdate);
        setEpWeek(targetData.epweek);
        setEpDay(targetData.epday);
        setSex(targetData.sex);
        setGroupRegion(targetGroupData.code);
        const groupDatas = await getGroups(targetGroupData.code);
        setGroupName(targetGroupData.name);
        // findGroup(targetGroupData.name);
        findGroupData(targetGroupData.name, groupDatas);
        const groupCode = findGroupId(targetGroupData.name);

        // console.log(findGroup(targetGroupData.name), groupCode, 'groupCode');
        if (groupCode && groupCode?.length) {
          setGroupCode(groupCode[0]?.id ?? '');
        }
        setTgData(targetData);
        setImg(targetData?.profileImg ?? '');
      }
    },
  });
  const [createYMGroup, createYMGroupRequest] = useMutation(createYMGroupGQL, {
    //here i am doing something after the mutaion finished
  });
  const getYMTargetData = async (id: string) => {
    await getTarget({
      variables: {
        getYmTargetId: id,
      },
    });
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [getYMGroupByCodeHandler, getYMGroupByCodeRequest] = useLazyQuery(getYMGroupByCodeGQL, {});
  const onSave = async () => {
    if (!tgName) {
      setError('아동이름을 입력해주세요.');
      return;
    }

    if (props.selectedTarget?.id) {
      const data = { ...tgData, sex, bdate: bDate, profileImg: img, epweek: epWeek, epday: epDay };

      const groupData = await getGroupByCode();

      if (groupData.data.getYMGroupByCode.group !== null) {
        updateYMTarget({
          variables: {
            id: props.selectedTarget?.id,
            input: {
              title: tgName,
              group: groupData.data.getYMGroupByCode.group.id,
              data: JSON.stringify(data),
            },
          },
        });
      } else {
        const group = await createGroup();
        if (group.data.createYMGroup.success) {
          const gid = group.data.createYMGroup.id;
          console.log(gid, 'gid');
          updateYMTarget({
            variables: {
              id: props.selectedTarget?.id,
              input: {
                title: tgName,
                group: gid,

                data: JSON.stringify(data),
              },
            },
          });
        }
      }
    }
  };
  const getGroupByCode = async () => {
    //d console.log("ymData.gcode:"+ymData.gcode)
    return await getYMGroupByCodeHandler({
      variables: { code: groupCode },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    });
  };

  const createGroup = async () => {
    const today = new Date();
    const owner = ymaData.eqid;

    return await createYMGroup({
      variables: {
        input: {
          code: groupList[0]?.id,
          title: regions[groupRegion] + '-' + groupName,
          category: 'ym_exam',
          owner: owner,
          reservedAt: today,
          data: JSON.stringify({
            region: regions[groupRegion],
            code: groupRegion,
            name: groupName,
            date: 1,
          }),
        },
      },
    });
  };

  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const ymData = useReactiveVar(ymDatas);

  const [groupType, setGroupType] = useState('ym_exam');
  const [tgName, setTgName] = useState('');
  const [groupRegion, setGroupRegion] = useState('T');

  const [groupData, setGroupData] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [groupCode, setGroupCode] = useState('');
  const [groupName, setGroupName] = useState('');
  const [sex, setSex] = useState(1);
  const [epWeek, setEpWeek] = useState(40);
  const [epDay, setEpDay] = useState(0);
  const [bDate, setBDate] = useState(0);
  const [bMonth, setBMonth] = useState(0);
  const [rDate, setRDate] = useState(0);
  const [update, setUpdate] = useState(0);
  const [privacyAgreed, setPrivacyAgreed] = useState(1);
  const [img, setImg] = useState('');

  const [getYMGroupListHandler, getYMGroupListRequest] = useLazyQuery(getYMGroupListGQL, { onCompleted: (data) => {} });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const getGroups = async (idx: any) => {
    /*
        const apiServer = 'https://fg.liflo.io'; //
        //if( isLocal() ) apiServer = 'http://localhost:3006'; // 'https://m5.liflo.io'

        const url = apiServer + '/data/'+'seouli_'+idx+'.json';

        const response = await fetch(url);
        const data = await response.json();
        setGroupData(data['region']);
        */
    return getYMGroupListHandler({
      variables: { code: idx },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    })
      .then((result) => {
        const {
          data: {
            getYMGroupList: { success, id, data, idx },
          },
        } = result;
        if (data === null) return;
        setGroupData(JSON.parse(data));
        return JSON.parse(data);
      })
      .catch((err) => {});
  };
  const findGroupId = (str: any) => {
    if (groupData !== null) {
      return groupData.filter((group) => group.name.includes(str));
    }
  };

  const findGroupData = (str: any, groupLists: any[]) => {
    if (groupLists !== null) {
      setGroupList(groupLists.filter((group) => group.name.includes(str)));
    }
  };

  const findGroup = (str: any) => {
    if (groupData !== null) {
      setGroupList(groupData.filter((group) => group.name.includes(str)));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const uploadUrl = await fetch('https://image.mbisq.com').then((resp) => resp.json());

        fetch(`${uploadUrl.result.uploadURL}`, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            const findAvatar = data.result.variants.find((item: string) => item.includes('avatar'));
            setImg(findAvatar);
            notificationController.success({ message: '업로드에 성공하였습니다.' });
          })
          .catch((error) => {
            console.error('업로드 실패:', error);
          });
      } catch (err) {
        notificationController.error({ message: '서버에러가 발생했습니다.' });
      }
    }
  };

  // useEffect(() => {
  //   //if( update !== ymData.tg.update){
  //   setTgName(ymData.tg.data.name);
  //   setBDate(ymData.tg.data.bdate);
  //   setRDate(parseInt(ymData.tg.reservedAt));
  //
  //   setGroupList([]);
  //   setGroupCode(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code);
  //   setGroupRegion(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code.substring(0, 1));
  //
  //   const day = dayjs(new Date().getTime()).format('YYMMDD');
  //   ymData.tg.data.uid = 'FT' + day + '##';
  //
  //   if (ymData.tg.group === undefined) setGroupName('');
  //   else {
  //     const gData = ymData.tg.group.data;
  //     setGroupName(gData.name);
  //   }
  //   getGroups(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code.substring(0, 1));
  //   findGroup('*');
  //   setSex(ymData.tg.data.sex);
  //   if (ymData.tg.data.bmonth === 0) setBMonth(getMonth(ymData.tg.data.bdate));
  //   //  setUpdate(ymData.tg.update);
  //   //}
  // }, [ymData.update]); //reservedAt]);

  const onClickGroupItem = (groupCode: any, groupName: any) => {
    setGroupCode(groupCode);
    setGroupName(groupName);
  };

  const onRemoveTarget = async () => {
    if (props.isExamTable && props.onRemove) {
      props.onRemove();
      return;
    }

    if (ymData?.eqid && props?.selectedTarget) {
      try {
        const { data } = await removeYmTargetFromYmUserMutation({
          variables: { removeYmTargetFromYmUserId: ymData?.eqid, targetid: props.selectedTarget?.id },
        });

        // Mutation 결과를 처리합니다.
        const { success, id } = data.removeYMTargetFromYMUser;

        if (success) {
          console.log(`대상(ID: ${id})을 사용자에서 성공적으로 제거했습니다.`);
          props.setSelectedTarget(null);
          props.refetch();
          props.closeModal();
          notificationController.success({ message: '성공적으로 처리했습니다.' });
        } else {
          console.error(`대상(ID: ${id})을 사용자에서 제거하는 데 실패했습니다.`);
          notificationController.error({ message: '처리중 에러가 발생했습니다.' });
        }
      } catch (error) {
        console.error('Mutation 실행 중 오류 발생:', error);
      }
    }
  };

  const onFinish = async (values = {}) => {
    console.log('here');
  };

  const doOk = () => {
    console.log('ymData:' + ymData.tg.data.uid.substring(8, 10));
    if (ymData.tg.data.uid.substring(8, 10) === '##') {
      ymDatas({
        ...ymData,
        tg: {
          ...ymData.tg,
          data: {
            ...ymData.tg.data,
            bdate: bDate,
            bmonth: bMonth,
            name: tgName,
            sex: sex,
            epWeek: epWeek,
            epDay: epDay,
            privacyAgreed,
          },
          reservedAt: rDate,
        },
        gregion: groupRegion,
        gcode: groupCode,
        gname: groupName,
      }); // update cache
      props.onOk(true);
    } else props.onOk(false);
  };

  const menus: any = [];

  Object.keys(regions).forEach(async function (key) {
    menus.push(key);
  });

  useEffect(() => {
    if (props.selectedTarget?.id && props.open) {
      getYMTargetData(props.selectedTarget?.id);
    }
  }, [props.open, props.selectedTarget?.id]);

  return (
    <S.ModalWrapper
      width="1000px"
      open={props.open}
      onCancel={props.closeModal}
      title="우리아이 정보"
      footer={null}
      keyboard={false}
    >
      <Form {...formItemLayout} onFinish={onFinish}>
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }} style={{ width: '100%' }}>
          <BaseCol span={12}>
            <div className="profile-img-area">
              <figure className="profile">
                {img ? (
                  <img className="profile-img" src={img} alt="target image" />
                ) : (
                  <img className="empty" src={targetImg} alt="target image" />
                )}
              </figure>
              <span onClick={handleImageClick}>사진 수정</span>
            </div>

            <FloatLabel
              label="아동이름"
              desc={error ? '' : '아동의 이름을 입력해주세요.'}
              name="select"
              required
              className="name-form"
            >
              <BaseInput
                className={error ? 'form-error' : ''}
                required
                autoComplete="off"
                value={tgName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTgName(event.target.value);
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, name:event.target.value}}}); // update cache
                }}
              />
            </FloatLabel>
            {error && <div className="error">{error}</div>}
            <FloatLabel
              label="생년월일"
              desc="아동의 생년월일을 선택해주세요.(만나이는 자동 계산 됩니다.)"
              name="select"
              required
            >
              <BaseDatePicker
                format="YYYY년 MM월 DD일"
                value={dayjs(bDate)}
                allowClear={false}
                onChange={(date: any, dateString) => {
                  const bdate = date.toDate().getTime();
                  console.log(date);
                  setBDate(bdate);
                  setBMonth(getMonth(bdate));
                  console.log(bdate);
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, bdate:bdate, bmonth:getMonth(bdate)}}}); // update cache
                }}
              />
              <span>
                {' '}
                만 {(bMonth / 12) | 0}세 {bMonth % 12}개월
              </span>
            </FloatLabel>

            <FloatLabel label="조산여부" desc="조산의 경우 기간을 입력해주세요.3" name="select" required>
              <div style={{ marginTop: '10px', padding: '7px', border: '1px solid #999a', borderRadius: 7 }}>
                임신{' '}
                <Select
                  value={String(epWeek)}
                  size="small"
                  bordered={false}
                  style={{ width: 65, marginLeft: 15, backgroundColor: 'white' }}
                  onChange={(value: any) => {
                    setEpWeek(parseInt(value));
                    //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, epweek:parseInt(e.target.value)}}}); // update cache
                  }}
                  options={[
                    { value: '20', label: '20' },
                    { value: '21', label: '21' },
                    { value: '22', label: '22' },
                    { value: '23', label: '23' },
                    { value: '24', label: '24' },
                    { value: '25', label: '25' },
                    { value: '26', label: '26' },
                    { value: '27', label: '27' },
                    { value: '28', label: '28' },
                    { value: '29', label: '29' },
                    { value: '30', label: '30' },
                    { value: '31', label: '31' },
                    { value: '32', label: '32' },
                    { value: '33', label: '33' },
                    { value: '34', label: '34' },
                    { value: '35', label: '35' },
                    { value: '36', label: '36' },
                    { value: '37', label: '37' },
                    { value: '38', label: '38' },
                    { value: '39', label: '39' },
                    { value: '40', label: '40' },
                  ]}
                />
                주
                <Select
                  value={String(epDay)}
                  size="small"
                  bordered={false}
                  style={{ width: 60, marginLeft: 15 }}
                  onChange={(value: any) => {
                    setEpDay(parseInt(value));
                    //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, epweek:parseInt(e.target.value)}}}); // update cache
                  }}
                  options={[
                    { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                  ]}
                />
                일
              </div>
            </FloatLabel>
          </BaseCol>
          <BaseCol span={1}>
            <Divider type="vertical" style={{ height: '450px' }} />
          </BaseCol>
          <BaseCol span={11}>
            <div style={{ marginBottom: '36px' }}>
              <FloatLabel label="성별" desc="아동의 성별을 선택해주세요." name="select" required width="10rem">
                <div style={{ marginTop: '10px', padding: '7px', border: '1px solid #999a', borderRadius: 7 }}>
                  <Radio.Group
                    value={String(sex)}
                    onChange={(e: any) => {
                      setSex(parseInt(e.target.value));
                      //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, sex:parseInt(e.target.value)}}}); // update cache
                    }}
                  >
                    <Radio value="1">여</Radio>
                    <Radio value="2">남</Radio>
                  </Radio.Group>
                </div>
              </FloatLabel>
            </div>

            <div style={{ marginBottom: '56px' }}>
              <FloatLabel label="지역 - 구이름" desc="가입시 입력했던 지역입니다." name="select">
                <div
                  style={{
                    marginTop: 10,
                    padding: '7px',
                    border: '0px solid #999a',
                    borderRadius: 7,
                    width: '100%',
                    paddingLeft: '0px',
                  }}
                >
                  <BaseSelect
                    size="small"
                    id="region"
                    value={groupRegion}
                    onChange={(value: any) => {
                      setGroupRegion(value);
                      setGroupName('');
                      findGroup('*');
                      getGroups(value);

                      console.log(value, 'value');
                    }}
                    style={{ width: '100%' }}
                  >
                    {menus.map((key: any, idx: number) => (
                      <Option key={idx} value={key}>
                        {regions[key]}
                      </Option>
                    ))}
                  </BaseSelect>
                </div>
              </FloatLabel>
            </div>
            <FloatLabel label="소속기관" desc="아동이 소속한 기관을 검색해주세요." name="select">
              <div
                style={{
                  marginTop: -20,
                  padding: '7px',
                  border: '0px solid #999a',
                  borderRadius: 7,
                  width: '100%',
                  paddingLeft: '0px',
                }}
              >
                <BaseInput
                  required
                  id="groupName"
                  autoComplete="off"
                  value={groupName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGroupName(event.target.value);
                    findGroup(event.target.value);
                  }}
                  size="small"
                />
              </div>
            </FloatLabel>
            <BaseList
              size="small"
              style={{
                marginTop: -5,
                borderRadius: 1,
                border: '1px solid',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 250,
              }}
            >
              {groupList.map((group, idx) => (
                <BaseList.Item
                  key={idx}
                  onClick={(event: any) => {
                    onClickGroupItem(group.id, group.name);
                    setGroupCode(group.id);
                    setGroupName(group.name);

                    //ymDatas({...ymData, gregion: groupRegion, gcode:group.id, gname:group.name }); // update cache
                  }}
                >
                  {group.name}
                </BaseList.Item>
              ))}
            </BaseList>
          </BaseCol>
        </BaseRow>

        <BaseRow className="footer" style={{ marginTop: '20px' }}>
          <div className="left-area">
            해당 아이 프로필 삭제가 필요하신가요?{' '}
            <span className="delete" onClick={onRemoveTarget} style={{ cursor: 'pointer' }}>
              삭제하기
            </span>
          </div>
          <div className="button-group">
            <button className="default-button" onClick={props.closeModal}>
              취소하기
            </button>
            <button className="active-button" onClick={onSave}>
              저장하기
            </button>
          </div>
        </BaseRow>
      </Form>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
        ref={fileInputRef}
      />
    </S.ModalWrapper>
  );
}
