import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  max-width: 550px !important;
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-body {
    padding-bottom: 10px !important;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  padding: 20px;

  text-align: center;
  .footer {
    margin-bottom: 14px;
    text-align: right;
    .close-btn {
      border-radius: 8px;
      padding: 8px 16px;
      background: #e9ecef;
      color: #495057;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .history-lists {
    border-radius: 22px;
    border: 1px solid #adb5bd;
    padding: 16px;
    margin-bottom: 24px;
  }

  .target-info {
    border-radius: 100px;
    border: 1px solid #6c757d;
    padding: 8px 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #495057;
  }
  .mail-history {
    > li {
      display: flex;
      justify-content: space-between;
      gap: 0 8px;
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      font-family: 'NotoSansKR', sans-serif;

      .time {
        color: #343a40;

        //font-family: Inter;
      }

      .email {
        color: #6c757d;
      }
    }
  }
`;
