import React, { useMemo, useState } from 'react';
import enUS from 'antd/lib/calendar/locale/en_US';
import koKR from 'antd/es/calendar/locale/ko_KR';
import { CalendarSwitch } from '@app/components/common/CalendarSwitch/CalendarSwitch';
import { useLanguage } from '@app/hooks/useLanguage';
import { ScheduleEvent } from '@app/api/schedule.api';
import { AppDate, Dates } from '@app/constants/Dates';
import * as S from './ExamCalendar.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // 한국 로케일 가져오기
import type { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { Calendar, Col, Radio, Row, Select, Typography } from 'antd';
import type { CalendarProps } from 'antd';
dayjs.extend(dayLocaleData);

interface ExamCalendarProps {
  date: AppDate;
  setDate: (state: AppDate) => void;
  onIncrease: () => void;
  onDecrease: () => void;
  onToday: () => void;
  setDateClicked: (state: boolean) => void;
  calendar: ScheduleEvent[];
}

export const ExamCalendar: React.FC<ExamCalendarProps> = ({
  calendar,
  date,
  setDate,
  onDecrease,
  onIncrease,
  setDateClicked,
  onToday,
}) => {
  const { language } = useLanguage();
  const locale = useMemo(() => (language === 'ko' ? koKR : enUS), [language]);

  const [date2, setDate2] = useState(new Date());
  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  const onChange = (newDate: Date | Date[]) => {
    if (Array.isArray(newDate)) {
      // 여러 날짜를 선택할 때 처리
      // 예: newDate.map(date => console.log(date));
    } else {
      setDate2(newDate);
    }
  };

  const handleSelect = (value: AppDate) => {
    console.log(value, 'value');

    setDate(value);
    setDateClicked(true);
  };

  const dateFormatted = Dates.format(date, 'MMMM YYYY');

  return (
    <>
      <BaseRow gutter={[20, 20]}>
        <BaseCol span={24}>
          <CalendarSwitch
            dateFormatted={dateFormatted}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            onToday={onToday}
          />
          {/*<h1>1231234dasdasd</h1>*/}
        </BaseCol>

        <BaseCol span={24}>
          <S.Calendar
            locale={locale}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];

              let current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current = current.month(i);
                months.push(current.format('M월')); // 월 이름을 한글로 표시
              }

              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>,
                );
              }

              const year = value.year();
              const month = value.month();
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>,
                );
              }
              return (
                <div style={{ padding: 8 }}>
                  {/*<Typography.Title level={4}>Custom header</Typography.Title>*/}
                  <Row gutter={8}>
                    {/*<Col>*/}
                    {/*  <Radio.Group size="small" onChange={(e) => onTypeChange(e.target.value)} value={type}>*/}
                    {/*    <Radio.Button value="month">Month</Radio.Button>*/}
                    {/*    <Radio.Button value="year">Year</Radio.Button>*/}
                    {/*  </Radio.Group>*/}
                    {/*</Col>*/}
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        value={year}
                        onChange={(newYear) => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                        }}
                      >
                        {options}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={month}
                        onChange={(newMonth) => {
                          const now = value.clone().month(newMonth);
                          onChange(now);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                  </Row>
                </div>
              );
            }}
            dateCellRender={(value) => {
              const today = Dates.getToday();

              return calendar.map((event) => {
                const calendarDate = Dates.getDate(event.date);

                if (
                  calendarDate.isSame(value, 'date') &&
                  calendarDate.isSame(value, 'month') &&
                  calendarDate.isSame(value, 'year')
                ) {
                  const isPast = today.isAfter(calendarDate);

                  return (
                    <S.Event key={event.date} $isPast={isPast}>
                      {calendarDate.format('DD')}
                    </S.Event>
                  );
                }
              });
            }}
            value={date}
            fullscreen={false}
            onSelect={handleSelect}
          />
        </BaseCol>
      </BaseRow>
    </>
  );
};
