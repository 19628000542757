import styled from 'styled-components';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';

export const ContentWrapper = styled.div`
  position: relative;

  gap: 10px;
  flex-wrap: wrap;
  width: 300px;
  //border-radius: 24px;
  background: #ebefff;
  //max-height: 150px;
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  overflow: auto;
  max-height: 130px;
  width: 300px;
  margin-bottom: 30px;
`;

export const RolePopOver = styled(BasePopover)`
  .ant-popover-inner-content {
    background: red !important;
  }
`;

export const Footer = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  bottom: 0;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  img {
    cursor: pointer;
  }
`;
