import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

//import { ScheduleCard } from '@app/components/examinee-dashboard/scheduleCard/ScheduleCard';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { createYMTaskGQL, getAllYMExecutersGQL, updateYMGroupGQL } from '@app/hocs/gql';
//import { Dates } from '@app/constants/Dates';
//import { getExamMonth, getMonth } from '@app/constants/YMData';
import { ymDatas, ymaDatas } from '@app/hocs/cache';
import { GroupScheduleCard } from '@app/components/examinee-dashboard/scheduleCard/GroupScheduleCard';
import styled from 'styled-components';
//import { Button } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const GroupScheduleForm = (props: any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { t } = useTranslation();

  const ymData = useReactiveVar(ymDatas);

  //const [dateFormatted, setDateFormated] = useState('');
  const [createYMTask, createYMTaskRequest] = useMutation(createYMTaskGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.createYMTask.success === true) {
        //setMessage('');
      } else {
        notificationController.error({ message: data.createYMTask.error });
      }
    },
  });
  useEffect(() => {
    console.log('******************* GroupScheduleForm useEffect:' + ymData.update + ':' + ymData.modal);
  }, [ymData.update]);

  useEffect(() => {
    //setDateFormated(Dates.format(parseInt(ymData.tg.reservedAt), 'YYYY년 M월 D일 - H시 m분'));
    setCompleted(ymData?.tg?.group?.status === 4);
  }, [ymData.tg.reservedAt]);

  const [updateYMGroup, updateYMGroupRequest] = useMutation(updateYMGroupGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMGroup.success === true) {
      } else {
        console.log(data.updateYMGroup.error);
      }
    },
  });

  const updateGroupStatus = async (status: number) => {
    updateYMGroup({
      variables: {
        id: ymData.tg.group.id,
        input: {
          status: status,
        },
      },
    })
      .then(async (result) => {
        const {
          data: {
            updateYMGroup: { success, data, id },
          },
        } = result;
        //console.log('updateYMGroup - data:'+data)

        console.log(status, ymData?.tk);

        // if (status === 4 && ymData?.tk?.length) {
        //   for (const v of ymData?.tk) {
        //     console.log(v, 'v');
        //     if (v?.category && !v.category.includes('assess')) {
        //       const data = typeof v.data === 'string' ? JSON.parse(v.data) : v.data;
        //       const category = v.category === 'e_0' ? 'assess_1' : 'assess_2';
        //       const owner = ymData?.tg?.executers?.length ? ymData?.tg?.executers[0]?.id : '';
        //       const title = v?.title;
        //
        //       await createAssessmentTask({
        //         video: data.video,
        //         thumb: data.thumb,
        //         comment: data.comment,
        //         vwidth: data.vwidth,
        //         vr: data.vr,
        //         category,
        //         title,
        //         owner,
        //       });
        //     }
        //   }
        // }

        notificationController.info({
          message: success ? '변경이 완료 되었습니다.' : '변경이 되지 않았습니다. 관리자에게 문의하세요.',
        });
        setCompleted(status === 4);
        //ymDatas({...ymData, tg:{...ymData.tg, group:{...ymData.tg.group, status:status}}});
        //setStatus(99);
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        console.log(err);
      });
  };

  const updateGroupReservedAt = (date: any) => {
    updateYMGroup({
      variables: {
        id: ymData.tg.group.id,
        input: {
          reservedAt: date,
          code: ymData.tg.group.code,
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMGroup: { success, data, id },
          },
        } = result;
        //console.log('updateYMGroup - data:'+data)
        notificationController.info({
          message: success ? '변경이 완료 되었습니다.' : '변경이 되지 않았습니다. 관리자에게 문의하세요.',
        });
        //ymDatas({...ymData, tg:{...ymData.tg, group:{...ymData.tg.group, reservedAt:date}}});
        //setStatus(99);
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        console.log(err);
      });
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(true);
      console.log(values);
      //props.onOk();
    }, 100);
  };

  console.log(ymData?.tk, ymData.tg);


  console.log(ymData.tk, 'tk');

  return (
    <BaseButtonsForm
      {...formItemLayout}
      layout="inline"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <BaseButtonsForm.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <BaseSpace align="end">
            <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={props.onOk}>
              닫기
            </BaseButton>
          </BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={[10, 10]}>
        <BaseCol span={24}>
          <RowStyled gutter={[10, 10]} wrap={true}>
            <BaseCol span={24}>
              <RowStyled gutter={[10, 10]} justify={'space-between'} wrap={false}>
                <BaseButton round type="default">
                  {ymData?.tg?.group?.data?.name}
                </BaseButton>
                <BaseSpace align="end">
                  <BaseButton
                    round
                    onClick={() => {
                      updateGroupStatus(completed ? 0 : 4);
                    }}
                    type={completed ? 'ghost' : 'primary'}
                  >
                    {completed ? '기관 복구' : '기관 평가 완료'}
                  </BaseButton>
                  <BaseButton
                    round
                    onClick={() => {
                      updateGroupStatus(5);
                    }}
                    type={'text'}
                  >
                    기관 삭제
                  </BaseButton>
                </BaseSpace>
              </RowStyled>
            </BaseCol>

            <BaseCol span={24}>해당 기관의 예정된 전체 검사의 날짜를 변경하실 수 있습니다.</BaseCol>
            <BaseCol span={24}>
              <GroupScheduleCard setReserved={(date: any) => updateGroupReservedAt(date)} />
            </BaseCol>
          </RowStyled>
        </BaseCol>

        <BaseCol span={24}></BaseCol>
      </BaseRow>
    </BaseButtonsForm>
  );
};

const RowStyled = styled(BaseRow)`
  min-height: 0rem;
`;
