import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import CallbackPage from '@app/pages/callback/GoogleCallbackPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import ScheduleDashboardPage from '@app/pages/DashboardPages/ScheduleDashboardPage';
import AssessmentDashboardPage from '@app/pages/DashboardPages/AssessmentDashboardPage';
import ExamineeDashboardPage from '@app/pages/DashboardPages/ExamineeDashboardPage';
import DataDashboardPage from '@app/pages/DashboardPages/DashboardPage';
import SettingDashboardPage from '@app/pages/DashboardPages/DashboardPage';
import MemberDashboardPage from '@app/pages/DashboardPages/MemberDashboardPage';
import AccessAllowPage from '@app/pages/AccessAllowPage';
import GoogleCallbackPage from '@app/pages/callback/GoogleCallbackPage';
import KakaoCallbackPage from '@app/pages/callback/KakaoCallbackPage';
import NaverCallbackPage from '@app/pages/callback/NaverCallbackPage';
import DetailAssessmentPage from '@app/pages/DetailAssessmentPage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const S2023TablesPage = React.lazy(() => import('@app/pages/S2023TablesPage'));
const SettingTablesPage = React.lazy(() => import('@app/pages/SettingTablesPage'));
const EmailTablesPage = React.lazy(() => import('@app/pages/EmailTablesPage'));
const VideoTablesPage = React.lazy(() => import('@app/pages/VideoTablesPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ComingSoonPage = React.lazy(() => import('@app/pages/ComingSoonPage'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));
const Indexing = React.lazy(() => import('./Indexing'));
const GradingListPage = React.lazy(() => import('@app/pages/AssessmentPage'));
const DetailGradingPage = React.lazy(() => import('@app/pages/DetailAssessmentPage'));
const TargetAssessmentPage = React.lazy(() => import('@app/pages/TargetAssessmentPage'));
const OverviewPage = React.lazy(() => import('@app/pages/OverviewPage'));
const TargetResultListsPage = React.lazy(() => import('@app/pages/TargetResultLists'));
import TargetResultGraphPage from '@app/pages/TargetResultGraphPage';
import TargetResultViewPage from '@app/pages/TargetResultView';
export const NFT_DASHBOARD_PATH = 'nft-dashboard';
export const MEDICAL_DASHBOARD_PATH = 'medical-dashboard'; // medical-dashboard
export const EXAMINEE_DASHBOARD_PATH = 'examinee-dashboard'; // medical-dashboard
export const DATA_DASHBOARD_PATH = 'data-dashboard'; // medical-dashboard
export const SETTING_DASHBOARD_PATH = 'setting-dashboard'; // medical-dashboard
export const EMAIL_DASHBOARD_PATH = 'email-tables';
export const VIDEO_DASHBOARD_PATH = 'video-tables';

export const SCHEDULE_DASHBOARD_PATH = 'schedule-dashboard'; // medical-dashboard
export const ASSESSMENT_DASHBOARD_PATH = 'assessment-dashboard'; // medical-dashboard
export const DETAIL_ASSESSMENT_DASHBOARD_PATH = 'assessment-dashboard/target-history/:targetId/:taskId'; // medical-dashboard
export const TARGET_ASSESSMENT_PATH = 'assessment-dashboard/assessment/target/:targetId/:taskId';
export const MEMBER_DASHBOARD_PATH = 'member-dashboard'; // medical-dashboard
export const OVERVIEW_PATH = 'overview';
export const TARGET_RESULT_LIST_PATH = 'target-result-lists';
export const TARGET_RESULT_GRAPH_PATH = 'target-result-graph';
export const TARGET_RESULT_VIEW_PATH = 'target-result-view';
export const PARENT_SIGN_UP_PATH = 'target-result-view';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const ScheduleDashboard = withLoading(ScheduleDashboardPage);
const AssessmentDashboard = withLoading(AssessmentDashboardPage);
const ExamineeDashboard = withLoading(ExamineeDashboardPage);
const DataDashboard = withLoading(DataDashboardPage);
const SettingDashboard = withLoading(SettingDashboardPage);
const MemberDashboard = withLoading(MemberDashboardPage);
const TargetAssessment = withLoading(TargetAssessmentPage);
const Overview = withLoading(OverviewPage);
const TargetResultLists = withLoading(TargetResultListsPage);
const TargetResultGraph = withLoading(TargetResultGraphPage);
const TargetResultView = withLoading(TargetResultViewPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const S2023Tables = withLoading(S2023TablesPage);
const SettingTables = withLoading(SettingTablesPage);
const EmailTables = withLoading(EmailTablesPage);
const VideoTables = withLoading(VideoTablesPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const ComingSoon = withLoading(ComingSoonPage);
const AccessAllow = withLoading(AccessAllowPage);
const GradingList = withLoading(GradingListPage);
const DetailAssessment = withLoading(DetailAssessmentPage);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const IndexingFallback = withLoading(Indexing);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/member'} element={protectedLayout}>
          <Route path={SCHEDULE_DASHBOARD_PATH} element={<ScheduleDashboard />} />
          <Route path={EXAMINEE_DASHBOARD_PATH} element={<ExamineeDashboard />} />
          <Route path={DATA_DASHBOARD_PATH} element={<DataDashboard />} />
          <Route path={ASSESSMENT_DASHBOARD_PATH} element={<AssessmentDashboard />} />
          <Route path={SETTING_DASHBOARD_PATH} element={<SettingDashboard />} />
          <Route path={MEMBER_DASHBOARD_PATH} element={<MemberDashboard />} />
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path={NFT_DASHBOARD_PATH} element={<NftDashboard />} />
        </Route>
        <Route path={'/'} element={protectedLayout}>
          <Route path={ASSESSMENT_DASHBOARD_PATH} element={<GradingList />} />
          <Route path={DETAIL_ASSESSMENT_DASHBOARD_PATH} element={<DetailAssessment />} />
          <Route path={TARGET_ASSESSMENT_PATH} element={<TargetAssessment />} />
          <Route path={OVERVIEW_PATH} element={<Overview />} />
          <Route path={TARGET_RESULT_LIST_PATH} element={<TargetResultLists />} />
          <Route path={TARGET_RESULT_GRAPH_PATH} element={<TargetResultGraph />} />
          <Route path={TARGET_RESULT_VIEW_PATH} element={<TargetResultView />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          <Route path="setting-tables" element={<SettingTables />} />
          <Route path="S2023-tables" element={<S2023Tables />} />
          <Route path="email-tables" element={<EmailTables />} />
          <Route path="video-tables" element={<VideoTables />} />
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="ComingSoon" element={<ComingSoon />} />
          <Route path="accessallow" element={<AccessAllow />} />
          <Route path="/parent/accessallow" element={<AccessAllow />} />

          {/*<Route path="profile" element={<ProfileLayout />}>*/}
          <Route path="/profile/personal-info" element={<PersonalInfo />} />
          <Route path="/profile/personal-info/parent" element={<PersonalInfo />} />

          <Route path="security-settings" element={<SecuritySettings />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="payments" element={<Payments />} />
          {/*</Route>*/}
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="parent-login" element={<LoginPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="parent-sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/" element={<AuthLayoutFallback />} />
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/indexing" element={<IndexingFallback />} />

        <Route path="/google/callback" element={<GoogleCallbackPage />} />
        <Route path="/kakao/callback" element={<KakaoCallbackPage />} />
        <Route path="/naver/callback" element={<NaverCallbackPage />} />
      </Routes>
    </BrowserRouter>
  );
};
