import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Divider, Input, Modal, Radio, Select } from 'antd';
import FloatLabel from '@app/components/common/FloatLabel/FloatLabel';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { ymDatas } from '@app/hocs/cache';
import dayjs from 'dayjs';
import { getMonth, isLocal, regions } from '@app/constants/YMData';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { getYMGroupListGQL, updateYMGroupListGQL } from '@app/hocs/gql';
import styled from 'styled-components';
import cancelImg from '@app/assets/icons/popover_cancel.svg';
import checkImg from '@app/assets/icons/file_upload_check.svg';
import alertImg from '@app/assets/icons/alert_icon.svg';
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const GroupAddForm = (props: any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [groupType, setGroupType] = useState('ym_exam');
  const [groupRegion, setGroupRegion] = useState('T');
  const [groupData, setGroupData] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [groupCode, setGroupCode] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupAddName, setGroupAddName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addGroup, setAddGroup] = useState(false);
  const [update, setUpdate] = useState(0);
  const [isGroupRegionChecked, setIsGroupRegionChecked] = useState(false);
  const groupListIdx = useRef(-1);

  const [isExistGroupCheck, setIsExistGroupCheck] = useState(false);

  const onDeleteGroupName = () => {
    setIsGroupRegionChecked(false);
    setGroupName('');
    setAddGroup(false);
  };

  const [updateYMGroupList, updateYMGroupListRequest] = useMutation(updateYMGroupListGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMGroupList.success === true) {
      } else {
        console.log(data.updateYMGroupList.error);
      }
    },
  });

  const updateGroupList = () => {
    updateYMGroupList({
      variables: {
        code: groupRegion,
        input: {
          data: JSON.stringify(groupData),
          name: regions[groupRegion],
          total: groupData.length,
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMGroupList: { success, data, id },
          },
        } = result;
        //d console.log('updateYMTarget - data:'+data)
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };

  const [getYMGroupListHandler, getYMGroupListRequest] = useLazyQuery(getYMGroupListGQL, { onCompleted: (data) => {} });

  const isExistGroupName = (str: string) => {
    const findGroup = groupData.find((group) => group.name === str);
    return !!findGroup;
  };

  const getGroups = async (idx: any) => {
    /*
    const apiServer = 'https://fg.liflo.io'; // 
    //if( isLocal() ) apiServer = 'http://localhost:3006'; // 'https://m5.liflo.io'
  
    const url = apiServer + '/data/'+'seouli_'+idx+'.json';
    
    const response = await fetch(url);
    const data = await response.json();

    setGroupData(data['region']);
    */
    getYMGroupListHandler({
      variables: { code: idx },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    })
      .then((result) => {
        const {
          data: {
            getYMGroupList: { success, id, data, idx },
          },
        } = result;
        if (data === null) return;
        setGroupData(JSON.parse(data));
        groupListIdx.current = idx;
      })
      .catch((err) => {});
  };

  const findGroup = (str: any) => {
    if (groupData !== null) {
      const findGList = groupData.filter((group) => group.name === str);
      if (findGList.length === 0) {
        if (str.length > 4) {
          setAddGroup(true);
          setGroupAddName(str);
          return;
        }
      } else {
        setGroupList(findGList);
      }
    }
    setAddGroup(false);
    setGroupAddName('');
  };

  const onFinish = async (values = {}) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(true);
      //notificationController.success({ message: t('common.success') });
      console.log(values);
    }, 100);
  };

  const doOk = () => {
    props.onOk(false);
  };

  const menus: any = [];

  Object.keys(regions).forEach(async function (key) {
    menus.push(key);
  });

  const doGroupUpload = () => {
    updateGroupList();
  };

  const addToGroupList = (data: any) => {
    updateYMGroupList({
      variables: {
        code: groupRegion,
        input: {
          data: JSON.stringify(data),
          idx: -1,
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMGroupList: { success, data, id },
          },
        } = result;
        if (data === null) return;
        setGroupData(JSON.parse(data));
        notificationController.success({ message: '기관 추가가 완료 되었습니다.' });
        //d console.log('updateYMTarget - data:'+data)
      })
      .catch((err) => {
        //console.log('login error',createUserError)
        //d console.log(err)
      });
  };

  const getZeroPad = (num: any, digit: any) => {
    let zero = '';
    for (let i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(digit * -1);
  };

  const doAddGroup = () => {
    if (isExistGroupName(groupName)) {
      notificationController.warning({ message: '이미 등록된 기관입니다.' });

      return;
    }

    if (!isGroupRegionChecked || !addGroup) {
      notificationController.warning({ message: '기관정보를 정확하게 입력해주세요.' });
      return;
    }

    const findData = groupData.find((item) => item.name === groupName);

    getYMGroupListHandler({
      variables: { code: groupRegion },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    })
      .then((result) => {
        const {
          data: {
            getYMGroupList: { success, id, data, idx },
          },
        } = result;
        if (data === undefined) return;
        const jData = JSON.parse(data);
        groupListIdx.current = idx;
        const code = groupRegion + getZeroPad(idx + 1, 4);
        const newGroup = {
          id: code,
          region: regions[groupRegion],
          name: groupName,
          phone: phoneNumber,
        };
        jData.push(newGroup);
        addToGroupList(jData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (groupRegion) {
      getGroups(groupRegion);
    }
  }, [props.gaddopen]);

  return (
    <Modal
      title="기관 추가"
      maskStyle={{ background: 'rgba(0, 0, 0, 0.15)' }}
      open={props.gaddopen}
      onOk={props.onOk}
      width={'40rem'}
      footer={null}
      onCancel={() => {
        setIsGroupRegionChecked(false);
        setAddGroup(false);
        setGroupName('');
        setPhoneNumber('');
        setGroupRegion('T');
        setGroupData([]);
        setGroupList([]);
        setGroupCode('');
        props.onCancel();
      }}
    >
      <BaseButtonsForm
        {...formItemLayout}
        layout="inline"
        isFieldsChanged={isFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        name="validateForm"
        initialValues={{
          'input-number': 3,
          'checkbox-group': ['A', 'B'],
          rate: 3.5,
        }}
        footer={
          <BaseButtonsForm.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <div style={{ display: 'flex', gap: 16 }}>
              <BaseButton
                loading={isLoading}
                onClick={() => {
                  setIsGroupRegionChecked(false);
                  setAddGroup(false);
                  setGroupName('');
                  setPhoneNumber('');
                  setGroupRegion('T');
                  setGroupData([]);
                  setGroupList([]);
                  setGroupCode('');
                  setIsExistGroupCheck(false);
                  props.onCancel();
                }}
              >
                취소하기
              </BaseButton>

              <BaseButton type="primary" loading={isLoading} onClick={doAddGroup}>
                기관 추가
              </BaseButton>
            </div>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
      >
        <BaseRow style={{ width: '100%' }}>
          <BaseSelectWrapper span={24}>
            <FloatLabel label="지역 - 구이름" desc="" name="select">
              <div style={{ marginTop: 10, padding: '7px', border: '0px solid #999a', borderRadius: 7, width: '100%' }}>
                <BaseSelect
                  size="small"
                  id="region"
                  value={groupRegion}
                  onChange={(value: any) => {
                    setGroupRegion(value);
                    setGroupName('');
                    findGroup('*');
                    getGroups(value);
                  }}
                  style={{ width: '100%', minHeight: '40px' }}
                >
                  {menus.map((key: any, idx: number) => (
                    <Option key={idx} value={key}>
                      {regions[key]}
                    </Option>
                  ))}
                </BaseSelect>
              </div>
            </FloatLabel>
            <FloatLabel
              label="소속기관"
              desc={`${
                isGroupRegionChecked
                  ? '등록되지 않은 기관입니다. 새로 등록하려면 체크 버튼을 눌러주세요.'
                  : isExistGroupCheck && !isGroupRegionChecked
                  ? '이미 등록된 기관입니다.'
                  : ''
              }`}
              name="select"
              required
            >
              <div
                style={{ marginTop: -20, padding: '7px', border: '0px solid #999a', borderRadius: 7, width: '100%' }}
                className="group-region"
              >
                <BaseInput
                  style={{ minHeight: '40px' }}
                  required
                  id="groupName"
                  value={groupName}
                  autoComplete="off"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGroupName(event.target.value);
                    // findGroup(event.target.value);
                    console.log(event.target.value);

                    const value = event.target.value;

                    if (value.length < 5) {
                      setIsGroupRegionChecked(false);
                      setAddGroup(false);
                    }

                    if (value.length > 4 && isExistGroupName(value)) {
                      setIsGroupRegionChecked(false);
                      setAddGroup(false);
                      setIsExistGroupCheck(true);
                    }

                    if (value.length > 4 && !isExistGroupName(value)) {
                      setIsGroupRegionChecked(true);
                      setIsExistGroupCheck(false);
                    }
                  }}
                  size="small"
                />
                <div className="img-wrapper">
                  <img
                    src={cancelImg}
                    alt={'cancel img'}
                    onClick={() => {
                      setGroupName('');
                      setIsGroupRegionChecked(false);
                      setAddGroup(false);
                      setPhoneNumber('');
                    }}
                  />
                  <img
                    src={checkImg}
                    alt="check img"
                    onClick={() => {
                      if (groupName.length > 4 && isGroupRegionChecked) {
                        setAddGroup(true);
                      }
                    }}
                  />
                </div>
              </div>
            </FloatLabel>
            {addGroup && (
              <FloatLabel
                label="전화번호"
                desc="신규 등록하는 기관의 전화번호를 입력해 주세요. 예시: 02-1234-5678"
                name="phone"
              >
                <div
                  style={{ marginTop: 20, padding: '7px', border: '0px solid #999a', borderRadius: 7, width: '100%' }}
                >
                  <BaseInput
                    id="phone"
                    style={{ minHeight: '40px' }}
                    value={phoneNumber}
                    autoComplete="off"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPhoneNumber(event.target.value);
                    }}
                    size="small"
                  />
                </div>
              </FloatLabel>
            )}
            {/*<BaseSpace align="end">*/}
            {/*  {addGroup && (*/}
            {/*    <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={doAddGroup}>*/}
            {/*      기관 추가*/}
            {/*    </BaseButton>*/}
            {/*  )}*/}
            {/*</BaseSpace>*/}
          </BaseSelectWrapper>
          {/*<BaseCol span={1}>*/}
          {/*  <Divider type="vertical" style={{ height: '300px' }} />*/}
          {/*</BaseCol>*/}
          {/*<BaseCol span={11}>*/}
          {/*  <BaseList*/}
          {/*    size="small"*/}
          {/*    style={{*/}
          {/*      marginTop: 0,*/}
          {/*      borderRadius: 1,*/}
          {/*      border: '1px solid #2222',*/}
          {/*      position: 'relative',*/}
          {/*      overflow: 'auto',*/}
          {/*      maxHeight: 290,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {groupList.map((group, idx) => (*/}
          {/*      <BaseList.Item*/}
          {/*        key={idx}*/}
          {/*        onClick={(event: any) => {*/}
          {/*          onClickGroupItem(group.id, group.name);*/}
          {/*          setGroupCode(group.id);*/}
          {/*          setGroupName(group.name);*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {group.name}*/}
          {/*      </BaseList.Item>*/}
          {/*    ))}*/}
          {/*  </BaseList>*/}
          {/*</BaseCol>*/}
        </BaseRow>
      </BaseButtonsForm>
    </Modal>
  );
};

const BaseSelectWrapper = styled(BaseCol)`
  .ant-select-selector {
    min-height: 40px;
  }
  .group-region {
    position: relative;
  }
  .img-wrapper {
    img {
      cursor: pointer;
    }
    display: flex;
    gap: 10px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
  }
`;

/**
      <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={doGroupUpload}>
            기관 초기화
      </BaseButton>
        <FloatLabel label="검사일" desc="검사일을 선택해주세요. 언제든지 수정 가능합니다." name="select" required width='20rem'>
        <div style={{ marginTop: '10px', padding: '7px', border: '1px solid #999a', borderRadius: 7}}>
          <Radio.Group>
            <Radio value="a">오늘</Radio>
            <Radio value="b">내일</Radio>
            <Radio value="c">미정</Radio>
          </Radio.Group>
          </div>
      </FloatLabel>

 */
