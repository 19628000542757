import { LAYOUT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { GitHubButton } from '../header/components/GithubButton/GitHubButton';

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};

  .ant-input-prefix {
    display: none !important;
  }
  .ant-space {
    position: absolute !important;
    right: 10px !important;
    top: -21px !important;
  }
`;

export const GHButton = styled(GitHubButton)`
  display: none;
  .ant-space {
    position: absolute !important;
    right: 10px !important;
    top: -21px !important;
  }
  @media only screen and ${media.lg} {
    display: block;
  }
`;
