import styled from 'styled-components';
import { Checkbox, Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-body {
    padding-top: 0px !important;
  }
  .ant-modal-title {
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 42px !important; /* 116.667% */
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  overflow: auto;
`;

export const Desc = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #6c757d;
`;

export const BoxWrapper = styled.div`
  padding: 32px;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  border-radius: 16px;
  border: 1px solid #6c757d;
  background: var(--neutral-100, #f8f9fa);
  margin-bottom: 24px;
`;

export const GroupTag = styled.span`
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primary-300, #8fa0ff);
  background: var(--primary-100, #ebefff);
  margin-bottom: 20px;
`;

export const CheckboxContainer = styled.div<{ isBorderBottom: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* 좌우 간격 16px */
  margin: 24px 0; /* 위아래 간격 24px */
  border-bottom: ${(props) =>
    props.isBorderBottom ? `1px solid #adb5bd;` : null}; /* 실선(border) 스타일 및 색상 설정 */
  padding-bottom: 48px;
`;

export const CustomCheckbox = styled(Checkbox)`
  /* 네모 모양으로 만들기 */
  &.ant-checkbox-wrapper {
    .ant-checkbox {
      border-radius: 0; /* 네모로 만들기 */
    }
  }
  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border-radius: 0 !important; /* 네모로 유지 */
    }
    .ant-checkbox + span {
      color: #343a40 !important;
    }
  }

  /* 체크되지 않은 상태에서 라벨 텍스트 회색으로 변경 */
  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
    .ant-checkbox-inner {
      border-radius: 0 !important; /* 네모로 만들기 */
    }

    .ant-checkbox + span {
      color: #6c757d !important;
    }
  }
`;

export const AttachmentCheckBox = styled(CustomCheckbox)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #495057;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 26px;
  > span:nth-child(2) {
    margin-left: 12px;
    position: relative;
    top: 2px;
  }
`;

export const DownloadButton = styled.button`
  width: 100%;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--primary-400, #6e82fe);
  background: var(--primary-100, #ebefff);
  color: #354acb;
  cursor: pointer;
  transition: ease-in 0.3s;
  &:hover {
    background: #354acb;
    color: #fff;
  }
`;

export const SendEmailButton = styled.button<{ disabled: boolean }>`
  border: none;
  width: 100%;
  margin-top: 16px;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  color: ${({ disabled }) => (disabled ? '#ADB5BD' : '#ffffff')};
  cursor: pointer;
  transition: ease-in 0.3s;

  border-radius: 8px;
  background: ${({ disabled }) => (disabled ? '#E9ECEF' : '#6e82fe')};
  &:hover {
    background: #354acb;
    color: #fff;
  }
`;

export const CountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
`;

export const EmailHistoryLists = styled.div`
  margin-top: 4px;
  text-align: right;

  .title {
    font-size: 14px;
    color: #343a40;
    font-weight: 400;
  }

  .history-lists {
    > li {
      list-style: none;
    }
    font-size: 12px;
    font-weight: 300;
    .text {
      color: #6c757d;
    }
    .time {
      display: inline-block;
      width: 76px;
      text-align: left;
      color: #343a40;
      margin-right: 8px;
    }
    .email {
      color: #6c757d;
    }
  }
`;

export const EmailFloatLabel = styled.div<{ error: boolean }>`
  margin-top: 16px;
  .float-label {
    margin-bottom: 0px !important;
  }
  .label {
    color: ${({ error }) => (error ? '#C50000' : '#6C757D')} !important;
  }
  .error {
    padding: 10px;
    font-weight: 300;
    font-size: 12px;
    color: #c50000;
  }
  input {
    border: ${({ error }) => (error ? '1px solid #C50000' : '1px solid #495057')} !important;
  }
`;
export const FooterWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  gap: 16px;

  > img {
    cursor: pointer;
  }
`;

export const BaseButton = styled.button<{ disabled?: boolean }>`
  padding: 8px 16px;
  width: 100%;
  border-radius: 8px;
  //border: 1px solid var(--primary-400, #6e82fe);
  border: ${({ disabled }) => (disabled ? 'none' : '1px solid  #6e82fe')};
  //background: var(--primary-100, #ebefff);
  background: ${({ disabled }) => (disabled ? '#E9ECEF' : '#ebefff')};
  //color: #354acb;
  color: ${({ disabled }) => (disabled ? '#ADB5BD' : '#354acb')};
  cursor: pointer;
`;