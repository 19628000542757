import React, { useState } from 'react';
import { Input } from 'antd';

import './FloatLabel.css';

const FloatLabel = (props: any) => {
  const { label, children, desc, required, width } = props;
  let { placeholder } = props;

  if (!placeholder) placeholder = label;

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div className="float-label" style={{ width: width }}>
      {children}
      <label className="label as-label">
        {label} {requiredMark}
      </label>
      <label className="description">{desc}</label>
    </div>
  );
};

export default FloatLabel;
