import * as S from './DeleteRoleModal.styles';
import dangerImage from '@app/assets/images/danger.png';
import { ContentBox } from './DeleteRoleModal.styles';
import * as Role from './RoleManagementModal.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { FooterBtn } from './RoleManagementModal.styles';
import React, { useEffect, useRef, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { DetailTaskData } from '@app/interfaces/interfaces';
interface ModalProps {
  onOk: (id: string) => void;
  onCancel: () => void;
  width: string;
  open: boolean;
  user?: { id: string; name: string } | null;
  type?: 'task';
  desc?: any;
  task?: DetailTaskData | null;
}

export default function DeleteExecutorModal({ open, onOk, onCancel, width, user, type, desc, task }: ModalProps) {
  return (
    <S.ModalWrapper closable={false} open={open} title="" onCancel={onCancel} width={width} footer={null}>
      <S.Title>
        <span>잠시만요</span> <img src={dangerImage} alt="danger icon" />
      </S.Title>
      <ContentBox>
        <Role.Tag>
          {type && task ? `${task?.name}・ ${task?.groupName} ・ ${task?.executers[0]?.name ?? ''}` : user?.name}
        </Role.Tag>
        <p style={type ? { fontWeight: 400 } : {}}>{desc ?? '정말로 이 사용자를 삭제하시겠습니까?'}</p>
      </ContentBox>

      <FooterBtn>
        <BaseButton
          key="cancel"
          style={{ marginRight: '10px', background: '#E9ECEF', border: 'none' }}
          onClick={onCancel}
        >
          취소
        </BaseButton>
        <BaseButton
          style={{ background: '#6E82FE', border: 'none' }}
          key="ok"
          type="primary"
          color="#6E82FE"
          onClick={() => {
            if (type && task?.taskId) {
              onOk(task.taskId);
              notificationController.info({ message: '삭제완료했습니다.' });
            }

            if (!type && user?.id) {
              try {
                onOk(user.id);
                notificationController.info({ message: '삭제완료했습니다.' });
                onCancel();
              } catch (err) {
                notificationController.error({ message: 'error가 발생했습니다.' });
              }
            }
          }}
        >
          삭제하기
        </BaseButton>
      </FooterBtn>
    </S.ModalWrapper>
  );
}
