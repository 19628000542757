import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { parentSidebarNavigation, sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { Menu } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { isSameClickSideBar, userDatas, ymaDatas, ymDatas } from '@app/hocs/cache';
import { MenuWrapper } from './SiderMenu.styles';
import { PARENT_PATH } from '@app/components/router/RequireAuth';
import { MailOutlined, VideoCameraOutlined } from '@ant-design/icons';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = useReactiveVar(userDatas);
  const userCategory = localStorage.getItem('userCategory') ?? '';
  const ymaData = useReactiveVar(ymaDatas);

  const sidebar = PARENT_PATH.includes(location.pathname) ? parentSidebarNavigation : sidebarNavigation;
  //console.log('====================================>'+ymaData.eqid)
  //if( (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('d.mbisq.com') !== -1) && sidebar.length === 5){
  if( ymaData.eqid === '652c8a513003bda78cb51809' && sidebar.length === 5){  
    sidebar.push(  {
      title: 'email관리', //'common.video-dashboard',
      key: 'email-dashboard',
      url: '/email-tables',
      icon: <MailOutlined/>,
    },);
    sidebar.push(  {
      title: 'Video관리', //'common.video-dashboard',
      key: 'video-dashboard',
      url: '/video-tables',
      icon: <VideoCameraOutlined/>,
    },);
  }

  const sidebarNavFlat = sidebar.reduce(
    (result: SidebarNavigationItem[], current) =>
      result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebar.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  useEffect(() => {
    // ant-menu-item 클래스를 가진 모든 엘리먼트를 선택합니다.
    const menuItems = document.querySelectorAll('.ant-menu-item');

    // 현재 페이지의 경로와 일치하는 메뉴 아이템의 인덱스를 찾습니다.
    const findIndex = sidebar.findIndex((item) => item.url === location.pathname);

    menuItems.forEach((menuItem) => {
      menuItem?.classList?.remove('ant-menu-item-selected'); // 스타일 초기화
    });

    // 일치하는 메뉴 아이템에 스타일을 추가합니다.
    if (findIndex !== -1) {
      const matchedMenuItem = menuItems[findIndex];
      if (matchedMenuItem?.classList) {
        matchedMenuItem?.classList?.add('ant-menu-item-selected'); // 스타일 추가
      }
    }
  }, [location.pathname]);

  const navigate = useNavigate();

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => {
        setCollapsed(true);
      }}
      items={sidebar.map((nav) => {
        const isSubMenu = nav.children?.length;
        const auths = ymaData?.auth?.split('|') ?? [];
        const isActive = location.pathname === nav.url;

        const findIndex = sidebar.findIndex((item) => item.url === location.pathname);

        if (nav.title === '관리' && !auths.includes('관리자')) return null;
        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? (
            t(nav.title)
          ) : (
            <Link
              onClick={() => {
                if (location.pathname === nav.url) {
                  isSameClickSideBar(true);
                }
              }}
              to={nav.url || ''}
            >
              {t(nav.title)}
            </Link>
          ),
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav) => ({
              key: childNav.key,
              label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
              title: t(childNav.title),
            })),
        };
      })}
    />
  );
};

export default SiderMenu;
