import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import { AppDate, Dates } from 'constants/Dates';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { ExamCalendar } from './ExamCalendar/ExamCalendar';
import { ScheduleEvent, getUserCalendar } from '@app/api/schedule.api';
import { getExaminersData, Examiner } from '@app/api/examiners.api';
import { ExaminersPanel } from './ExaminersPanel';
import { AppointmentPanel } from './AppointmentPanel';
import { Divider } from 'antd';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { getAllYMExecutersGQL } from '@app/hocs/gql';
import dayjs from 'dayjs';
import { userDatas, ymDatas, ymaDatas } from '@app/hocs/cache';

export const GroupScheduleCard = (props:any) => {
  const { isTablet } = useResponsive();

  const [calendar, setCalendar] = useState<ScheduleEvent[]>([]);
  const [selectedDate, setDate] = useState<AppDate>(Dates.getToday());
  const [isDateClicked, setDateClicked] = useState(false);
  const [afterDate, setAfterDate] = useState<AppDate>(Dates.getToday());

  //const [examiners, setExaminers] = useState<Examiner[]>([]);
  const [selectedExaminer, setExaminer] = useState<Examiner>();
  const [isExaminerClicked, setExaminerClicked] = useState(false);

  //const user = props.examinee; //useAppSelector((state) => state.user.user);
  //const executers = useRef<any[]>([]);
  const ymData = useReactiveVar(ymDatas);


  useEffect(() => {
    
    // get calendar
    //user && getUserCalendar(user?.id).then((res) => setCalendar(res)); // -> calendar
    // get examiner
    //getExaminersData().then((res) => setExaminers(res)); // -> examiner

    //setExaminers(ymDatas().examiners);
      setDate(dayjs(parseInt(ymData.tg.reservedAt))); //new Date(parseInt(user.reservedAt))  
      setAfterDate(dayjs(parseInt(ymData.tg.reservedAt)));  

  }, [ymDatas().tg]);

  const { i18n, t } = useTranslation();
/*
  useEffect(() => {
    setDate((selectedDate) => selectedDate.locale(i18n.language));
  }, [i18n.language]);
*/
  
  useEffect( ()=>{
    if( isDateClicked === false ) return;
    console.log('selectedDate:'+selectedDate.format('YYYY-MM-DD'));
    //ymDatas().tg.reservedAt = selectedDate.toDate().getTime();
    //ymDatas({...ymData, tg:{...ymData.tg, reservedAt:selectedDate.toDate().getTime()}}); // update cache
    //setAfterDate(selectedDate);
    setDateClicked(false);
  }, [isDateClicked])

  const handleDecreaseMonth = () => {
    setAfterDate(selectedDate.month(selectedDate.month() - 1));
  };

  const handleIncreaseMonth = () => {
    setAfterDate(selectedDate.month(selectedDate.month() + 1));
  };

  const handleToday = () => {
    setAfterDate(Dates.getToday());
  };

  const calendarItem = (
    <ExamCalendar calendar={calendar} date={afterDate}
      setDate={setAfterDate}
      onDecrease={handleDecreaseMonth}
      onIncrease={handleIncreaseMonth}
      onToday={handleToday}
      setDateClicked={setDateClicked}
    />
  );

  const currentEvent = calendar.find((event) => Dates.getDate(event.date).isSame(selectedDate, 'date'));

  //<>Hello{( selectedExaminer !== undefined ) && selectedExaminer.name}/{Dates.format(selectedDate, 'YY MM DD')}</>; //<ExaminersPanel event={currentEvent} />;

  return (
    <DashboardCard title={''} style={{backgroundColor:'#EBEFFF'}}>
      <RowStyled gutter={[10, 10]} justify={'end'} wrap={true}>
        {isTablet ? (
          <>
          <BaseCol span={24}>
            {calendarItem}
            </BaseCol>
          <BaseCol span={24}><RowStyled gutter={[0, 0]} justify={'space-between'} align={"middle"}>
            <BaseButton round type="default" onClick={()=>setAfterDate(selectedDate)}>{selectedDate.format('YYYY-MM-DD (ddd)')} (원래 날짜로)</BaseButton>
            <RightOutlined />
            <BaseButton round type="default" onClick={()=>{props.setReserved(afterDate); setDate(afterDate)}}>{afterDate.format('YYYY-MM-DD (ddd)')}로 변경하기</BaseButton></RowStyled></BaseCol>
          </>
        ) : (isDateClicked || isExaminerClicked) && calendar.some((event) => Dates.getDate(event.date).isSame(selectedDate, 'date')) ? (
          <BackButtonWrapper span={24}>
            <BackButton type="text" icon={<ArrowLeftOutlined />} onClick={() => setDateClicked(false)} />
          </BackButtonWrapper>
        ) : (
          <BaseCol span={20}>{calendarItem}</BaseCol>
        )}
      </RowStyled>
    </DashboardCard>
  );
};

const BackButtonWrapper = styled(BaseCol)`
  position: relative;
`;

const BackButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white);
`;

const RowStyled = styled(BaseRow)`
  min-height: 0rem;
`;
