import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { Modal } from 'antd';
import styled from 'styled-components';
import checkImage from '@app/assets/images/tag-check.png';
export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
`;

export const Text = styled.p`
  font-weight: ${FONT_WEIGHT.regular};
  line-height: 32px;
  font-size: ${FONT_SIZE.lg};
`;

export const BoldText = styled.span`
  font-weight: ${FONT_WEIGHT.medium};
  line-height: 32px;
  font-size: ${FONT_SIZE.lg};
`;

export const BodyWrapper = styled.div`
  display: flex;

  gap: 36px;
`;

export const RoleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const PermissionWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: ${FONT_SIZE.md};
`;

export const RoleBox = styled.div`
  width: 327px;
  //height: 100%;
  min-height: 152px;
  border-radius: 16px;
  padding: 16px;
  background: #e9ecef;
  border: 1px solid #495057;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const PermissionBox = styled.div`
  width: 512px;
  //height: 100%;
  min-height: 364px;
  border-radius: 16px;
  padding: 24px;
  background: #e9ecef;
  border: 1px solid #495057;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
`;

export const Tag = styled.span`
  position: relative; /* 가상 요소의 위치를 설정하기 위해 부모 요소를 relative로 설정합니다. */
  align-self: flex-start;

  padding: 8px 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  border: 1px solid #6c757d;
  cursor: pointer;

  img {
    object-fit: cover;
    margin-left: 7px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const ActiveTag = styled.span`
  position: relative;
  padding: 8px 24px 8px 34px;
  //float: right; /* 텍스트를 오른쪽으로 붙입니다. */
  display: flex;
  border-radius: 100px;
  background: #aefdef;
  border: 1px solid #35e5f2;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px; /* 이미지의 너비 */
    height: 16px; /* 이미지의 높이 */
    background-image: url(${checkImage});
    background-repeat: no-repeat;
    background-size: contain;

    margin-right: 80px !important; /* 이미지와 텍스트 사이의 오른쪽 마진을 설정합니다. */
  }
`;

export const PlusRoleTag = styled.span<{ clicked: boolean }>`
  //width: 56px;
  //height: 44px;
  //border-radius: 100px;
  //background: #fff;
  //border: 1px solid #6c757d;
  //display: inline-flex;
  //justify-content: center;
  //align-items: center;
  //cursor: pointer;

  width: ${(props) => (props.clicked ? '100px' : '56px')};
  height: 44px;
  border-radius: 100px;
  background: ${(props) => (props.clicked ? '#fff' : 'transparent')};
  border: 1px solid #6c757d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* 애니메이션 효과 추가 */
`;

export const FooterBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const InputField = styled.input<{ clicked: boolean; width?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.clicked && props.width ? props.width : props.clicked ? '100%' : '0')};
  padding: ${(props) => (props.clicked ? '0 12px' : '0')}; /* 여백 추가 */

  height: 44px;
  border: none;
  background: transparent;

  transition: all 0.3s ease; /* 애니메이션 효과 추가 */
  outline: none;
`;

export const Container = styled.div`
  position: relative;
  background: #fff;
  border-radius: 50%;
`;

export const SubmitButton = styled.button`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--primary-400, #6e82fe);
  border: none;

  cursor: pointer;

  &:hover {
    background: #1d2f99;
  }
`;
