import React, { useEffect, useState } from 'react';
import { getExaminersData, Examiner } from '@app/api/examiners.api';
import { TreatmentDoctor } from './TreatmentDoctor/TreatmentDoctor';
import { specifities } from '../../../constants/specifities';
import { TreatmentNotFound } from './TreatmentNotFound/TreatmentNotFound';
import { ScheduleEvent } from '@app/api/schedule.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ymDatas, ymaDatas } from '@app/hocs/cache';

interface ExamineePanelProps {
  event?: ScheduleEvent;
  setExaminer: (state: Examiner) => void;
  setExaminerClicked: (state: boolean) => void;
}

export const ExaminersPanel: React.FC<ExamineePanelProps> = ({ event, setExaminer, setExaminerClicked }) => {
  const thisday = 170;
  const foundDay = (days: any) => {
    return days.find((element: any) => element === thisday) === undefined;
  };
  const foundExaminer = (id: any) => {
    return ymData.examiners.find((element: any) => element.id === id);
  };
  const ymData = ymDatas();

  console.log(ymData, 'examiner');

  return (
    <BaseRow gutter={[0, 10]}>
      <BaseCol span={20}></BaseCol>
      {
        ymData.examiners.map((v: any, i: any) => {
          //console.log('id:'+v.id+' name:'+v.name+' selected:'+v.selected+' auth:'+v.auth);
          console.log(v.selected, v?.name, '123123');
          const span = 10;
          if (v.auth.indexOf('exam') === -1 && v.selected === false) return <></>;
          return (
            v.name && (
              <BaseCol key={i} span={span}>
                <BaseButton
                  round
                  type={v.selected ? 'primary' : 'ghost'}
                  size="small"
                  onClick={() => {
                    setExaminer({ id: v.id, name: v.name, selected: v.selected });
                    setExaminerClicked(true);
                    //ymDatas({...ymDatas(), eqid:v.id}); // update cache
                  }}
                >
                  {v.name}
                </BaseButton>
              </BaseCol>
            )
          );
        })

        /*ymaDatas().eqs.map( (v:any,i:any) => {
        let span = 10;
        const vObj = JSON.parse(v.data);
        vObj.days = vObj.days === undefined ? [] : vObj.days;
        if(v.name.length > 3) span = 10;
              return <BaseCol key={i} span={span}>
                <BaseButton round
                type={ (foundExaminer(v.id) && foundDay(vObj.days)) ? (v.id === ymDatas().eqid ? "primary":"default"):"ghost"}
                size="small"
                onClick={() => {
                  setExaminer({id:v.id, name:v.name});
                  setExaminerClicked(true);
                  //ymDatas({...ymDatas(), eqid:v.id}); // update cache
                }}
              > 
                {v.name}
              </BaseButton></BaseCol>
    })*/
      }
    </BaseRow>
  );
  /*
  const currentExaminer = examiners.find((examiner) => examiner.id === event?.examiner);

  if (event && currentExaminer) {
    const doctor: TreatmentDoctor = {
      name: currentExaminer.name,
      address: currentExaminer.address,
      date: event.date,
      imgUrl: currentExaminer.imgUrl,
      phone: currentExaminer.phone,
      speciality: specifities.find(({ id }) => id === currentExaminer.specifity)?.name || '',
    };

    return <TreatmentDoctor doctor={doctor} />;
  } else {
    return <TreatmentNotFound />;
  }
  */
};
