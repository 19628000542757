import * as S from '@app/components/ui/SettingPopOverContent.styles';
import * as SettingTable from '@app/pages/SettingTables.styles';
import doneImage from '@app/assets/icons/popover_done.svg';
import { Executer } from '@app/interfaces/interfaces';
import { useEffect, useState } from 'react';
import cancelImage from '@app/assets/icons/tag_cancel.svg';
interface Props {
  executers: Executer[];
  updateYMTask: (taskId: string, executorId: string) => Promise<void>;
  onClose: () => void;
  value: { taskId: string; executers: { id: string }[] };
  executer: { id: string }[];
}
type ExecutorWithCheck = Executer & {
  isChecked: boolean;
};
export default function GradingPopOverContent({ executers, updateYMTask, onClose, value, executer }: Props) {
  const [executorsData, setExecutorsData] = useState<ExecutorWithCheck[]>([]);
  const [selectedExe, setSelectedExe] = useState<ExecutorWithCheck | null>(null);
  const onClickTagHandler = (id: string) => {
    setExecutorsData((prevExecutorsData) => {
      const updatedExecutors = prevExecutorsData.map((executor) => {
        if (executor.id === id) {
          return { ...executor, isChecked: true };
        } else {
          return { ...executor, isChecked: false };
        }
      });

      return updatedExecutors;
    });
  };

  const updateExecutor = async () => {
    const checkedExecutors = executorsData.find((executor) => executor.isChecked);
    if (checkedExecutors) {
      await updateYMTask(value.taskId, checkedExecutors.id);
    }
    onCloseHandler();
  };

  const initData = () => {
    const updatedExecutors = executers
      .map((exe) => ({ ...exe, isChecked: false }))
      .map((item) => {
        if (executer?.length && executer[0] !== null && item.id === executer[0].id) {
          item.isChecked = true;
          return item;
        }
        return item;
      });
    console.log(executer, 'updatedExecutors');
    setExecutorsData(updatedExecutors);
  };

  const onCloseHandler = () => {
    onClose();
    initData();
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <S.ContentWrapper>
      <S.ButtonWrapper>
        {executorsData.map((executor) => (
          <SettingTable.TabButton
            key={executor.id}
            isChecked={executor.isChecked}
            isHovered={true}
            onClick={() => {
              onClickTagHandler(executor.id);
            }}
          >
            {executor.name}
          </SettingTable.TabButton>
        ))}
      </S.ButtonWrapper>

      <S.Footer>
        <img src={cancelImage} alt="cancel img" onClick={onCloseHandler} />

        <img src={doneImage} alt="done img" onClick={updateExecutor} />
      </S.Footer>
    </S.ContentWrapper>
  );
}
