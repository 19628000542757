import styled from 'styled-components';
import { Link } from 'react-router-dom';

// export const StatusTag2 = styled.div`
//   padding: 8px 16px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 300px;
//   font-weight: 400;
//   max-width: 160px;
//   //background: #fff8eb;
//   //color: #c50000;
//   //border: 1px solid #f27610;
//
//   //color: #10658c;
//   //border: 1px solid #26b6d0;
//   //background: #d6fef3;
//
//   //color: #354acb;
//   //border: 1px solid var(--primary-500, #556aeb);
//   //background: var(--primary-100, #ebefff);
//
//   color: var(--neutral-700, #495057);
//   border: 1px solid var(--neutral-500, #adb5bd);
//   background: var(--neutral-200, #e9ecef);
// `;

export const FlexContainer = styled.div<{ justifyContent?: 'center'; alignItems?: 'center'; gap?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: ${({ gap }) => gap || '0px'};
`;

export const StatusTag = styled.div<{ status: number }>`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 300px;
  font-weight: 400;
  max-width: 160px;
  margin: 0 auto;
  ${({ status }) => {
    switch (status) {
      case 1:
        return `
          background: #fff8eb;
          color: #c50000;
          border: 1px solid #f27610;
        `;
      case 0:
        return `
          color: #10658c;
          border: 1px solid #26b6d0;
          background: #d6fef3;
        `;
      case 9:
        return `
          color: #354acb;
          border: 1px solid var(--primary-500, #556aeb);
          background: var(--primary-100, #ebefff);
        `;
      case 3:
        return `
            color: var(--neutral-700, #495057);
             border: 1px solid var(--neutral-500, #adb5bd);
             background: var(--neutral-200, #e9ecef);
        `;
      default:
        return `
          /* 기본 스타일 설정 */
          color: var(--neutral-700, #495057);
          border: 1px solid var(--neutral-500, #adb5bd);
          background: var(--neutral-200, #e9ecef);
        `;
    }
  }}
`;

export const LinkButton = styled(Link)`
  margin: 0 auto;
  max-width: 95px;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.3s;
  border-radius: 22px;
  border: 1px solid var(--neutral-500, #adb5bd);
  background: var(--neutral-200, #e9ecef);
  color: #495057;

  &:hover {
    background: #d6fef3;
  }
`;

export const TableWrapper = styled.div`
  .ant-table-pagination {
    display: flex !important;
  }
  .ant-table-pagination {
    display: flex !important;
  }

  .ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    padding-left: 23px !important;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
  }
  .ant-pagination-options {
    display: none !important;
  }
`;
