import * as S from './SendEmailToGroupModal.styles';

import { ChangeEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';

import { DetailTaskData, GetYMGroupData, TargetData, TaskData } from '@app/interfaces/interfaces';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getAssessmentByTargetIdGQL, getYMGroupById } from '@app/hocs/gql';
import get from 'lodash/get';
import FloatLabel from '@app/components/common/FloatLabel/FloatLabel';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { notificationController } from '@app/controllers/notificationController';
import AWS from 'aws-sdk';
import awsService from '@app/utils/aws';
import teacherPdf from '@app/assets/pdf/teacher_report.pdf';
import { Buffer } from 'buffer';
import { EmailHistoryLists } from './SendEmailToGroupModal.styles';
import { Dates } from '@app/constants/Dates';
import { generateUniqueFileName, sendEmail, uploadZipToS3 } from '@app/utils/utils';
import { use } from 'echarts';

interface Props {
  title: string;
  open: boolean;
  onSendHandler: () => void;
  onDownloadHandler: () => void;
  onCancel: () => void;
  width: string;
  selectedTaskData: DetailTaskData | null;
  updateYmGroupData: (id: string, data: any, email: string) => void;
}

interface AgreementTargetData {
  id: string;
  name: string;
  isChecked: boolean;
  privacyAgreed: number;
}

interface TargetPdfData {
  name: string;
  pdfData: string[];
  privacyAgreed: number;
  taskId: string;
  targetId: string;
}

// AWS.config.update({
//   accessKeyId: 'AKIAY5JEW5JOQCJT72SI',
//   secretAccessKey: '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',
//   region: 'ap-northeast-2',
// });

const ses = new AWS.SES({ apiVersion: '2010-12-01' });

export default function SendEmailToGroupModal({
  title,
  open,
  onSendHandler,
  onDownloadHandler,
  onCancel,
  width,
  selectedTaskData,
  updateYmGroupData,
}: Props) {
  const { data } = useQuery<GetYMGroupData>(getYMGroupById, {
    variables: {
      getYmGroupId: selectedTaskData?.groupId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  //const [targetLists, setTargetLists] = useState<TargetData[]>([]);

  const [getAssessmentByTargetId] = useLazyQuery(getAssessmentByTargetIdGQL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [agreementTargets, setAgreementTargets] = useState<TargetPdfData[]>([]);
  const [disagreementTargets, setDisagreementTargets] = useState<TargetPdfData[]>([]);
  const [emailTargetLists, setEmailTargetLists] = useState<TargetPdfData[]>([]);
  const [loading, setLoading] = useState(false);

  const [reserve, setReserve] = useState('');
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [emailReserveLoading, setEmailReserveLoading] = useState(false);

  const [isAttachmentChecked, setIsAttachmentChecked] = useState(true);

  //const [targetsData, setTargetsData] = useState<{ id: string; name: string; isChecked: boolean }[]>([]);
  //const [targetPdfLists, setTargetPdfLists] = useState<TargetPdfData[]>([]);

  const addAgreedTarget = (targetValue: TargetPdfData) => {
    const updatedDisagreementTargets = [...disagreementTargets];
    const index = updatedDisagreementTargets.findIndex((target) => target.taskId === targetValue.taskId);

    if (targetValue.privacyAgreed === 0) {
      if (index !== -1) {
        updatedDisagreementTargets[index].privacyAgreed = 1;
      }
      setEmailTargetLists((prevState) => [...prevState, { ...targetValue }]);
    } else {
      if (index !== -1) {
        updatedDisagreementTargets[index].privacyAgreed = 0;
      }

      const filteredTargetData = emailTargetLists.filter((target) => target.taskId !== targetValue.taskId);
      setEmailTargetLists(filteredTargetData);
    }

    setDisagreementTargets(updatedDisagreementTargets);
  };

  const addDisAgreedTarget = (targetValue: TargetPdfData) => {
    const updatedDisagreementTargets = [...agreementTargets];
    const index = updatedDisagreementTargets.findIndex((target) => target.taskId === targetValue.taskId);

    if (targetValue.privacyAgreed === 0) {
      if (index !== -1) {
        updatedDisagreementTargets[index].privacyAgreed = 1;
      }
      setEmailTargetLists((prevState) => [...prevState, { ...targetValue }]);
    } else {
      if (index !== -1) {
        updatedDisagreementTargets[index].privacyAgreed = 0;
      }

      const filteredTargetData = emailTargetLists.filter((target) => target.taskId !== targetValue.taskId);
      setEmailTargetLists(filteredTargetData);
    }
    setAgreementTargets(updatedDisagreementTargets);
    //
    // const removeAgreedTarget = emailTargetLists.filter((target) => target.taskId !== id);
    // setEmailTargetLists(removeAgreedTarget);

    // setDisagreementTargets((prevState) => [...prevState, { ...value, privacyAgreed: 0 }]);
  };
/*
  const handleCheckboxChange = (id: string) => {
    // Toggle the isChecked state for the checkbox with the given id
    setTargetsData((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, isChecked: !item.isChecked } : item)),
    );
  };
*/
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // 이메일 유효성 검사 함수 호출
    if (!validateEmail(newEmail)) {
      setError('이메일 주소를 확인해 주세요.');
    } else {
      setError('');
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return re.test(email);
  };

  const makeSimpleEmail = async ( zipFileUrl:any) => {

    const message = {
      Subject: { Data: '[서울아이발달지원센터] 발달선별검사 보고서'},
      Body: {
        Html: {
          Data: `
            <html>
              <head></head>
              <body>
                <p style="color: #333;">
                안녕하세요. 서울아이발달지원센터입니다.<br/><br/>
                우선 올해 서울아이발달지원센터의 [찾아가는 어린이집 발달선별검사]에 참여해 주셔서 깊은 감사를 드립니다. 
                저희 센터의 사업에 많은 관심을 가져주시고, 적극적으로 협조해 주신 덕분에 무사히 검사를 마칠 수 있었습니다.
                <br/><br/>
                  어린이집에 정보 제공을 동의한 유아의 보고서를 첨부해서 발송해 드리며, 각 부모에게는 개별적으로 발송이 완료되었습니다.
                <br/><br/>
                  또한 어린이집 교사를 위한 가이드 자료를 다운로드할 수 있는 링크를 메일 하단에 첨부하였으니 확인 부탁드립니다.
                <br/><br/>
                  감사합니다.
                <br/><br/>
                  서울아이발달지원센터 드림.
                </p>
          ` 
        },
      },
    };
    
    let links= `<table border="1" style="border-collapse: collapse; border-color:#444; width: 400px">
    <tr>
    <td colspan="2" style="color:#000; padding-left:8px" bgcolor= "#eee">첨부</td>
    </tr>
    `;

    links += `<tr ><td style="color:#000; padding-left:8px" bgcolor= "#ccc">보고서(압축파일)</td><td style="width:30%;text-align: center;" bgcolor= "#ccc"> <a style="color:#00f; text-decoration:underline" href="${zipFileUrl}">다운로드</a></td></tr>`;


    if (isAttachmentChecked) {
      const fileData = [
        {
          data: 'https://ict.liflo.io/link/11', //'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/%E1%84%89%E1%85%A5%E1%84%8B%E1%85%AE%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A1%E1%86%AF%E1%84%83%E1%85%A1%E1%86%AF%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5_%E1%84%80%E1%85%AD%E1%84%89%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
          label: '서울아이발달지원센터_교사용가이드',
        },
      ];

      fileData.forEach((link) => {
        links += `<tr><td style="color:#333; padding-left:8px">${link.label} </td><td style="width:30%;text-align: center;"><a style="color:#00f;" href="${link.data}">열기</a></td></tr>`;
      });
    }

    message.Body.Html.Data += (links + '</table></body></html>');

/*
    let links = `<p style="margin-top: 10px; color:#000;">보고서 : <a style="color:#333;" href="${zipFileUrl}">download</a></p>\n`;

    if (isAttachmentChecked) {
      const fileData = [
        {
          data: 'https://ict.liflo.io/link/11', //'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/%E1%84%89%E1%85%A5%E1%84%8B%E1%85%AE%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A1%E1%86%AF%E1%84%83%E1%85%A1%E1%86%AF%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5_%E1%84%80%E1%85%AD%E1%84%89%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
          label: '서울아이발달지원센터_교사용가이드',
        },
      ];

      fileData.forEach((link) => {
        links += `<p style="color: #333;">${link.label}: <a style="color:#333;" href="${link.data}">열기</a></p>\n\n`;
      });
    }

    message.Body.Html.Data += (links + '</body></html>');
*/
    return message;
  }

  const makeZipFile = async () => {
      // create zip file
      const zip = new JSZip();

      for (const item of emailTargetLists) {
        // 각 PDF 파일을 가져와 .zip 파일에 추가합니다.
        for (const pdfUrl of item.pdfData) {
          const pdfArrayBuffer = await fetch(pdfUrl).then((resp) => resp.arrayBuffer());
          zip.file(`${item.name}.pdf`, pdfArrayBuffer);
        }
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const zipBase64 = await new Response(zipBlob).arrayBuffer();
      //const zipBase64String = Buffer.from(zipBase64).toString('base64');
      const zipBuffer = Buffer.from(zipBase64);
      const zipUrl = await uploadZipToS3( zipBuffer, generateUniqueFileName());
      return zipUrl;
  }
  
  const sendEmailWithAttachment = async () => {
    try {
      setSendEmailLoading(true);
/*
      // 이메일 양식 변경 183번째줄 심리발달선별검사 => 발달선별검사
      const additionalContent = `
        <p>
          안녕하세요. 서울아이발달지원센터입니다.<br/><br/>
          우선 올해 서울아이발달지원센터의 [찾아가는 어린이집 발달선별검사]에 참여해 주셔서 깊은 감사를 드립니다. 
          저희 센터의 사업에 많은 관심을 가져주시고, 적극적으로 협조해 주신 덕분에 무사히 검사를 마칠 수 있었습니다.
        </p>
        <p>
          어린이집에 정보 제공을 동의한 유아의 보고서를 첨부해서 발송해 드리며, 각 부모에게는 개별적으로 발송이 완료되었습니다.
        </p>
        <p>
          또한 어린이집 교사를 위한 가이드 자료를 다운로드할 수 있는 링크를 메일 하단에 첨부하였으니 확인 부탁드립니다.
        </p>
        <p>
          감사합니다.<br/><br/>
          서울아이발달지원센터 드림.
        </p>
`;
      // 이메일 본문 생성
      const emailSubject = '[서울아이발달지원센터] 발달선별검사 보고서';
      const emailBody = additionalContent;
      const boundary = 'boundary-example';

      // let emailContent =
      //   `Subject: ${emailSubject}\n` +
      //   'MIME-Version: 1.0\n' +
      //   `Content-type: multipart/mixed; boundary=${boundary}\n\n` +
      //   `--${boundary}\n` +
      //   `Content-Type: text/plain; charset=UTF-8\n` +
      //   `Content-Transfer-Encoding: quoted-printable\n\n` +
      //   `${emailBody}\n\n` +
      //   `--${boundary}\n` +
      //   // `Content-Type: application/zip; name="${zipFileName}"\n` +
      //   // `Content-Disposition: attachment; filename="${zipFileName}"\n` +
      //   `Content-Type: application/zip; name="result.zip"\n` + // 하나의 압축 파일로 만들어진 것으로 설정
      //   `Content-Disposition: attachment; filename="result.zip"\n` +
      //   `Content-Transfer-Encoding: base64\n\n` +
      //   `${zipBase64String}` +
      //   `--${boundary}--`;
      // let emailContent =
      //   `Subject: ${emailSubject}\n` +
      //   'MIME-Version: 1.0\n' +
      //   `Content-type: multipart/mixed; boundary=${boundary}\n\n` +
      //   `--${boundary}\n` +
      //   `Content-Type: text/plain; charset=UTF-8\n` +
      //   `Content-Transfer-Encoding: quoted-printable\n\n` +
      //   `${emailBody}\n\n` +
      //   `--${boundary}\n` +
      //   `Content-Type: application/zip; name="result.zip"\n` +
      //   `Content-Disposition: attachment; filename="result.zip"\n` +
      //   `Content-Transfer-Encoding: base64\n\n` +
      //   `${zipBase64String}\n\n`;

      const zip = new JSZip();

      for (const item of emailTargetLists) {
        // 각 PDF 파일을 가져와 .zip 파일에 추가합니다.
        for (const pdfUrl of item.pdfData) {
          const pdfArrayBuffer = await fetch(pdfUrl).then((resp) => resp.arrayBuffer());
          zip.file(`${item.name}.pdf`, pdfArrayBuffer);
        }
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const zipBase64 = await new Response(zipBlob).arrayBuffer();
      const zipBase64String = Buffer.from(zipBase64).toString('base64');

      
      let emailContent =
        `Subject: ${emailSubject}\n` +
        'MIME-Version: 1.0\n' +
        `Content-type: multipart/mixed; boundary=${boundary}\n\n` +
        `--${boundary}\n` +
        `Content-Type: text/html; charset=UTF-8\n` +
        `Content-Transfer-Encoding: quoted-printable\n\n` +
        `<html><body>${emailBody}</body></html>\n\n` +
        `--${boundary}\n` +
        `Content-Type: application/zip; name="result.zip"\n` +
        `Content-Disposition: attachment; filename="result.zip"\n` +
        `Content-Transfer-Encoding: base64\n\n` +
        `${zipBase64String}\n\n`;
        
      if (isAttachmentChecked) {
        const fileData = [
          {
            data: 'https://ict.liflo.io/link/11', //'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/%E1%84%89%E1%85%A5%E1%84%8B%E1%85%AE%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A1%E1%86%AF%E1%84%83%E1%85%A1%E1%86%AF%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5_%E1%84%80%E1%85%AD%E1%84%89%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
            label: '서울아이발달지원센터_교사용가이드',
          },
        ];

        fileData.forEach((link) => {
          emailContent +=
            `--${boundary}\n` +
            `Content-Type: text/html; charset=UTF-8\n` +
            `Content-Transfer-Encoding: quoted-printable\n\n` +
            `<p>${link.label}: <a href=3D"${link.data}">${link.data}</a></p>\n\n`;
        });

        // const pdfArrayBuffer = await fetch(teacherPdf).then((resp) => resp.arrayBuffer());
        // const unit8Array = new Uint8Array(pdfArrayBuffer);
        // const pdfBuffers = Buffer.from(unit8Array).toString('base64');
        //
        // emailContent +=
        //   `--${boundary}\n` +
        //   `Content-Type: application/pdf; name="교사용 가이드.pdf"\n` +
        //   `Content-Disposition: attachment; filename="교사용 가이드.pdf"\n` +
        //   `Content-Transfer-Encoding: base64\n\n` +
        //   `${pdfBuffers}\n\n`;
      }
      emailContent += `--${boundary}--`;
      const params = {
        Source: 'seoul-i@seoul-i.kr', // 발신자 이메일 주소
        Destinations: [email], // 수신자 이메일 주소
        RawMessage: {
          Data: emailContent,
        },
      };
*/
      const zipUrl = await makeZipFile();
      const data:any = await makeSimpleEmail(zipUrl);
    
      const params = {
        Source: 'seoul-i@seoul-i.kr', // 발신자 이메일 주소
        Destination: {
          ToAddresses: [email] //["blueton@gmail.com"] 
        },
        Message: data,
        ConfigurationSetName: 'EmailTrackingConfig'
      };
  
      
      try {
        await sendEmail(params,null);
        //await awsService.sendEmail(params);
        // await ses.sendRawEmail(params).promise();
        notificationController.success({ message: '이메일 전송에 성공하였습니다.' });
        if (selectedTaskData?.groupId) {
          updateYmGroupData(selectedTaskData.groupId, selectedTaskData.groupData, email);
        }
      } catch (err) {
        console.log(err, 'err');
        notificationController.error({ message: '이메일 전송에 실패했습니다.' });
      }

      // for (const item of emailTargetLists) {
      //   const zip = new JSZip();
      //
      //   // 각 PDF 파일을 가져와 .zip 파일에 추가합니다.
      //   for (const pdfUrl of item.pdfData) {
      //     const pdfArrayBuffer = await fetch(pdfUrl).then((resp) => resp.arrayBuffer());
      //     zip.file(`${item.name}.pdf`, pdfArrayBuffer);
      //   }
      //   const zipFileName = `${item.name} 결과보고서.zip`; // 압축 파일 이름
      //
      //   // .zip 파일을 생성합니다.
      //   const zipBlob = await zip.generateAsync({ type: 'blob' });
      //
      //   // .zip 파일을 base64로 인코딩합니다.
      //   const zipBase64 = await new Response(zipBlob).arrayBuffer();
      //   const zipBase64String = Buffer.from(zipBase64).toString('base64');
      //
      //   // 이메일 본문 생성
      //   const emailSubject = '결과 보고서입니다.';
      //   const emailBody = '결과 보고서를 확인해주세요.';
      //   const boundary = 'boundary-example';
      //
      //   const emailContent =
      //     `Subject: ${emailSubject}\n` +
      //     'MIME-Version: 1.0\n' +
      //     `Content-type: multipart/mixed; boundary=${boundary}\n\n` +
      //     `--${boundary}\n` +
      //     `Content-Type: text/plain; charset=UTF-8\n` +
      //     `Content-Transfer-Encoding: quoted-printable\n\n` +
      //     `${emailBody}\n\n` +
      //     `--${boundary}\n` +
      //     `Content-Type: application/zip; name="${zipFileName}"\n` +
      //     `Content-Disposition: attachment; filename="${zipFileName}"\n` +
      //     `Content-Transfer-Encoding: base64\n\n` +
      //     `${zipBase64String}` +
      //     `--${boundary}--`;
      //
      //   const params = {
      //     Source: 'mbisqinc@gmail.com', // 발신자 이메일 주소
      //     Destinations: [email], // 수신자 이메일 주소
      //     RawMessage: {
      //       Data: emailContent,
      //     },
      //   };
      //
      //   await ses.sendRawEmail(params).promise();
      //   notificationController.success({ message: '이메일 전송에 성공하였습니다.' });
      //
      //   console.log(`${item.name}에 대한 이메일 전송 성공`);
      // }
    } catch (error) {
      console.error('이메일 전송 중 오류 발생:', error);
      notificationController.error({ message: '이메일 전송 중 에러가 발생했습니다. 다시 시도해주세요. ' });
    } finally {
      setSendEmailLoading(false);
    }
  };

  const cancelEmail = async (key:any) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    const apiServer = 'https://ict.mbisq.com'; // 
    //const apiServer = 'http://localhost:8787'; // 
    await fetch(apiServer+'/popMail/'+key, requestOptions)
        .then(response => response.json())
        .then(data => console.log('delete Reserve:'+data));
    setReserve('');
    //updateTaskForMail('');    
    setEmailReserveLoading(false);
    return key;    
  }

  const reserveEmail = async (data:any) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    //console.log('write userData:'+requestOptions.body)
    const key = (new Date()).getTime();
    const apiServer = 'https://ict.mbisq.com'; // 
    //const apiServer = 'http://localhost:8787'; // 
    await fetch(apiServer+'/putMail/'+key, requestOptions)
        .then(response => response.json())
        .then(data => console.log('pu Reserve:'+data));
    return key;    
  }

  const reserveEmailWithAttachment = async () => {

    const zipUrl = await makeZipFile();
    const data = await makeSimpleEmail(zipUrl);
    const params = {
      Source: 'seoul-i@seoul-i.kr', // 발신자 이메일 주소
      Destination: {
        ToAddresses: [email] //["blueton@gmail.com"] 
      },
      Message: data,
      ConfigurationSetName: 'EmailTrackingConfig'
    };
  
    try {
      const key = ''+await reserveEmail(params);
      setReserve(key);
      //await awsService.sendEmail(params);
      // await ses.sendRawEmail(params).promise();
      //updateTaskForMail(key);
      // onChangeData('init');
      setEmailReserveLoading(false);
    } catch (err) {
      notificationController.error({ message: '이메일 전송 예약중 에러가 발생했습니다. ' });
      setEmailReserveLoading(false);
      console.log(err, 'err');
      // alert('에러가 발생했습니다.');
    }
  };
  async function compressAndDownloadPDFs() {
    try {
      setLoading(true);
      const zip = new JSZip();

      let count = 1;
      for (const item of emailTargetLists) {
        const pdfDoc = await PDFDocument.create();
        console.log(item, 'item');
        // 각 PDF 파일을 가져와 합칩니다.
        for (const pdfUrl of item.pdfData) {
          const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
          const externalPdfDoc = await PDFDocument.load(pdfBytes);
          const copiedPages = await pdfDoc.copyPages(externalPdfDoc, externalPdfDoc.getPageIndices());
          copiedPages.forEach((page) => pdfDoc.addPage(page));
        }

        // 새로운 PDF를 생성합니다.
        const mergedPdfBytes = await pdfDoc.save();

        // .zip 파일에 PDF를 추가합니다.
        zip.file(`${count}.${item.name}.pdf`, mergedPdfBytes);
        count++;
      }

      // .zip 파일을 생성하고 다운로드합니다.
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const url = window.URL.createObjectURL(zipBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'final_report.zip';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('PDF 압축 및 다운로드 중 오류 발생:', error);
      notificationController.error({ message: 'pdf 압축중 에러가 발생했습니다. ' });
    } finally {
      setLoading(false);
    }
  }

  const onDownLoadTargetPdf = () => {
    if (!emailTargetLists.length) {
      notificationController.warning({ message: '선택된 아이들이 없습니다.' });
      return;
    }
    compressAndDownloadPDFs();
  };

  const getTargetDataById = async (targets: TargetData[]) => {
    console.log(targets, 'targets');

    const results = await Promise.all(
      targets.map((target) => {
        return getAssessmentByTargetId({
          variables: { tgid: target.id, category: 'assess_1,assess_2' },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });
      }),
    );

    const assessmentLists = null;

    const targetPdfData: TargetPdfData[] = [];

    results.forEach((targetInfo) => {
       if (
        targetInfo?.data?.getYMAssessmentsByTargetId?.success &&
        targetInfo?.data?.getYMAssessmentsByTargetId?.tasks?.length
      ) {
        const resultArray: any[] = [];

        targetInfo?.data?.getYMAssessmentsByTargetId?.tasks.forEach((task: TaskData) => {
          const newTask = { ...task };
          newTask.data =
            newTask?.data === '' ? {} : typeof newTask?.data === 'string' ? JSON.parse(newTask?.data) : newTask?.data;

          console.log(newTask, 'newTask');

          const taskData = newTask.data;
          const targetData =
            typeof newTask?.target?.data === 'string' ? JSON.parse(newTask?.target?.data) : newTask?.target?.data;

          if ((newTask.status === 2 || newTask.status === 9) && taskData.pdfFileUrl) {
            const targetName = newTask?.target?.title;
            let existingItem = resultArray.find((resultItem) => resultItem.name === targetName);

            if (existingItem) {
              const fileUrl = taskData?.pdfGroupFileUrl ?? taskData.pdfFileUrl;
              existingItem.pdfData.push(fileUrl);
            } else {
              // 새로운 이름인 경우
              console.log(newTask, 'tasktask');
              const fileUrl = taskData?.pdfGroupFileUrl ?? taskData.pdfFileUrl;
              existingItem = {
                taskId: newTask?.id,
                name: targetName,
                pdfData: [fileUrl],
                targetId: newTask?.target?.id,
                privacyAgreed: 1, //targetData?.privacyAgreed ?? 0,
              };
              resultArray.push(existingItem);
            }
          }
        });
        targetPdfData.push(resultArray[0]);
        console.log(resultArray, 'resultArray');
      }
    });

    const agreedData = targetPdfData.filter((target) => target?.privacyAgreed === 1);
    const disAgreedData = targetPdfData.filter((target) => target?.privacyAgreed === 0);

    console.log(agreedData, disAgreedData, targetPdfData, 'targetPdfData');

    //setTargetPdfLists(targetPdfData);
    setAgreementTargets(agreedData);
    setEmailTargetLists(agreedData);
    setDisagreementTargets(disAgreedData);
  };

  useEffect(() => {
    setEmail('');
    setReserve('');
  },[selectedTaskData?.groupName]);
  
  useEffect(() => {
    if (data?.getYMGroup.success && open) {
      const targets = get(data, 'getYMGroup.group.targets', []) as TargetData[];
      getTargetDataById(targets);
    }
  }, [data, open]);

  console.log(selectedTaskData?.groupMailHistories, 'history');

  return (
    <S.ModalWrapper title={title} open={open} onCancel={onCancel} width={width} footer={null}>
      <S.Desc>
        기관의 전체 채점 결과를 압축 파일로 저장하거나 이메일 발송을 할 수 있습니다. 
        기관 정보 제공에 동의를 한 대상자만 압축하여 저장 혹은 발송할 수 있지만 아래 목록에서 대상자를 추가 선택 혹은 선택해제 할 수 있습니다.
      </S.Desc>

      <S.BoxWrapper>
        <S.GroupTag>
          {selectedTaskData?.region} ・ {selectedTaskData?.groupName}
        </S.GroupTag>
        <S.Desc>기관 정보 제공에 동의한 대상자입니다.</S.Desc>
        <S.CheckboxContainer isBorderBottom={true}>
          {agreementTargets.map((value) => (
            <S.CustomCheckbox
              key={value.taskId}
              onChange={() => addDisAgreedTarget(value)}
              checked={!!value.privacyAgreed}
            >
              {value.name}
            </S.CustomCheckbox>
          ))}
          {/*{targetsData.map(({ id, name, isChecked }) => (*/}
          {/*  <S.CustomCheckbox key={id} onChange={() => handleCheckboxChange(id)} checked={isChecked}>*/}
          {/*    {name}*/}
          {/*  </S.CustomCheckbox>*/}
          {/*))}*/}
        </S.CheckboxContainer>
        <S.Desc>기관 정보 제공에 동의하지 않았거나 동의 정보가 없는 대상자입니다.</S.Desc>

        <S.CheckboxContainer isBorderBottom={false}>
          {disagreementTargets.map((value) => (
            <S.CustomCheckbox
              key={value.taskId}
              onChange={() => addAgreedTarget(value)}
              checked={!!value.privacyAgreed}
            >
              {value.name}
            </S.CustomCheckbox>
          ))}
        </S.CheckboxContainer>
        {/*<S.CountContainer>선택 20 / 총 28</S.CountContainer>*/}
      </S.BoxWrapper>

      <S.DownloadButton onClick={onDownLoadTargetPdf}>
        {loading ? 'PDF 압축파일을 생성중입니다...' : '결과 압축 파일 다운로드'}
      </S.DownloadButton>
      <S.AttachmentCheckBox
        checked={isAttachmentChecked}
        onChange={() => {
          setIsAttachmentChecked(!isAttachmentChecked);
        }}
      >
        서울아이발달지원센터_교사용가이드
      </S.AttachmentCheckBox>
      <S.EmailFloatLabel error={!!error}>
        <FloatLabel label="받는 사람" name="select" required>
          <BaseInput
            onChange={handleEmailChange}
            placeholder="받는 사람의 이메일 주소를 입력해 주세요."
            required
            autoComplete="off"
            value={email}
          />
        </FloatLabel>

        {error && <div className="error">{error}</div>}
      </S.EmailFloatLabel>
      <S.EmailHistoryLists>
        <div className="title">이메일 발송 히스토리 (최근 2건 표시)</div>
        <ul className="history-lists">
          {!selectedTaskData?.groupMailHistories?.length ? (
            <div className="text">발송 이력 없음</div>
          ) : (
            selectedTaskData?.groupMailHistories?.slice(0, 2)?.map((item, index) => (
              <li key={index}>
                <span className="time">{item?.timestamp ? Dates.format(item?.timestamp, 'YY.MM.DD HH:mm') : null}</span>
                <span className="email">{item?.to}</span>
              </li>
            ))
          )}
        </ul>
      </S.EmailHistoryLists>

      <S.FooterWrapper>
            {true&&<S.SendEmailButton
              disabled={(!!error || !email) && reserve === ''}
              onClick={() => {
                if (email && !error && reserve === '') {
                  setEmailReserveLoading(true);
                  reserveEmailWithAttachment();
                }
                else if(reserve !== ''){
                  setEmailReserveLoading(true);
                  cancelEmail(reserve);
                }
              }}
            >
            {emailReserveLoading ? (reserve!==''? '예약 취소 중...' :'예약 등록 중...') : (reserve!==''? '예약중입니다. 취소하려면 클릭하세요.':'이메일 예약 발송 (돌아오는 월요일에 발송됩니다.)')}
            </S.SendEmailButton>}

      <S.SendEmailButton
        disabled={!!error || !email}
        onClick={() => {
          if (!error && email) {
            if (!emailTargetLists.length) {
              notificationController.warning({ message: '선택된 아이들이 없습니다.' });
              return;
            }
            sendEmailWithAttachment();
          }
        }}
      >
        {sendEmailLoading ? '발송 중...' : '이메일 바로 발송'}
      </S.SendEmailButton>
      </S.FooterWrapper>
    </S.ModalWrapper>
  );
}
