import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Divider, Radio, Select } from 'antd';
import FloatLabel from '@app/components/common/FloatLabel/FloatLabel';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { ymaDatas, ymDatas } from '@app/hocs/cache';
import dayjs from 'dayjs';
import { getExamMonthFromReserved, getFormatedAge, getMonth, isLocal, regions } from '@app/constants/YMData';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { createYMGroupGQL, getYMGroupListGQL, updateYMTargetGQL } from '@app/hocs/gql';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const RegistrationForm = (props: any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const ymData = useReactiveVar(ymDatas);
  const ymaData = useReactiveVar(ymaDatas);

  const [tgName, setTgName] = useState('');
  const [groupRegion, setGroupRegion] = useState('T');

  const [groupData, setGroupData] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [groupCode, setGroupCode] = useState('');
  const [groupName, setGroupName] = useState('');
  const [sex, setSex] = useState(1);
  const [epWeek, setEpWeek] = useState(40);
  const [epDay, setEpDay] = useState(0);
  const [bDate, setBDate] = useState(0);
  const [bMonth, setBMonth] = useState(0);
  const [rDate, setRDate] = useState(0);
  const [privacyAgreed, setPrivacyAgreed] = useState(1);

  const [getYMGroupListHandler, getYMGroupListRequest] = useLazyQuery(getYMGroupListGQL, { onCompleted: (data) => {} });
  const getGroups = async (idx: any) => {
    /*
    const apiServer = 'https://fg.liflo.io'; // 
    //if( isLocal() ) apiServer = 'http://localhost:3006'; // 'https://m5.liflo.io'
  
    const url = apiServer + '/data/'+'seouli_'+idx+'.json';
    
    const response = await fetch(url);
    const data = await response.json();
    setGroupData(data['region']);
    */
    getYMGroupListHandler({
      variables: { code: idx },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      // pollInterval: 500
    })
      .then((result) => {
        const {
          data: {
            getYMGroupList: { success, id, data, idx },
          },
        } = result;
        if (data === null) return;
        setGroupData(JSON.parse(data));
      })
      .catch((err) => {});
  };

  const findGroup = (str: any) => {
    if (groupData !== null) {
      setGroupList(groupData.filter((group) => group.name.includes(str)));
    }
  };

  useEffect(() => {
    console.log('******************* RegistrationForm useEffect:' + ymData.update + ':' + ymData.modal);
    //if( update !== ymData.tg.update){
    setTgName(ymData.tg.data.name);
    setBDate(ymData.tg.data.bdate);
    setRDate(parseInt(ymData.tg.reservedAt));
    console.log(ymData.tg.data.privacyAgreed, 'privacyAgreed');
    setPrivacyAgreed(ymData.tg.data?.privacyAgreed ?? 1);
    setEpWeek(Number(ymData.tg.data.epweek));
    setEpDay(Number(ymData.tg.data.epday));
    setGroupList([]);
    setGroupCode(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code);
    setGroupRegion(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code.substring(0, 1));

    const day = dayjs(new Date().getTime()).format('YYMMDD');
    ymData.tg.data.uid = 'FT' + day + '##';

    if (ymData.tg.group === undefined) setGroupName('');
    else {
      const gData = ymData.tg.group.data;
      setGroupName(gData.name);
    }
    getGroups(ymData.tg.group === undefined ? 'T' : ymData.tg.group.code.substring(0, 1));
    findGroup('*');
    setSex(ymData.tg.data.sex);
    if (ymData.tg.data.bmonth === 0 || ymData.tg.data.bmonth === undefined ) setBMonth(getMonth(ymData.tg.data.bdate));
    else setBMonth(ymData.tg.data.bmonth);
    //  setUpdate(ymData.tg.update);
    //}
  }, [ymData.update]); //reservedAt]);

  const onClickGroupItem = (groupCode: any, groupName: any) => {
    setGroupCode(groupCode);
    setGroupName(groupName);
  };

  const onFinish = async (values = {}) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(true);
      //notificationController.success({ message: t('common.success') });
    }, 100);
  };
  const [createYMGroup, createYMGroupRequest] = useMutation(createYMGroupGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.createYMGroup.success === true) {
        //console.log('createYMGroup - data:'+data.createYMGroup.data)
        // create target
      } else {
        console.log(data.updateYMTarget.error);
      }
    },
  });

  const [updateYMTarget, updateYMTargetRequest] = useMutation(updateYMTargetGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMTarget.success === true) {
      } else {
        console.log(data.updateYMTarget.error);
      }
    },
  });
  const doOk = () => {
    console.log('ymData:' + ymData.tg.data.uid.substring(8, 10));

    if (!tgName || !groupName) {
      notificationController.warning({ message: '필수입력요소를 다 입력해주세요.' });
      return;
    }

    let gCode = groupCode;
    if( rDate > new Date(2024,3,1).getTime() && gCode.length >= 5 )
      gCode = gCode.substring(0,5)+'1';

    if (ymData.tg.data.uid.substring(8, 10) === '##' && !props.isTargetUpdate) {
      ymDatas({
        ...ymData,
        tg: {
          ...ymData.tg,
          data: {
            ...ymData.tg.data,
            bdate: bDate,
            bmonth: bMonth,
            name: tgName,
            sex: sex,
            epWeek: epWeek,
            epDay: epDay,
            privacyAgreed,
          },
          reservedAt: rDate,
        },
        gregion: groupRegion,
        gcode: gCode,
        gname: groupName,
      }); // update cache
      props.onOk(true);
    } else {
      const today = new Date();
      const owner = ymaData.eqid;
      createYMGroup({
        variables: {
          input: {
            code: gCode,
            title: groupRegion + '-' + groupName,
            category: 'ym_exam',
            owner: owner,
            reservedAt: today,
            data: JSON.stringify({
              region: regions[groupRegion],
              code: groupRegion,
              name: groupName,
              date: 1,
            }),
          },
        },
      })
        .then((result) => {
          const {
            data: {
              createYMGroup: { success, data, id: gid },
            },
          } = result;
          const targetData = {
            ...ymData.tg.data,
            bdate: bDate,
            bmonth: bMonth,
            name: tgName,
            sex: sex,
            epweek: epWeek,
            epday: epDay,
            privacyAgreed,
          };
          updateYMTarget({
            variables: {
              id: ymData.tg.id,
              input: {
                title: tgName,
                group: gid,
                data: JSON.stringify(targetData),
                reservedAt: String(rDate),
              },
            },
          });
          setTimeout(() => {
            props.updateExaminer();
            notificationController.success({ message: '수정했습니다.' });
          }, 700);
        })
        .catch((err) => {
          //console.log('login error',createUserError)
          //d console.log(err)
        });
      ymDatas({
        ...ymData,
        tg: {
          ...ymData.tg,
          title: tgName,
          data: {
            ...ymData.tg.data,
            bdate: bDate,
            bmonth: bMonth,
            name: tgName,
            sex: sex,
            epWeek: epWeek,
            epDay: epDay,
            privacyAgreed,
          },
          reservedAt: rDate,
        },
        gregion: groupRegion,
        gcode: gCode,
        gname: groupName,
      }); // update cache
      // setTimeout(() => {
      //   props.onOk(false);
      // }, 1000);
    }
  };

  const menus: any = [];

  Object.keys(regions).forEach(async function (key) {
    menus.push(key);
  });

  //console.log('groupData', tgName, ymData.tg?.status, privacyAgreed);

  return (
    <BaseButtonsForm
      {...formItemLayout}
      layout="inline"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <BaseButtonsForm.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <BaseSpace align="end">
            <BaseButton type="primary" loading={isLoading} onClick={props.onCancel}>
              취소
            </BaseButton>
            <BaseButton type="primary" loading={isLoading} onClick={doOk}>
              {props.isTargetUpdate ? '저장하기' : '등록하기'}
            </BaseButton>
          </BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }} style={{ width: '100%' }}>
        <BaseCol span={11.5}>
          <FloatLabel label="고유번호" desc="아동의 고유번호는 자동 생성됩니다." name="select" required width="23rem">
            <BaseInput required value={ymData.tg.data.uid} disabled={props.isTargetUpdate} />
          </FloatLabel>
          <FloatLabel label="아동이름" desc="아동의 이름을 입력해주세요." name="select" required width="23rem">
            <BaseInput
              required
              value={tgName}
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTgName(event.target.value);
                //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, name:event.target.value}}}); // update cache
              }}
            />
          </FloatLabel>
          <FloatLabel label="성별" desc="아동의 성별을 선택해주세요." name="select" required width="10rem">
            <div style={{ marginTop: '10px', padding: '7px', border: '1px solid #999a', borderRadius: 7 }}>
              <Radio.Group
                value={'' + sex}
                onChange={(e: any) => {
                  setSex(parseInt(e.target.value));
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, sex:parseInt(e.target.value)}}}); // update cache
                }}
              >
                <Radio value="1">여</Radio>
                <Radio value="2">남</Radio>
              </Radio.Group>
            </div>
          </FloatLabel>
          <FloatLabel
            label="생년월일"
            desc="아동의 생년월일을 선택해주세요.(만나이는 자동 계산 됩니다.)"
            name="select"
            required
          >
            <BaseDatePicker
              format="YYYY년 MM월 DD일"
              value={dayjs(new Date(bDate))}
              allowClear={false}
              onChange={(date: any, dateString) => {
                const bdate = date.toDate().getTime();

                setBDate(bdate);
                setBMonth(getMonth(bdate));

                if (typeof bdate === 'number') {
                  const aData = rDate - bdate;
                  const millisecondsInMonth = 30.4375 * 24 * 60 * 60 * 1000; // 평균 월 길이를 기준으로 밀리초를 계산합니다.
                  const months = Math.floor(aData / millisecondsInMonth); // 밀리초를 개월로 변환합니다.

                  const data =
                    getMonth(bdate) / 12 > 3
                      ? `${(getMonth(bdate) / 12) | 0}세 ${getMonth(bdate) % 12}개월`
                      : `${getMonth(bdate)}개월`;
                  console.log(data, 'data');
                }

                //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, bdate:bdate, bmonth:getMonth(bdate)}}}); // update cache
              }}
            />
            <span style={{ marginLeft: 10 }}>
              만 {(bMonth / 12) | 0}세 {bMonth % 12}개월
            </span>
          </FloatLabel>
          <FloatLabel label="조산여부" desc="조산의 경우 기간을 입력해주세요." name="select" required width="16rem">
            <div style={{ marginTop: '10px', padding: '7px', border: '1px solid #999a', borderRadius: 7 }}>
              임신{' '}
              <Select
                defaultValue="37"
                size="small"
                value={'' + epWeek}
                bordered={false}
                style={{ width: 65, marginLeft: 15, backgroundColor: 'white' }}
                onChange={(value: any) => {
                  setEpWeek(parseInt(value));
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, epweek:parseInt(e.target.value)}}}); // update cache
                }}
                options={[
                  { value: '20', label: '20' },
                  { value: '21', label: '21' },
                  { value: '22', label: '22' },
                  { value: '23', label: '23' },
                  { value: '24', label: '24' },
                  { value: '25', label: '25' },
                  { value: '26', label: '26' },
                  { value: '27', label: '27' },
                  { value: '28', label: '28' },
                  { value: '29', label: '29' },
                  { value: '30', label: '30' },
                  { value: '31', label: '31' },
                  { value: '32', label: '32' },
                  { value: '33', label: '33' },
                  { value: '34', label: '34' },
                  { value: '35', label: '35' },
                  { value: '36', label: '36' },
                  { value: '37', label: '37' },
                  { value: '38', label: '38' },
                  { value: '39', label: '39' },
                  { value: '40', label: '40' },
                ]}
              />
              주
              <Select
                defaultValue="0"
                value={'' + epDay}
                size="small"
                bordered={false}
                style={{ width: 60, marginLeft: 15 }}
                onChange={(value: any) => {
                  setEpDay(parseInt(value));
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, epweek:parseInt(e.target.value)}}}); // update cache
                }}
                options={[
                  { value: '0', label: '0' },
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                  { value: '6', label: '6' },
                ]}
              />
              일 
            </div>
          </FloatLabel>
        </BaseCol>
        <BaseCol span={1}>
          <Divider type="vertical" style={{ height: '450px' }} />
        </BaseCol>
        <BaseCol span={11.5}>
          <FloatLabel label="검사일" desc="아동의 검사일을 선택해주세요." name="select" required width="23rem">
            <div
              style={{
                marginTop: '0px',
                padding: '0px',
                paddingLeft: '7px',
                border: '0px solid #999a',
                borderRadius: 7,
                width: '100%',
              }}
            >
              <BaseDatePicker
                format="YYYY년 MM월 DD일"
                value={dayjs(new Date(rDate))}
                disabled={props.isTargetUpdate && ymData?.tg?.status === 2}
                allowClear={false}
                onChange={(date: any, dateString) => {
                  const rdate = date.toDate().getTime();
                  setRDate(rdate);
                  ymData.tg.data.uid =
                    ymData.tg.data.uid.substring(0, 2) +
                    dateString.substring(2, 4) +
                    dateString.substring(6, 8) +
                    dateString.substring(10, 12) +
                    '##';
                  
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, bdate:bdate, bmonth:getMonth(bdate)}}}); // update cache
                }}
              />
            </div> 
          </FloatLabel>
          <FloatLabel label="" desc="" name="select" width="23rem">
          <div style={{ marginTop: 0, marginLeft:5, padding: '7px', border: '1px solid #999a', borderRadius: 7, width: '60%' }}>
          검사 월수 {getFormatedAge(getExamMonthFromReserved(bDate, epWeek, epDay, rDate),1)}
          </div>
          </FloatLabel>
          <Divider style={{ marginTop: -15, marginBottom: 0, border:'#aaa 0.5px solid' }} />
          <FloatLabel label="지역 - 구이름" desc="" name="select">
            <div style={{ marginTop: 16, padding: '7px', border: '0px solid #999a', borderRadius: 7, width: '100%' }}>
              <BaseSelect
                size="small"
                id="region"
                value={groupRegion}
                onChange={(value: any) => {
                  setGroupRegion(value);
                  setGroupName('');
                  findGroup('*');
                  getGroups(value);
                  ymData.tg.data.uid = 'F' + value + ymData.tg.data.uid.substring(2, 8) + '##';
                }}
                style={{ width: '100%' }}
              >
                {menus.map((key: any, idx: number) => (
                  <Option key={idx} value={key}>
                    {regions[key]}
                  </Option>
                ))}
              </BaseSelect>
            </div>
          </FloatLabel>
          <FloatLabel label="소속기관" desc="아동이 소속한 기관을 검색해주세요." name="select" required>
            <div style={{ marginTop: -20, padding: '7px', border: '0px solid #999a', borderRadius: 7, width: '100%' }}>
              <BaseInput
                required
                id="groupName"
                value={groupName}
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGroupName(event.target.value);
                  findGroup(event.target.value);
                }}
                size="small"
              />
            </div>
          </FloatLabel>
          <BaseList
            size="small"
            style={{
              marginTop: -5,
              borderRadius: 1,
              border: '#aaa 0.5px solid',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 250,
            }}
          >
            {groupList.map((group, idx) => (
              <BaseList.Item
                key={idx}
                onClick={(event: any) => {
                  onClickGroupItem(group.id, group.name);
                  setGroupCode(group.id);
                  setGroupName(group.name);
                  //ymDatas({...ymData, gregion: groupRegion, gcode:group.id, gname:group.name }); // update cache
                }}
              >
                {group.name}
              </BaseList.Item>
            ))}
          </BaseList>

          <FloatLabel
            label="결과"
            desc="기관에 정보를 제공하는 것에 동의 한 경우에만 결과 제공이 됩니다."
            name="select"
            required width="22rem"
          >
            <div
              style={{
                marginTop: '30px',
                marginLeft:5,
                marginBottom: '10px',
                padding: '7px',
                border: '1px solid #999a',
                borderRadius: 7,
              }}
            >
              <Radio.Group
                value={'' + privacyAgreed}
                onChange={(e: any) => {
                  setPrivacyAgreed(parseInt(e.target.value));
                  //ymDatas({...ymData, tg:{...ymData.tg, data:{...ymData.tg.data, sex:parseInt(e.target.value)}}}); // update cache
                }}
              >
                <Radio value="1">동의</Radio>
                <Radio value="0">비동의</Radio>
              </Radio.Group>
            </div>
          </FloatLabel>
        </BaseCol>
      </BaseRow>
    </BaseButtonsForm>
  );
};
