import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 20px;
  }
  .error {
    position: relative;
    top: -23px;
    font-size: 12px !important;
    color: #c50000;
    font-weight: normal;
  }
  .form-error {
    border: 1px solid #c50000 !important;
  }

  .ant-modal-body {
    //padding: 40px 44px !important;
  }

  .ant-modal-close-x {
    font-size: 24px !important;
    color: #000;
  }

  .ant-modal-title {
    font-size: 36px;
    color: #212529;
    font-weight: 500;
  }
  .ant-modal-close {
    top: 37px;
    right: 20px;
  }
  .ant-modal-header {
    border-bottom: none !important;
    padding-top: 40px;
  }

  .profile-img-area {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #556aeb;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 32px;
    > span {
      cursor: pointer;
    }

    .profile-img {
      object-fit: cover;
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }

    .profile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: #d9d9d9;
      position: relative;
      > .empty {
        object-fit: cover;
        align-items: center;
        justify-content: center;
        position: absolute;
        display: inline-block;
        transform: translate(20%, 32%);
      }
    }

    height: 150px;
  }

  .footer {
    width: 100%;
    .left-area {
      color: #495057;
      font-size: 14px;
    }

    .button-group {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      .default-button {
        border-radius: 8px;
        background: var(--neutral-200, #e9ecef);
        margin-right: 15px;
        padding: 8px 16px;
        color: #495057;
        border: none;
        cursor: pointer;
      }

      .active-button {
        padding: 8px 16px;
        border-radius: 8px;
        background: var(--primary-400, #6e82fe);
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }

    .delete {
      cursor: pointer;
      color: #c50000;
      text-decoration-line: underline;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;
