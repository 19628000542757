import React, { useEffect, useState } from 'react';
import { getExaminersData, Examiner } from '@app/api/examiners.api';
import { TreatmentDoctor } from './TreatmentDoctor/TreatmentDoctor';
import { specifities } from '../../../constants/specifities';
import { TreatmentNotFound } from './TreatmentNotFound/TreatmentNotFound';
import { ScheduleEvent } from '@app/api/schedule.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { RoundButton } from '@app/components/common/RoundButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ymDatas } from '@app/hocs/cache';

interface AppointmentPanelProps {
  event?: ScheduleEvent;
  setExaminer: (state: Examiner) => void;
  setExaminerClicked: (state: boolean) => void;
}

export const AppointmentPanel: React.FC<AppointmentPanelProps> = ({ event, setExaminer, setExaminerClicked }) => {
  const [examiners, setExaminers] = useState<Examiner[]>([]);
  const [timetable, setTimetable] = useState<any[]>([]);
  const timetableNew = [
    {id:1,text:'08:00',checked:false}, {id:2,text:'08:30',checked:false},
    {id:3,text:'09:00',checked:false},{id:4,text:'09:30',checked:false},
    {id:5,text:'10:00',checked:false},{id:6,text:'10:30',checked:false},
    {id:7,text:'11:00',checked:false},{id:8,text:'11:30',checked:false},
    {id:9,text:'12:00',checked:false},{id:10,text:'12:30',checked:false},
    {id:11,text:'01:00',checked:false},{id:12,text:'01:30',checked:false},
    {id:13,text:'02:00',checked:false},{id:14,text:'02:30',checked:false},
    {id:15,text:'03:00',checked:false},{id:16,text:'03:30',checked:false},
    {id:17,text:'04:00',checked:false},{id:18,text:'04:30',checked:false},
    {id:19,text:'05:00',checked:false},{id:20,text:'05:30',checked:false},
    {id:21,text:'06:00',checked:false},{id:22,text:'06:30',checked:false},
    {id:23,text:'07:00',checked:false},{id:24,text:'07:30',checked:false},
    {id:25,text:'새벽',checked:false},{id:26,text:'밤',checked:false},
  ]

  useEffect(() => {
    //getExaminersData().then((res) => setExaminers(res));
  }, []);

  useEffect(() => {
    const before = new Date(parseInt(ymDatas().tg.reservedAt))
    const hour = before.getHours();
    const min = before.getMinutes();
    const idx = (hour-8)*2 + (min === 30 ? 1:0);
    if( idx >= 0 && idx < 24 )
      timetableNew[idx].checked = true;
    else if(idx < 0 ) timetableNew[24].checked = true;
    else timetableNew[25].checked = true;

    console.log(hour +':'+min+':'+idx);
    setTimetable(timetableNew);
  },[ymDatas().tg]);

  const setReservedTime = (id:any) => {
    timetableNew[id-1].checked = true;
    const hour = Math.floor((id-1)/2) + 8;
    const min = ((id-1)%2) * 30;
    const reservedAt = new Date(parseInt(ymDatas().tg.reservedAt));
    reservedAt.setHours(hour);
    reservedAt.setMinutes(min);
  
    console.log('setReservedTime:'+reservedAt);
    ymDatas().tg.reservedAt = reservedAt.getTime();
    ymDatas({...ymDatas(), tg:ymDatas().tg}); // update cache
    console.log('setReservedTime:'+ymDatas().tg.reservedAt);
    setTimetable(timetableNew);
  }

  const thisday = 170;
  const foundDay = ( days:any) => { return (days.find((element:any) => element === thisday) === undefined)};


  return (
      <BaseRow  gutter={[0, 10]}>
      <BaseCol span={24}>오전</BaseCol>
      { timetable.map((v:any,i:any)=>{
        if( v.id >= 3 && v.id < 9 )
          return <BaseCol key={i} span={8}>
            <BaseButton style={{width:'90%'}} type={ v.checked ? "primary":"ghost"} size="small" onClick={() => {setReservedTime(v.id)}}>{v.text}</BaseButton>
          </BaseCol>

      })}
      <BaseCol span={24}>오후</BaseCol>
      { timetable.map((v:any,i:any)=>{
        if( v.id >= 11 && v.id < 21 )
          return <BaseCol key={i} span={8}>
            <BaseButton style={{width:'90%'}} type={ v.checked ? "primary":"ghost"} size="small" onClick={() => {setReservedTime(v.id)}}>{v.text}</BaseButton>
          </BaseCol>

      })}
    </BaseRow>
    )
  /*
  const currentExaminer = examiners.find((examiner) => examiner.id === event?.examiner);

  if (event && currentExaminer) {
    const doctor: TreatmentDoctor = {
      name: currentExaminer.name,
      address: currentExaminer.address,
      date: event.date,
      imgUrl: currentExaminer.imgUrl,
      phone: currentExaminer.phone,
      speciality: specifities.find(({ id }) => id === currentExaminer.specifity)?.name || '',
    };

    return <TreatmentDoctor doctor={doctor} />;
  } else {
    return <TreatmentNotFound />;
  }
  */
};
