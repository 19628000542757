import styled from 'styled-components';
import { Modal } from 'antd';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-body {
    padding-bottom: 10px !important;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  padding: 20px;

  text-align: center;
`;

export const Title = styled.div`
  display: flex;
  gap: 10px;
  font-weight: ${FONT_WEIGHT.medium};
  align-items: center;
  justify-content: center;
`;

export const ContentBox = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: #f8f9fa;
  margin-top: 32px;
  p {
    margin-top: 10px;
  }
`;

export const OtherTitle = styled.div`
  font-size: 36px;
  color: #212529;
  font-weight: 500;
  font-family: Noto Sans KR;
`;

export const ResultWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--neutral-600, #6c757d);
  background: var(--neutral-100, #f8f9fa);
  padding: 32px;

  margin: 32px 0;
`;

export const Result = styled.div<{ background: string }>`
  display: flex;
  gap: 32px 0;
  align-items: center;
  color: #495057;
  > .result-area {
    display: flex;
    align-items: center;
  }
  .result-status {
    margin-left: 32px;

    padding: 8px 32px;
    border-radius: 30px;
    align-items: center;
    gap: 20px;
    border: 1px solid var(--neutral-400, #ced4da);
    background: var(--White, #fff);
    position: relative;
    display: flex;
    align-items: center;
  }
  .status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    //background: #c50000;
    background-color: ${({ background }) => background};
  }
`;

export const ResultDesc = styled.p`
  margin-top: 16px;
  text-align: left;
`;

export const PDFWrapper = styled.div`
  position: relative;

  margin-bottom: 32px;
  display: flex;
  gap: 24px;
  font-size: 16px;
  min-height: 210px;
  .title {
    text-align: left;
  }
  .pdf-area {
    cursor: pointer;
    padding: 5px;
    border: 2px solid #6c757d;
    max-width: 468px;
    display: flex;
    margin-top: 20px;
    > img {
      width: 150px;
      height: 230px;
      object-fit: cover;
    }
  }
  .download-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  gap: 16px;

  > img {
    cursor: pointer;
  }
`;

export const SelectGrowthWrapper = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 400;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }

  .select-growth-pdf {
    margin-top: 8px;
    margin-bottom: 24px;
    border-radius: 16px;
    background: var(--neutral-100, #f8f9fa);
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const EmailFloatLabel = styled.div<{ error: boolean }>`
  margin: 16px 0 16px 0;
  position: relative;
  .file-wrapper {
    margin-left: 10px;
    display: inline-flex;
    flex-direction: column;
  }
  .file-name {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
  }
  > span {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;

    position: absolute;
    right: 14px;
    bottom: 50px;
    z-index: 100000;
  }

  .input {
    display: flex;
    flex-wrap: wrap; /* 줄 바꿈 설정 */

    > img {
      margin-left: 10px;
      cursor: pointer;
    }

    //margin-bottom: 16px;
  }

  .float-label {
    width: 90%;
    margin-bottom: 0px !important;
  }
  .label {
    color: ${({ error }) => (error ? '#C50000' : '#6C757D')} !important;
  }
  .error {
    //padding: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #c50000;
    text-align: left;
  }
  input {
    border: ${({ error }) => (error ? '1px solid #C50000' : '1px solid #495057')} !important;
  }
`;

export const BaseButton = styled.button<{ disabled?: boolean }>`
  padding: 8px 16px;
  width: 100%;
  border-radius: 8px;
  //border: 1px solid var(--primary-400, #6e82fe);
  border: ${({ disabled }) => (disabled ? 'none' : '1px solid  #6e82fe')};
  //background: var(--primary-100, #ebefff);
  background: ${({ disabled }) => (disabled ? '#E9ECEF' : '#ebefff')};
  //color: #354acb;
  color: ${({ disabled }) => (disabled ? '#ADB5BD' : '#354acb')};
  cursor: pointer;
`;

export const HoverBaseButton = styled(BaseButton)`
  &:hover {
    background: #354acb;
    color: #fff;
  }
`;
