import * as S from './MailHistoryModal.styles';
import { Dates } from '@app/constants/Dates';

interface Props {
  task: any;
  onCancel: () => void;
  width: string;
  open: boolean;
}
const MailHistoryModal = ({ task, onCancel, width, open }: Props) => {
  return (
    <S.ModalWrapper closable={false} open={open} title="" onCancel={onCancel} footer={null}>
      <div className="title">개별 이메일 발송 히스토리</div>

      <div className="history-lists">
        <div className="target-info">
          {task?.name} ・ {task?.groupName} ・ {task?.executers[0]?.name}
        </div>
        <ul className="mail-history">
          {task?.mailHistories?.map((mail: any, index: number) => (
            <li key={index}>
              <span className="time">
                {/*{Dates.format(Math.floor(mail?.timestamp?.getTime()), 'YYYY.MM-DD HH:mm:ss')}*/}
                {mail?.timestamp ? Dates.format(mail?.timestamp, 'YY.MM.DD HH:mm') : null}
              </span>
              <span className="email">{mail.to}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer">
        <button className="close-btn" onClick={onCancel}>
          닫기
        </button>
      </div>
    </S.ModalWrapper>
  );
};

export default MailHistoryModal;
