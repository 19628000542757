import { Executer } from '@app/interfaces/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import SettingPopOverContent from '@app/components/ui/SettingPopOverContent';
import * as S from '@app/pages/SettingTables.styles';
import plusImage from '@app/assets/images/plus.png';
import GradingPopOverContent from '@app/components/ui/GradingPopOverContent';
import * as SettingTable from '@app/pages/SettingTables.styles';
import { useReactiveVar } from '@apollo/client';
import { ymaDatas } from '@app/hocs/cache';

interface Props {
  updateYMTask: (taskId: string, executorId: string) => Promise<void>;
  value: { taskId: string; executers: { id: string }[] };
  executers: { id: string }[];
}

export default function GradingPopOver({ updateYMTask, value, executers }: Props) {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const popOverRef = useRef<HTMLDivElement>(null);
  const ymaData = useReactiveVar(ymaDatas);

  const onToggle = () => {
    setPopOverOpen((prevState) => !prevState);
  };

  const onClose = () => {
    setPopOverOpen(false);
  };
  const handleDocumentClick = (e: MouseEvent) => {
    if (popOverRef.current && !popOverRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  return (
    <div ref={popOverRef}>
      <BasePopover
        color="#ebefff"
        content={
          ymaData.eqs && (
            <div onClick={(e) => e.stopPropagation()}>
              <GradingPopOverContent
                onClose={onClose}
                executers={ymaData.eqs}
                updateYMTask={updateYMTask}
                value={value}
                executer={executers}
              />
            </div>
          )
        }
        trigger="click"
        placement="bottom"
        showArrow={false}
        open={popOverOpen}
      >
        <SettingTable.AddrRoleButton onClick={onToggle}>
          <img src={plusImage} alt="" />
        </SettingTable.AddrRoleButton>
      </BasePopover>
    </div>
  );
}
