import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import { ScheduleCard } from '@app/components/schedule-dashboard/scheduleCard/ScheduleCard/ScheduleCard';

import { MapCard } from '@app/components/schedule-dashboard/mapCard/MapCard';
import { ScreeningsCard } from '@app/components/schedule-dashboard/screeningsCard/ScreeningsCard/ScreeningsCard';
import { ActivityCard } from '@app/components/schedule-dashboard/activityCard/ActivityCard';
import { TreatmentCard } from '@app/components/schedule-dashboard/treatmentCard/TreatmentCard';
import { CovidCard } from '@app/components/schedule-dashboard/covidCard/CovidCard';
import { HealthCard } from '@app/components/schedule-dashboard/HealthCard/HealthCard';
import { FavoritesDoctorsCard } from '@app/components/schedule-dashboard/favoriteDoctors/FavoriteDoctorsCard/FavoritesDoctorsCard';
import { PatientResultsCard } from '@app/components/schedule-dashboard/PatientResultsCard/PatientResultsCard';
import { StatisticsCards } from '@app/components/schedule-dashboard/statisticsCards/StatisticsCards';
import { BloodScreeningCard } from '@app/components/schedule-dashboard/bloodScreeningCard/BloodScreeningCard/BloodScreeningCard';
import { NewsCard } from '@app/components/schedule-dashboard/NewsCard/NewsCard';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ExamineeTable } from '@app/components/examinee-dashboard/ExamineeTable';

const ExamineeDashboardPage: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={24} xxl={24}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol id="schedule" span={24}>
            <ExamineeTable />
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[30, 30]}>
      {isTablet && (
        <BaseCol id="map" md={24} order={4}>
          <ExamineeTable />
        </BaseCol>
      )}
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t('common.examinee-dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ExamineeDashboardPage;
