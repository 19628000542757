import * as SettingTable from './SettingTables.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { gradingTagLists } from '@app/constants/ui';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import * as S from './AssessmentPage.styles';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import emailIcon from '@app/assets/icons/email.svg';
import SendEmailToGroupModal from '@app/components/modal/SendEmailToGroupModal';
import deleteIcon from '@app/assets/icons/deleteIcon.svg';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  getYMAssessmentsGQL,
  getYMTags,
  getYMTasksByCategoryAndOwnerIdGQL,
  searchYMTargetsByTaskKeywordGQL,
  updateYMGroupGQL,
  updateYMTaskGQL,
} from '@app/hocs/gql';
import { isSameClickSideBar, userDatas, ymaDatas } from '@app/hocs/cache';
import { Btn } from '@app/components/header/components/YMHeaderSearch/HeaderSearch.styles';
import { SearchOutlined } from '@ant-design/icons';
import GradingPopOver from '@app/components/ui/GradingPopOver';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { DetailTaskData, Executer } from '@app/interfaces/interfaces';
import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
import { Input, Select } from 'antd';
import DeleteExecutorModal from '@app/components/modal/DeleteExecutorModal';
import MailHistoryModal from '@app/components/modal/MailHistoryModal';
import emailCheckImg from '@app/assets/icons/email_check.svg';
import emailAlertImg from '@app/assets/icons/email_alert.svg';
import { getExamMonthFromReserved, getFormatedAge } from '@app/constants/YMData';
export const InputWrapper = styled.div`
  //.ant-input-group-addon {
  //  display: none;
  //}
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    display: none !important;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-active .ant-select-selector {
    outline: none !important; /* 포커스 효과 제거 */
    border: none !important;
    box-shadow: none !important; /* 포커스 상태의 그림자 제거 */
  }

  .ant-select-selector {
    font-size: 15px !important;
  }
  .ant-input-group-addon {
    //display: none !important;
  }
  .ant-select:focus-visible {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;담당자
  }
  .ant-select-selector:focus-visible {
    outline: none !important;
    border-color: initial !important;
    box-shadow: none !important;
    border: none !important;
  }

  .ant-input-affix-wrapper:focus {
    outline: none !important; /* 포커스 효과 제거 */
    border-color: initial !important; /* 포커스 상태의 테두리 제거 */
    box-shadow: none !important; /* 포커스 상태의 그림자 제거 */
    border: none !important;
  }
  .ant-input-group-addon {
    border-radius: 28pc !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ant-input-group-addon > button {
    display: none !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 28px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 0 !important;
    height: 44px !important;
    width: 300px !important;
    padding-left: 20px !important;
    font-size: 16px;

    .ant-input::placeholder {
      color: #999; /* 원하는 색상을 지정하세요. */
    }
    ::placeholder {
    }
  }
  .ant-select-dropdown {
    width: 100px !important;
    min-width: 100px !important;
  }
`;

export const SelectWrapper = styled(Select)`
  .ant-select-dropdown {
    width: 100px !important;
    min-width: 100px !important;
  }
`;

export default function AssessmentPage() {
  const { Option } = Select;

  const [tasks, setTasks] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState(gradingTagLists[0].value);
  const [filteredTableData, setFilteredTableData] = useState<DetailTaskData[]>([]);
  const [allGradingData, setAllGradingData] = useState<DetailTaskData[]>([]);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(99);
  const [searchType, setSearchType] = useState({ label: '아이', value: 'title' });
  const isSidebarClick = useReactiveVar(isSameClickSideBar);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusCount, setStatusCount] = useState<number[]>([0,0,0,0,0]);

  //const [selectedTarget, setSelectedTarget] = useState<any>(null);
  const [mailModal, setMailModal] = useState(false);
  const [selectedTaskData, setSelectedTaskData] = useState<DetailTaskData | null>(null);
  const userData = useReactiveVar(userDatas);
  const [searchQuery] = useLazyQuery(searchYMTargetsByTaskKeywordGQL);

  const ymaData = useReactiveVar(ymaDatas);

  const [updateTask] = useMutation(updateYMTaskGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMTask.success !== true) {
        notificationController.error({ message: data.updateYMTask.error });
      }
    },
  });

  // const ymData = useReactiveVar(ymDatas);

  const { data: assessTags } = useQuery(getYMTags, {
    variables: { category: 'Assess' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });    
  const [getYMTasksByCategoryAndOwnerIdHandler] = useLazyQuery(getYMTasksByCategoryAndOwnerIdGQL,{
    onCompleted: (data) => {
      if (data.getYMTasksByCategoryAndOwnerId.tasks === null) return;
      setTasks(data.getYMTasksByCategoryAndOwnerId.tasks);
      setStatus(status + 1);
    },
  });
  const [getYMTasksByCategoryHandler] = useLazyQuery(getYMAssessmentsGQL, {
    onCompleted: (data) => {
      if (data.getYMAssessments.tasks === null) return;
      setTasks(data.getYMAssessments.tasks);
      setStatus(status + 1);
    },
  });

  const [searchKeyword, setSearchKeyword] = useState('');
  const [isComposing, setIsComposing] = useState(false);
  const [updateYMGroup, updateYMGroupRequest] = useMutation(updateYMGroupGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMGroup.success === true) {
      } else {
        console.log(data.updateYMGroup.error);
      }
    },
  });
  const onToggleMailModal = () => {
    setMailModal((prevState) => !prevState);
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  // searchTasks 안에 있던 CountData 함수를 밖으로 뺴서 getTaskData 함수에 적용 했습니다.
  const countData = (sortedArray: any) => {
    const onProgressData = sortedArray
      .filter((item: any) => 1 === item.status)
      .filter((data: any) => {
        return getStatusValue(data?.taskData?.[data.category], data.category) === 0;
      });

    const onRatedData = sortedArray
      .filter((item: any) => 1 === item.status)
      .filter((item: any) => getStatusValue(item?.taskData?.[item.category], item.category) === 1);

    const onPendingData = sortedArray.filter((item: any) => 9 === item.status);
    const onCompletedData = sortedArray.filter((item: any) => 2 === item.status);

    setStatusCount([
      sortedArray.length,
      onRatedData.length,
      onProgressData.length,

      onPendingData.length,
      onCompletedData.length,
    ]);
  };

  const searchTasks = async () => {
    if (!searchKeyword) {
      //refetch();

      setStatus(status + 1);
      return;
    }

    const category = 'assess_1,assess_2';
    setCurrentTab(3);

    const searchData = await searchQuery({
      variables: {
        keyword: searchKeyword,
        type: searchType.value,
        category,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

    if (searchData.data.searchYMTargetsByTaskKeyword.success && searchData.data.searchYMTargetsByTaskKeyword.tasks) {
      const tasks = [...searchData.data.searchYMTargetsByTaskKeyword.tasks];
      let sortedArray = updateTasksData(tasks);
      sortedArray = sortedArray.filter((item: any) => item?.status !== 5);
      console.log(sortedArray, 'sortedArray');
      setFilteredTableData(sortedArray);
      setAllGradingData(sortedArray);
      countData(sortedArray);
      return;
    }
    if (
      !searchData.data.searchYMTargetsByTaskKeyword.tasks ||
      !searchData.data.searchYMTargetsByTaskKeyword?.tasks?.length
    ) {
      setFilteredTableData([]);
      setAllGradingData([]);
    }
  };

  const onTogglDelteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };

  const onToggleModal = () => {
    setModal((prevState) => !prevState);
  };
  const onChangeTab = (value: number) => {
    if (currentTab === value) return;

    setSearchKeyword('');
    setCurrentTab(value);

    if (value === 3) {
      const sortedArray = allGradingData; //updateTasksData(allGradingData);
      setFilteredTableData(sortedArray);          
      return;
    }

    if (value === 0) {
      const filteredData = allGradingData //updateTasksData(allGradingData)
        .filter((item:any) => 1 === item.status)
        .filter((data:any) => {
          return getStatusValue(data?.taskData?.[data.category], data.category) === 0;
        });

      setFilteredTableData(filteredData);
  
      return;
    }

    if (value === 1) {
      const filteredData = allGradingData //updateTasksData(allGradingData)
        .filter((item:any) => 1 === item.status)
        .filter((item:any) => getStatusValue(item?.taskData?.[item.category], item.category) === 1);
      setFilteredTableData(filteredData);

      return;
    }

    const filteredData = allGradingData //updateTasksData(allGradingData)
      .filter((item:any) => value === item.status);
    setFilteredTableData(filteredData);
};

  const getExamName = (category: string) => {
    if (assessTags?.getYMTags?.success && assessTags?.getYMTags?.tags.length) {
      const tags = assessTags?.getYMTags?.tags;
      const findTag = tags.find((tag: { code: string }) => tag.code === category);

      return findTag?.name;
    }

    return '';
  };

  const updateYMTask = async (taskId: string, executorId: string) => {
    try {
      const { data: updateData } = await updateTask({
        variables: {
          id: taskId,
          input: {
            executers: [executorId],
          },
        },
      });

      if (updateData.updateYMTask.success === true) {
        //await refetch();
        notificationController.success({ message: '성공적으로 변경되었습니다.' });
      } else {
        notificationController.error({ message: updateData.updateYMTask.error });
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  function checkKeysExist(obj: any, ...keys: any[]) {
    for (const key of keys) {
      if (key in obj) {
        return 0; // 하나라도 존재하면 0 반환
      }
    }
    return 1; // 모든 키값이 존재하지 않으면 1 반환
  }

  const getStatusValue = (taskData: any, category: string) => {
    if (!taskData) {
      return 1;
    }

    if (taskData) {
      if (category === 'assess_1') {
        return checkKeysExist(taskData, 'totalComment', 'snapResult');
      } else {
        return checkKeysExist(taskData, 'totalComment', 'snapResult', 'kidsResult');
      }
    }

    return 0;
  };

  const columns2: ColumnsType<any> = [
    {
      title: '요청일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: '130px',
      render: (value, record) => {

        //console.log(record.mailHistories?.length, record);
        if( record.createdAt === undefined) return<></>;
        const created = new Date(Number(record.createdAt));
        const executedAt = created.toISOString();
        return (
          <>
          {executedAt.substring(0,10)}
          </>
        );
      },
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      // filterMode: 'tree',
      // onFilter: (value: string | number | boolean, record: ExecuterTableRow) => record.name.includes(value.toString()),
    },
    {
      title: '채점 상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      // width: '200px',
      render: (value, record) => {
        //setSelectedTarget(record);
        const updatedStatus =
          value !== 1 ? value : getStatusValue(record?.taskData?.[record.category], record.category);

        //console.log(record.mailHistories?.length, record);
        return (
          <S.StatusTag
            status={updatedStatus}
            style={{
              maxWidth: updatedStatus === 2 && record.mailHistories?.length ? '300px' : '160px',
              cursor: updatedStatus === 2 ? 'pointer' : 'initial',
            }}
            onClick={() => {
              if (updatedStatus === 2) {
                onToggleMailModal();
                setSelectedTaskData(record);
              }
            }}
          >
            {updatedStatus === 1
              ? '미평가'
              : updatedStatus === 0
              ? '평가 진행 중'
              : updatedStatus === 9
              ? '결과 발송 대기'
              : updatedStatus === 2
              ? '완료'
              : ''}{' '}
            {updatedStatus === 2 && record.mailHistories?.length ? ` : ${record.mailHistories[0]?.to}` : null}
          </S.StatusTag>
        );
      },

      // filterMode: 'tree',
      // onFilter: (value: string | number | boolean, record: ExecuterTableRow) => record.name.includes(value.toString()),
    },

    {
      title: '아동 이름',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),

      key: 'name',
      align: 'center',
    },
    {
      title: '검사 월수',
      dataIndex: 'targetBirthDate',
      key: 'bDate',
      align: 'center',
      render: (text: string, record) => {
        return (
          <>
            {record?.exMonth}
          </>
        );
      },
    },
    {
      title: '검사구분',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      render: (text: string, record: { bDate: string }) => getExamName(text),
    },
    {
      title: '지역',
      dataIndex: 'region',
      key: 'region',
      align: 'center',
    },
    {
      title: '기관명',
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'center',
      sorter: (a: any, b: any) => a.groupName.localeCompare(b.groupName),
      render: (value, record) => {
        //console.log(record.groupMailHistories, 'groupMailHistories');
        return (
          <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
            <span>{value}</span>
            <img
              width="20"
              height="18"
              src={emailIcon}
              alt="email icon"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onToggleModal();
                setSelectedTaskData(record);
              }}
            />
            {record.groupMailHistories !== undefined && <img src={record.groupMailHistories.length ? emailCheckImg : emailAlertImg} />}
          </div>
        );
      },
    },
    {
      title: '담당자',
      dataIndex: 'executers',
      key: 'executers',
      align: 'center',
      sorter: (a: any, b: any) => {
        const prev = a.executers[0]?.name ?? '';
        const next = b.executers[0]?.name ?? '';
        return prev.localeCompare(next);
      },

      render: (value, record) => {
        return (
          <S.FlexContainer justifyContent="center" alignItems="center" gap="10px">
            {value?.length && value[0] !== null ? (
              <SettingTable.TabButton isChecked={record.status === 3} isHovered={false}>
                {value && value[0]?.name}
              </SettingTable.TabButton>
            ) : null}
            {record.status === 2 || record.status == 9 ? null : (
              <GradingPopOver updateYMTask={updateYMTask} value={record} executers={value} />
            )}
          </S.FlexContainer>
        );
      },
    },
    {
      title: '채점',
      align: 'center',

      render: (_, record) => (
        <S.LinkButton to={`target-history/${record.targetId}/${record.taskId}`}>바로가기</S.LinkButton>
      ),
    },

    {
      title: '채점 삭제',
      align: 'center',

      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={deleteIcon}
            alt="delete Icon"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onTogglDelteModal();
              console.log(record, 'record');
              setSelectedTaskData(record);
            }}
          />
        </div>
      ),
    },
  ];

  const updateTasks = () => {
    setSearchKeyword('');
    setCurrentTab(3);

    if (ymaData?.auth) {
      if (ymaData.auth.split('|').includes('관리자')) {
        getYMTasksByCategoryHandler({
          variables: {
            category: 'assess_1,assess_2',
          },
        });
      } else {
        getYMTasksByCategoryAndOwnerIdHandler({
          variables: {
            ownerId: ymaData.eqid,
            category: 'assess_1,assess_2',
          },
        });
      }
    }
  }

  const deleteTaskByTaskId = (id: string) => {
    console.log('delete', id);

    if (selectedTaskData?.taskId) {
      updateTask({
        variables: {
          id: selectedTaskData?.taskId,
          input: {
            status: 5,
          },
        },
      })
        .then(() => {
          //refetch();
          setTimeout(() => {
            setStatus(status + 1);
            onTogglDelteModal();
          }, 1000);
        })
        .catch((err) => {
          notificationController.error({ message: '에러가 발생했습니다.' });
        });
    }
  };
  const updateTasksData = (tasks: any) => {
    const updatedTasks = tasks.reduce(
      (accumulator: any, task: any, index: any) => {
        const updatedTaskData =
          task?.data === '' ? {} : typeof task?.data === 'string' ? JSON.parse(task?.data) : task?.data;
        const updatedGroupData =
          task?.group?.data === ''
            ? {}
            : typeof task?.group?.data === 'string'
            ? JSON.parse(task?.group?.data)
            : task?.group?.data;
        //console.log(updatedGroupData, 'task');

        if (task?.target?.data && task?.group?.data && task?.target?.id) {

          const target = JSON.parse(task.target.data);
          const group = JSON.parse(task.group.data);
          const name = target.name ;
          const bDate = target.bdate;
          const region = group.region;
          const groupName = group.name + (group.name !== '학부모검사'? (task.group.code.length === 5 ? "(23)":""): '');
          const groupId = task.group.id;
          const taskId = task.id;
          const targetId = task?.target?.id;
          const category = task?.category;
          const taskData = updatedTaskData;
          const executedAt = task?.executedAt;
          const completedAt = task?.completedAt;
          const createdAt = task?.createdAt;
          const rDate = task?.target?.reservedAt;
          const mailHistories = updatedTaskData?.mail ?? [];
          const groupData = updatedGroupData;
          const groupMailHistories = updatedGroupData?.mail ?? [];
          if( updatedTaskData.exmonth < 0 ){
            //console.log('황민혁', task, '황민혁');
            // getFormatedAge(updatedTaskData.exmonth,1), // 
          }
          accumulator.push({
            key: index,
            name,
            bDate,
            region,
            groupName,
            executers: task.executers,
            status: task.status,
            groupId,
            taskId,
            targetId,
            category,
            taskData,
            executedAt,
            completedAt,
            createdAt,
            rDate,
            exMonth: getFormatedAge(getExamMonthFromReserved(bDate, target.epweek, target.epday, Number(rDate)),1),//updatedTaskData.exmonth + '개월' + (updatedTaskData.bmonth !== updatedTaskData.exmonth ?' (조산)':'') ,
            mailHistories,
            groupMailHistories,
            groupData,
          });
        }

        return accumulator;
      },
      [] as {
        key: number;
        name: string;
        bDate: string;
        region: string;
        groupName: string;
        executers: Executer[];
        status: number;
        groupId: string;
        taskId: string;
        targetId: string;
        category: string;
        taskData: any;
        executedAt: string;
        completedAt: string;
        createdAt: string;
        rDate: string;
        mailHistories: { to: string; timestamp: string }[];
        groupMailHistories: { to: string; timestamp: string }[];
        groupData: any;
      }[],
    );

    const sortedArray = updatedTasks
      .sort((a: any, b: any) => {
        if (a.status === 2 && b.status !== 2) {
          return 1; // a가 2이고 b가 2가 아닌 경우 b를 먼저 보여줍니다.
        } else if (a.status !== 2 && b.status === 2) {
          return -1; // a가 2가 아니고 b가 2인 경우 a를 먼저 보여줍니다.
        } else if (a.status === 2 && b.status === 2) {
          // a와 b가 둘 다 2인 경우, completedAt 또는 executedAt을 기준으로 정렬합니다.
          if (a.completedAt && b.completedAt) {
            const dateA = new Date(a.completedAt) as any;
            const dateB = new Date(b.completedAt) as any;
            return dateB - dateA; // completedAt을 기준으로 내림차순 정렬
          } else if (!a.completedAt && !b.completedAt) {
            // 둘 다 completedAt이 없는 경우, executedAt을 기준으로 정렬합니다.
            const dateA = new Date(a.executedAt) as any;
            const dateB = new Date(b.executedAt) as any;
            return dateB - dateA; // executedAt을 기준으로 내림차순 정렬
          } else {
            // a에는 completedAt이 없고 b에는 completedAt이 있는 경우, executedAt을 기준으로 정렬합니다.
            if (!a.completedAt) {
              const dateA = new Date(a.executedAt) as any;
              const dateB = new Date(b.completedAt) as any;
              return dateB - dateA;
            } else {
              const dateA = new Date(a.completedAt) as any;
              const dateB = new Date(b.executedAt) as any;
              return dateB - dateA;
            }
          }
        } else {
          return 0;
        }
      })
      .sort((a: any, b: any) => {
        if (a.status !== 2 && b.status !== 2) {
          const dateA = parseInt(a.createdAt);
          const dateB = parseInt(b.createdAt);
          return dateB - dateA; // createdAt을 숫자로 변환하여 오름차순 정렬
        }
        return 0;
      });

    return sortedArray;
  };

  const updateYmGroupData = (id: string, data: any, email: string) => {
    console.log(data, id, 'updateYmGroup');
    const groupMail = selectedTaskData?.groupMailHistories ?? [];
    const groupData = { ...data, mail: [{ to: email, timestamp: new Date() }, ...groupMail] };
    updateYMGroup({
      variables: {
        id,
        input: {
          data: JSON.stringify(groupData),
        },
      },
    }).then((resp) => {
      console.log(selectedTaskData, 'selectedTaskData');
      if (selectedTaskData) {
        const data = { ...selectedTaskData };
        const time = JSON.stringify(new Date());

        console.log('new: ', [{ to: email, timestamp: JSON.parse(time) }, ...data.groupMailHistories]);

        setSelectedTaskData({
          ...data,
          groupMailHistories: [{ to: email, timestamp: JSON.parse(time) }, ...data.groupMailHistories],
        });
      }
      //refetch();
      setStatus(status + 1);
    });
  };

  useEffect(() => {
    if (isSidebarClick) {
      setStatus(status + 1);
      isSameClickSideBar(false);
    }
  }, [isSidebarClick]);

  useEffect(() => {
    if (searchKeyword) {
      searchTasks();
      return;
    }

    if( tasks.length === 0 ){
      updateTasks();
    }
    else if (userData.uid ) {
      if (ymaData.auth.split('|').includes('관리자')) {
        if (tasks !== null && tasks.length > 0) {

          let sortedArray = updateTasksData(tasks);
          sortedArray = sortedArray.filter((item: any) => item.status !== 5);

          const onProgressData = sortedArray
            .filter((item: any) => 1 === item.status)
            .filter((data: any) => {
              return getStatusValue(data?.taskData?.[data.category], data.category) === 0;
            });

          const onRatedData = sortedArray
            .filter((item: any) => 1 === item.status)
            .filter((item: any) => getStatusValue(item?.taskData?.[item.category], item.category) === 1);
          //console.log(currentTab, 'currentTab');

          const filteredData = sortedArray.filter((item: any) => {
            if (currentTab === gradingTagLists[0].value) return item;

            if (currentTab === 0) {
              return 1 === item.status && getStatusValue(item?.taskData?.[item.category], item.category) === 0;
            }

            if (currentTab === 1) {
              return 1 === item.status && getStatusValue(item?.taskData?.[item.category], item.category) === 1;
            }

            if (item?.status) {
              return item.status === currentTab;
            }
          });

          setFilteredTableData(filteredData);
          setAllGradingData(sortedArray);

          //console.log(sortedArray, filteredData, 'filteredData', tasks);
          filteredData.map((item: any, index: any) => {
            //if (item.status === 2) {console.log(item, 'item', index);}
          });

          const onPendingData = sortedArray.filter((item: any) => 9 === item.status);
          const onCompletedData = sortedArray.filter((item: any) => 2 === item.status);

          setStatusCount([
            sortedArray.length,
            onRatedData.length,
            onProgressData.length,
            onPendingData.length,
            onCompletedData.length,
          ]);
        }
      } else {
        //getTaskData(ymaData.eqid);
        let sortedArray = updateTasksData(tasks);
        sortedArray = sortedArray.filter((item: any) => item.status !== 5);
        
        // 학부모 계정 카운트 안됨 관련 함수 추가
        countData(sortedArray);
  
        const filteredData = sortedArray.filter((item: any) => {
          if (currentTab === gradingTagLists[0].value) return item;
  
          if (currentTab === 0) {
            return 1 === item.status && getStatusValue(item?.taskData?.[item.category], item.category) === 0;
          }
  
          if (currentTab === 1) {
            return 1 === item.status && getStatusValue(item?.taskData?.[item.category], item.category) === 1;
          }
  
          if (item?.status) {
            return item.status === currentTab;
          }
        });
  
        setFilteredTableData(filteredData);
        setAllGradingData(sortedArray);  
      }
    }
  }, [status, tasks]);

  const searchTab = [
    { label: '아이', value: 'title' },
    { label: '담당자', value: 'search2' },
    { label: '소속기관', value: 'search1' },
  ];

  // console.log(filteredTableData, ' filteredTableData');
  const selectBefore = (
    <SelectWrapper
      style={{ width: '100px', minWidth: '100px' }}
      defaultValue={searchType.label}
      onChange={(e) => {
        const filteredTab = searchTab.filter((item) => item.value === e);
        setSearchType(filteredTab[0]);
      }}
    >
      {searchTab.map((item) => {
        if (item.label === searchType.label) return;
        return (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        );
      })}
      {/*<Option value="name">아이</Option>*/}
      {/*<Option value="https://">https://</Option>*/}
    </SelectWrapper>
  );

  const getStatusCount = (value: any) => {
    if (value === 0) {
    }
  };
  //console.log(filteredTableData, statusCount, 'statusCount');
  return (
    <>
      {tasks && (
        <>
          <PageTitle>채점</PageTitle>

          <BaseRow justify="space-between" style={{ position: 'absolute', top: '0px' }}>
            <BaseCol xl={24} md={24}>
              <BaseFormTitle style={{ padding: '1rem' }}>채점</BaseFormTitle>
            </BaseCol>
          </BaseRow>

          <SettingTable.TabSearchWrapper style={{ justifyContent: 'space-between' }}>
            <BaseCol>
              <BaseSpace>
                {gradingTagLists.map((item, index) => (
                  <SettingTable.TabButton
                    key={item.label}
                    isChecked={currentTab === item.value}
                    isHovered={true}
                    onClick={() => {
                      onChangeTab(item.value);
                    }}
                  >
                    {item.label}
                    {`(${statusCount[index]})`}
                  </SettingTable.TabButton>
                ))}
                  <SettingTable.TabButton
                    key="새로"
                    isChecked={false}
                    isHovered={true}
                    onClick={() => {
                      updateTasks();
                    }}
                    style={{marginLeft:20, backgroundColor: '#777', color: '#fff', cursor: 'pointer'}}
                  >새로고침
                  </SettingTable.TabButton>

              </BaseSpace>
            </BaseCol>
            <BaseCol>
              <InputWrapper>
                <Input.Search
                  width="100%"
                  value={searchKeyword}
                  placeholder="검색"
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  addonBefore={selectBefore}
                  onPressEnter={(e) => {
                    if (isComposing) {
                      return;
                    }
                    e.preventDefault();

                    searchTasks();
                  }}
                  suffix={
                    <Btn
                      size="small"
                      type="text"
                      aria-label="Filter"
                      icon={<SearchOutlined />}
                      onClick={() => searchTasks()}
                    />
                  }
                  onChange={(event) => setSearchKeyword(event.target.value)}
                  addonAfter={null}
                />

                {/*<div ref={ref} />*/}
              </InputWrapper>
            </BaseCol>
          </SettingTable.TabSearchWrapper>
          <S.TableWrapper>
            <BaseTable
              columns={columns2}
              dataSource={filteredTableData}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: true, // 페이지 크기 변경 옵션 표시
                showQuickJumper: true, // 페이지 빠른 이동 옵션 표시
                showTotal: (total, range) => <div>{`${range[1]} of ${total === 0 ? 0 : total} items`}</div>,
              }}
              scroll={{ x: 800 }}
            />
          </S.TableWrapper>

          {selectedTaskData && (
            <SendEmailToGroupModal
              title="기관 결과 다운로드 및 발송"
              open={modal}
              onSendHandler={() => {}}
              onDownloadHandler={() => {}}
              onCancel={onToggleModal}
              width="883px"
              selectedTaskData={selectedTaskData}
              updateYmGroupData={updateYmGroupData}
            />
          )}
          <DeleteExecutorModal
            onOk={deleteTaskByTaskId}
            onCancel={() => setDeleteModal(false)}
            width="450px"
            open={deleteModal}
            type="task"
            desc={
              <div>
                정말로 이 채점 항목을 삭제하시겠습니까? <br /> 삭제 후에는 데이터를 복구할 수 없습니다.
              </div>
            }
            task={selectedTaskData}
          />
          <MailHistoryModal task={selectedTaskData} open={mailModal} onCancel={onToggleMailModal} width="400px" />
        </>
      )}
    </>
  );
}
